import { Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: "transparent",
	/* theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)", */
	"&:hover": {
		backgroundColor: "#f5f5f5",
	},
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface AccordionComponentProps {
	title: string;
	titleDescription?: string;
	children: React.ReactNode;
	defaultExpanded?: boolean;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
	title,
	titleDescription,
	children,
	defaultExpanded = false,
}) => {
	const [expanded, setExpanded] = useState(defaultExpanded);

	return (
		<Accordion
			defaultExpanded={defaultExpanded}
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
		>
			<AccordionSummary>
				{titleDescription ? (
					<>
						<Typography sx={{ width: "33%", flexShrink: 0 }}>
							{title}
						</Typography>
						{!expanded && (
							<Typography sx={{ color: "text.secondary" }}>
								{titleDescription}
							</Typography>
						)}
					</>
				) : (
					<div>{title}</div>
				)}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

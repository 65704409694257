// TODO: add documentation, refactor

import { Box, Button, Popover, Typography, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useRef, useState } from "react";
import { ProfileButton } from "../profile-button.component";

/**
 * ButtonWithPopover - Clickable button, which opens a popover on hover displaying children
 */
export const ProfileButtonWithPopover: React.FC<{
	variant?: "box" | "chip";
	avatar?: string | null;
	name: { first_name: string; last_name: string };
	label: string;
	children?: React.ReactNode;
	onClick: () => void;
	onAdd: () => void;
	disableClick?: boolean;
	disablePopover?: boolean;
	loading?: boolean;
}> = ({
	variant = "box",
	avatar = null,
	name = { first_name: "", last_name: "" },
	label,
	onClick,
	onAdd,
	children,
	disableClick = false,
	disablePopover = false,
	loading = false,
}) => {
	const [open, setOpen] = useState(false);
	const popoverAnchor = useRef(null);

	const handlePopoverEnter = () => {
		if (disablePopover) return;
		setOpen(true);
	};

	const popoverLeave = () => {
		setOpen(false);
	};

	if (loading) {
		return <ButtonWithPopoverSkeleton variant={variant} />;
	} else
		return (
			<Stack direction="row" alignItems="center" spacing={2}>
				{variant === "box" && (
					<Typography
						variant="body2"
						sx={{ fontWeight: "bold", mr: "2px", minWidth: "90px" }}
					>
						{label}
					</Typography>
				)}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					{/* If name is null, show option to add */}
					{!name.first_name && !name.last_name ? (
						<Button onClick={onAdd}>
							<Typography variant="body1">
								+ hinzufügen
							</Typography>
						</Button>
					) : (
						<>
							<div
								aria-owns="mouse-over-popover"
								aria-haspopup="true"
								ref={popoverAnchor}
								onMouseEnter={handlePopoverEnter}
								onMouseLeave={popoverLeave}
							>
								<ProfileButton
									avatar={avatar || ""}
									firstName={name.first_name}
									lastName={name.last_name}
									onClick={onClick}
									disableClick={disableClick}
									variant={variant}
								/>
							</div>
							<Popover
								id="mouse-over-popover"
								sx={{
									pointerEvents: "none",
								}}
								open={open}
								anchorEl={popoverAnchor.current}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								PaperProps={{
									onMouseEnter: handlePopoverEnter,
									onMouseLeave: popoverLeave,
									sx: {
										pointerEvents: "auto",
									},
								}}
							>
								{children}
							</Popover>
						</>
					)}
				</Box>
			</Stack>
		);
};

const ButtonWithPopoverSkeleton: React.FC<{
	variant: "box" | "chip";
}> = ({ variant }) => {
	if (variant === "box") {
		return (
			<Stack direction="row" alignItems="center" spacing={3.2}>
				<Skeleton variant="text" width={90} />
				<Stack direction="row" alignItems="center" spacing={2}>
					<Skeleton variant="circular" width={40} height={40} />
					<Skeleton variant="text" width={100} />
				</Stack>
			</Stack>
		);
	} else if (variant === "chip") {
		// TODO
		return (
			<Stack direction="row" alignItems="center" spacing={1.5}>
				<Skeleton variant="circular" width={40} height={40} />
			</Stack>
		);
	} else {
		return <></>;
	}
};

import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from "@mui/material";
import {
	PdfTemplateConfiguration,
	PdfTemplateConfigurationContextProps,
	usePdfTemplateConfigurationContext,
} from "../../context/PdfTemplateConfigurationContext";
import { CustomTextField } from "../../forms/form-input-components/custom-input-components/custom-text-field.component";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { CustomTextFieldWithSaveButton } from "../../forms/form-input-components/custom-input-components/custom-text-field-with-save-button.component";

// Override pdfTemplateConfiguration: PdfTemplateConfiguration | undefined; to not be undefined
interface SingleConfigurationProps
	extends PdfTemplateConfigurationContextProps {
	pdfTemplateConfiguration: PdfTemplateConfiguration;
}

export const PdfTemplateSingleConfigurations: React.FC<{
	pdfTemplateType: PdfTemplateType;
}> = ({ pdfTemplateType }) => {
	// Pass the entire context as props
	const props: PdfTemplateConfigurationContextProps =
		usePdfTemplateConfigurationContext();
	if (!props.pdfTemplateConfiguration) {
		return null;
	}

	switch (pdfTemplateType) {
		case PdfTemplateType.KOSTENVORANSCHLAG:
			return (
				<KostenvoranschlagSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.LIEFERSCHEIN:
			return (
				<LieferscheinSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.GUTSCHRIFT:
			return (
				<GutschriftSettings {...(props as SingleConfigurationProps)} />
			);
		case PdfTemplateType.RECHNUNG:
			return (
				<RechnungSettings {...(props as SingleConfigurationProps)} />
			);
		case PdfTemplateType.SAMMELRECHNUNG:
			return (
				<SammelrechnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.KARTENZAHLUNGSQUITTUNG:
			return (
				<KartenzahlungsquittungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.BARZAHLUNGSQUITTUNG:
			return (
				<BarzahlungsquittungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.AKONTORECHNUNG:
			return (
				<AkontorechnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.ERSTE_MAHNUNG:
			return (
				<ErsteMahnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.FOLGEMAHNUNG:
			return (
				<FolgemahnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.AUFTRAGSBLATT:
			return (
				<AuftragsblattSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		default:
			return null;
	}
};

export const KostenvoranschlagSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={
								props.pdfTemplateConfiguration[
									PdfTemplateType.KOSTENVORANSCHLAG
								].showSignature
							}
							onChange={(e) => {
								props.updatePdfTemplateConfiguration(
									PdfTemplateType.KOSTENVORANSCHLAG,
									{
										showSignature: e.target.checked,
									}
								);
							}}
						/>
					}
					label="Unterschrift anzeigen"
				/>
			</FormGroup>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.KOSTENVORANSCHLAG}
				{...props}
			/>
		</div>
	);
};

export const LieferscheinSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.LIEFERSCHEIN}
				{...props}
			/>
		</div>
	);
};

export const GutschriftSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.GUTSCHRIFT}
				{...props}
			/>
		</div>
	);
};

export const RechnungSettings: React.FC<SingleConfigurationProps> = (props) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.RECHNUNG}
				{...props}
			/>
			<CustomTextFieldWithSaveButton
				label="Text oberhalb des Einzahlungsscheins"
				value={
					props.pdfTemplateConfiguration[PdfTemplateType.RECHNUNG]
						.textAbovePaymentSlip
				}
				validationError={null}
				multiline
				rows={4}
				fullWidth
				onSave={(value) => {
					props.updatePdfTemplateConfiguration(
						PdfTemplateType.RECHNUNG,
						{
							textAbovePaymentSlip: value,
						}
					);
				}}
				onReset={() => {
					props.resetConfigurationColumn(
						PdfTemplateType.RECHNUNG,
						"textAbovePaymentSlip"
					);
				}}
			/>
		</div>
	);
};

export const SammelrechnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.SAMMELRECHNUNG}
				{...props}
			/>
		</div>
	);
};

export const KartenzahlungsquittungSettings: React.FC<
	SingleConfigurationProps
> = (props) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				{...props}
			/>
		</div>
	);
};

export const BarzahlungsquittungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.BARZAHLUNGSQUITTUNG}
				{...props}
			/>
		</div>
	);
};

export const AkontorechnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.AKONTORECHNUNG}
				{...props}
			/>
		</div>
	);
};

export const ErsteMahnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.ERSTE_MAHNUNG}
				{...props}
			/>
		</div>
	);
};

export const FolgemahnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.FOLGEMAHNUNG}
				{...props}
			/>
		</div>
	);
};

export const AuftragsblattSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return <div></div>;
};

const FooterTextSettings: React.FC<
	SingleConfigurationProps & { pdfTemplateType: PdfTemplateType }
> = ({
	pdfTemplateConfiguration,
	updatePdfTemplateConfiguration,
	resetConfigurationColumn,
	pdfTemplateType,
}) => {
	return (
		<CustomTextFieldWithSaveButton
			label="Fusszeile"
			value={pdfTemplateConfiguration[pdfTemplateType].footerText}
			validationError={null}
			multiline
			rows={4}
			fullWidth
			onSave={(value) => {
				updatePdfTemplateConfiguration(pdfTemplateType, {
					footerText: value,
				});
			}}
			onReset={() => {
				resetConfigurationColumn(pdfTemplateType, "footerText");
			}}
		/>
	);
};

import { Box } from "@mui/material";
import { GuarantorSelect } from "../../../form-input-components/selects/guarantor.select";
import { FormGroupProps } from "../../../form-types";
import { OwnTpGroup } from "../../../form-input-components/groups/own-tp.group";
import { TpTierAndVariationGroup } from "../../../form-input-components/groups/tp-tier-and-variation.group";
import { CustomTextFieldWithPercentage } from "../../../form-input-components/custom-input-components/custom-text-field-with-percentage.component";
import { BankAccountSelect } from "../../bank-account/input-components/bank-account.select";
import { NumberSelect } from "../../../form-input-components/selects/number.select";

export const InvoiceSettingsBGroup: React.FC<FormGroupProps> = ({
	formData,
	formErrors,
	onChange = () => {},
	onValueChange = () => {},
	onCheckboxChange = () => {},
}) => {
	return (
		<Box>
			<OwnTpGroup
				formData={formData}
				formErrors={formErrors}
				onChange={onChange}
				onCheckboxChange={onCheckboxChange}
			/>
			<TpTierAndVariationGroup
				formData={formData}
				formErrors={formErrors}
				onChange={onChange}
				onValueChange={onValueChange}
			/>
			<CustomTextFieldWithPercentage
				label="Rabattsatz Arbeit"
				name="discount_work"
				value={formData.discount_work || ""}
				onChange={onChange}
				size="small"
				validationError={formErrors.discount_work}
				fullWidth
			/>
			<CustomTextFieldWithPercentage
				label="Rabattsatz Material"
				name="discount_material"
				value={formData.discount_material || ""}
				onChange={onChange}
				validationError={formErrors.discount_material}
				fullWidth
			/>

			<NumberSelect
				label="Anzahl Rechnungsexemplare"
				number={6}
				value={formData.inv_print_qty}
				onChange={(value) =>
					onValueChange({ name: "inv_print_qty", value })
				}
			/>
			<NumberSelect
				label="Anzahl Lieferscheinexemplare"
				number={6}
				value={formData.del_note_print_qty}
				onChange={(value) =>
					onValueChange({ name: "del_note_print_qty", value })
				}
			/>
			<NumberSelect
				label="Anzahl Kostenvoranschlagexemplare"
				number={6}
				value={formData.kv_print_qty}
				onChange={(value) =>
					onValueChange({ name: "kv_print_qty", value })
				}
			/>

			<GuarantorSelect
				onChange={(value) => {
					if (value) {
						onValueChange({ name: "guarantor_id", value: value });
					} else {
						onValueChange({ name: "guarantor_id", value: null });
					}
				}}
				value={formData.guarantor_id}
			/>
			<BankAccountSelect
				label="Konto"
				value={formData.bank_account_id}
				onChange={(value) =>
					onValueChange({ name: "bank_account_id", value: value })
				}
			/>
		</Box>
	);
};

import React from "react";
import JSZip from "jszip";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../../store/Central";
import { supabase } from "@/lib/supabase";
import {
	Box,
	LinearProgress,
	LinearProgressProps,
	Typography,
} from "@mui/material";
import clsx from "clsx";

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number }
) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography
					variant="body2"
					sx={{ color: "text.secondary" }}
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

// TODO: Konfiguration
const FILES_TO_PROCESS = [
	"Labors.txt", // Not found in zip
	"TAJournal.txt", // Completed
	"Mitarbeiter.txt", // Completed
	"Garanten.txt", // Completed
	"Kunden.txt", // Completed
	"Archiv.txt", // Error
	"LListe.txt", // Completed
	"Gruppen.txt", // Completed
	"Artikel.txt", // Error
	"Tarif.txt", // Completed
	"Bloecke.txt", // Completed
	"BPositionen.txt", // Completed
	"Debitoren.txt", // Completed
	"Faelle.txt", // Timeout
	"FPositionen.txt", // Timeout
	"IMAGE.txt", // Completed
	"Ressourcen.txt", // Completed
	"Termine.txt", // Completed
	"Aufgaben.txt", // Not found in zip
	"Pendenzen.txt", // Completed
	"Farbkarten.txt", // Timeout
	"Konfiguration.txt", // Not found in zip
	"Vorlagen.txt", // Not found in zip
	"Layouts.txt", // Not found in zip
	"SpotNotes.txt", // Not found in zip
	"EMails.txt", // Not found in zip
];

interface ProgressInterface {
	[name: string]: {
		totalLength: number;
		processedSuccess: number;
		processedError: number;
	};
}

export const MigrationPage: React.FC = () => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const [progress, setProgress] = React.useState<ProgressInterface>(
		Object.fromEntries(
			FILES_TO_PROCESS.map((name) => [
				name,
				{ totalLength: 0, processedSuccess: 0, processedError: 0 },
			])
		)
	);
	const [notFoundErrors, setNotFoundErrors] = React.useState<{
		[name: string]: boolean;
	}>({});
	const [rowErrors, setRowErrors] = React.useState<{
		[name: string]: any[];
	}>({});

	const readZipFile = async (content: string | ArrayBuffer) => {
		if (!organizationId) {
			Logger.error("Organization ID not found.");
			return;
		}

		const newZip = new JSZip();

		newZip.loadAsync(content).then(function (zip) {
			if (!zip) {
				Logger.error("Zip file not found.");
				return;
			}
			Logger.info("Zip file loaded.");

			FILES_TO_PROCESS.forEach(async (fileName) => {
				const zipEntry = zip.file(`DLCloud/${fileName}`);
				if (!zipEntry) {
					setNotFoundErrors((prev) => ({
						...prev,
						[fileName]: true,
					}));
					Logger.error(`File ${fileName} not found in the zip.`);
					return;
				}

				const fileData = await zipEntry.async("string");
				const rows = fileData.split("\n").map((row) => ({
					table_name: fileName,
					row_value: row,
					organization_id: organizationId,
				}));

				setProgress((prev) => ({
					...prev,
					[fileName]: {
						...prev[fileName],
						totalLength: rows.length,
					},
				}));

				const processBatch = async (batch: any) => {
					const { data, error } = await supabase
						.from("DL_MIG")
						.insert(batch);

					if (error) {
						Logger.error(
							`Error processing batch for file ${fileName}`,
							data,
							error
						);
						return false;
					}
					Logger.info(
						`Batch for file ${fileName} processed successfully.`,
						data
					);
					return true;
				};

				const batchSize = 191;
				let errors: any[] = [];
				for (let i = 0; i < rows.length; i += batchSize) {
					const batch = rows.slice(i, i + batchSize);
					const success = await processBatch(batch);
					if (!success) {
						errors = errors.concat(batch);
					} else {
						setProgress((prev) => ({
							...prev,
							[fileName]: {
								...prev[fileName],
								processedSuccess:
									prev[fileName].processedSuccess +
									batch.length,
							},
						}));
					}

					// wait 300ms
					await new Promise((resolve) => setTimeout(resolve, 300));
				}

				const retryBatchSize = 11;
				let errors2: any[] = [];
				for (let i = 0; i < errors.length; i += retryBatchSize) {
					const batch = errors.slice(i, i + retryBatchSize);
					const success = await processBatch(batch);
					if (!success) {
						errors2 = errors2.concat(batch);
					} else {
						setProgress((prev) => ({
							...prev,
							[fileName]: {
								...prev[fileName],
								processedSuccess:
									prev[fileName].processedSuccess +
									batch.length,
							},
						}));
					}

					// wait 300ms
					await new Promise((resolve) => setTimeout(resolve, 300));
				}

				const retryBatchSize2 = 1;
				let remainingErrors: any[] = [];
				for (let i = 0; i < errors2.length; i += retryBatchSize2) {
					const batch = errors2.slice(i, i + retryBatchSize2);
					const success = await processBatch(batch);
					if (!success) {
						Logger.error(
							`Error processing batch for file ${fileName}`,
							batch
						);
						setProgress((prev) => ({
							...prev,
							[fileName]: {
								...prev[fileName],
								processedError:
									prev[fileName].processedError +
									batch.length,
							},
						}));
						remainingErrors = remainingErrors.concat(batch);
					} else {
						setProgress((prev) => ({
							...prev,
							[fileName]: {
								...prev[fileName],
								processedSuccess:
									prev[fileName].processedSuccess +
									batch.length,
							},
						}));
					}

					// wait 300ms
					await new Promise((resolve) => setTimeout(resolve, 300));
				}
				if (remainingErrors.length > 0) {
					Logger.error(
						`Error processing batch for file ${fileName}`,
						remainingErrors
					);
					setRowErrors((prev) => ({
						...prev,
						[fileName]: remainingErrors,
					}));
				}
			});
			Logger.info("Migration completed.");
		});
	};

	return (
		<div className="px-16 pb-20 pt-10 flex flex-col gap-6">
			<input
				type="file"
				onChange={(e) => {
					const file = e.target.files?.item(0);
					if (!file) return;

					const reader = new FileReader();
					reader.onload = async (e) => {
						const content = e.target?.result;
						if (!content) return;

						readZipFile(content);
					};
					reader.readAsArrayBuffer(file);
				}}
			/>
			<div className="grid grid-cols-3 gap-2">
				{Object.keys(progress).map((key) => {
					const processed =
						progress[key].processedSuccess +
						progress[key].processedError;
					if (notFoundErrors[key]) {
						return (
							<div
								key={key}
								className="flex flex-col bg-gray-200 rounded-md p-4"
							>
								<h1 className="font-bold">{key}</h1>
								<p>Datei nicht im Importverzeichnis</p>
							</div>
						);
					}

					return (
						<div
							key={key}
							className={clsx(
								"flex flex-col bg-gray-200 rounded-md p-4",
								{
									"border-red-500 border-2":
										progress[key].processedError > 0,
									"bg-green-200":
										processed ===
											progress[key].totalLength &&
										processed > 0,
								}
							)}
						>
							<h1 className="font-bold">{key}</h1>
							<LinearProgressWithLabel
								value={
									(processed / progress[key].totalLength) *
										100 || 0
								}
							/>
							{progress[key].processedError > 0 && (
								<p>Fehler: {progress[key].processedError}</p>
							)}
							Gesamt: {processed}/{progress[key].totalLength}
						</div>
					);
				})}
			</div>
			{Object.keys(rowErrors).length > 0 && (
				<div className="flex flex-col gap-2">
					<h1 className="font-bold">Fehlerhafte Zeilen</h1>
					<div className="grid grid-cols-1 gap-2">
						{Object.keys(rowErrors).map((key) => {
							return (
								<div
									key={key}
									className="flex flex-col bg-gray-200 rounded-md p-4"
								>
									<h1 className="font-bold">{key}</h1>
									{rowErrors[key].length > 0 &&
										rowErrors[key].map((row, index) => (
											<p key={index}>{row.row_value}</p>
										))}
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database } from "../../../../../../lib/supabase";
import { InvoiceSettingsAGroup } from "../input-components/invoice-settings-a.group";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { InvoiceSettingsBGroup } from "../input-components/invoice-settings-b.group";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

// Form to update (not create!) client invoice settings
export const ClientInvoiceSettingsForm: React.FC<FormProps> = ({
	onSave = () => {},
	submitRef = null,
}) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const clientId = useCentralStore((state) => state.clientId);
	const { setIsPendingChanges } = useCustomDialogContext();
	const {
		handleCheckboxChange,
		handleInputChange,
		handleValueChange,
		formData,
		formErrors,
		handleUpdate,
		isPendingChanges,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["clients"]["Row"]>(
		{
			organization_id: organizationId,
		},
		SupabaseTableEnum.CLIENTS,
		true,
		clientId,
		"Auftraggeber",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!clientId) {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
			Logger.error("No client id found in store");
			return;
		}

		const { success, data } = await handleUpdate(event, clientId);
		if (success && data) {
			const client = data[0];
			onSave(client);
			useCentralStore.getState().upsertClient(client);
		} else {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={!isPendingChanges}
			margin="none"
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					maxWidth: "500px",
					marginTop: "1.5rem",
				}}
			>
				<InvoiceSettingsAGroup
					formData={formData}
					formErrors={formErrors}
					onCheckboxChange={handleCheckboxChange}
				/>
				<InvoiceSettingsBGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onValueChange={(args: any) =>
						handleValueChange(args.name, args.value)
					}
					onCheckboxChange={handleCheckboxChange}
				/>
			</div>
		</FormSectionWithSaveButton>
	);
};

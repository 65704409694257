import {
	ClientEntityType,
	GuarantorEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import "../fonts.css";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { QrPaymentSlip } from "../pdf-template-components/qr-payment-slip/qr-payment-slip.component";
import { calculateSammelrechnungRowsAndTotals } from "../../lib/utils/calculate";
import { PdfTemplateType } from "../document-template.types";
import { PdfTableTotalsSection } from "../pdf-template-components/pdf-table-totals-section.component";

export interface SammelrechnungTemplateProps {
	recipient: ClientEntityType | GuarantorEntityType;
	sammelrechnungCalculationResult: ReturnType<
		typeof calculateSammelrechnungRowsAndTotals
	>;
	organization: OrganizationEntityType | null;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	paymentSlipData: PaymentSlipDataInput;
	logoUrl?: string;
}

export const SammelrechnungTemplate: React.FC<SammelrechnungTemplateProps> = ({
	recipient,
	organization,
	sammelrechnungCalculationResult,
	pdfTemplateConfiguration,
	paymentSlipData,
	logoUrl,
}) => {
	if (
		!organization ||
		!recipient ||
		!pdfTemplateConfiguration ||
		!sammelrechnungCalculationResult ||
		!paymentSlipData
	) {
		return null;
	}
	const { rows: tableRows } = sammelrechnungCalculationResult;
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.SAMMELRECHNUNG}
				logoUrl={logoUrl}
			/>
			<PdfRecipientAddress
				recipient={recipient}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate
					title="Sammelrechnung"
					// As a Sammelrechnung is multiple jobs, we use the current date (i.e. when the PDF is generated) as the date
					date={new Date().toLocaleDateString()}
				/>
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<table>
						<thead>
							<tr>
								<td
									style={{
										paddingRight: "5mm",
									}}
								>
									Rg.-Nummer
								</td>
								<td>{paymentSlipData.invoiceNumber}</td>
							</tr>
						</thead>
					</table>
				</div>
				<div
					aria-label="Tabelle mit allen Aufträgen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<table
						style={{
							width: "100%",
							borderCollapse: "collapse",
							fontSize: "9pt",
						}}
					>
						<thead>
							<tr
								style={{
									backgroundColor: "lightgrey",
									borderBottom: "1px solid black",
								}}
							>
								<th
									style={{
										textAlign: "left",
										width: "14%",
									}}
								>
									Auftrag
								</th>
								<th
									style={{
										textAlign: "left",
										width: "14%",
									}}
								>
									Datum
								</th>
								<th
									style={{
										textAlign: "left",
										width: "28%",
									}}
								>
									Auftraggeber
								</th>
								<th
									style={{
										textAlign: "left",
										width: "30%",
									}}
								>
									Titel
								</th>
								<th
									style={{
										textAlign: "right",
										width: "14%",
									}}
								>
									Betrag
								</th>
							</tr>
						</thead>
						<tbody>
							{tableRows.map((row, i) => (
								<tr key={row.jobCode + "-" + i}>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{row.jobCode}
									</td>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{row.jobDocumentDate}
									</td>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{row.clientName}
									</td>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{row.jobTitle}
									</td>
									<td
										style={{
											textAlign: "right",
										}}
									>
										{row.jobTotals.total.text}
									</td>
								</tr>
							))}
							<PdfTableTotalsSection
								totals={{ ...sammelrechnungCalculationResult }}
								numberOfColumns={5}
								numberOfAdditionalColumns={0}
								organization={organization}
								monthlyDiscount={
									sammelrechnungCalculationResult
										.monthlyDiscount.text
								}
							/>
						</tbody>
					</table>
				</div>
				<div
					aria-label="Fusszeile"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					{
						pdfTemplateConfiguration[PdfTemplateType.SAMMELRECHNUNG]
							.footerText
					}
				</div>
				<QrPaymentSlip
					pdfTemplateConfiguration={pdfTemplateConfiguration}
					paymentSlipData={paymentSlipData}
				/>
			</div>
		</PdfLayout>
	);
};

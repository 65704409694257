import {
	ClientEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { KostenvoranschlagTemplate } from "../../pdf-templates/pdf-templates/kostenvoranschlag.template";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import {
	CalculateTotalsResult,
	calculateSammelrechnungRowsAndTotals,
} from "../../lib/utils/calculate";
import { LieferscheinTemplate } from "../../pdf-templates/pdf-templates/lieferschein.template";
import { GutschriftTemplate } from "../../pdf-templates/pdf-templates/gutschrift.template";
import { RechnungTemplate } from "../../pdf-templates/pdf-templates/rechnung.template";
import { PaymentSlipDataInput } from "../../pdf-templates/pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { SammelrechnungTemplate } from "../../pdf-templates/pdf-templates/sammelrechnung.template";
import { KartenzahlungsquittungTemplate } from "../../pdf-templates/pdf-templates/kartenzahlungsquittung.template";
import { BarzahlungsquittungTemplate } from "../../pdf-templates/pdf-templates/barzahlungsquittung.template";
import { AkontorechnungTemplate } from "../../pdf-templates/pdf-templates/akontorechnung.template";
import { ZahlungserinnerungTemplate } from "../../pdf-templates/pdf-templates/zahlungserinnerung.template";
import { ErsteMahnungTemplate } from "../../pdf-templates/pdf-templates/erste-mahnung.template";
import { FolgemahnungTemplate } from "../../pdf-templates/pdf-templates/folgemahnung.template";
import { AuftragsblattTemplate } from "../../pdf-templates/pdf-templates/auftragsblatt.template";

export const PdfTemplateOptions = ({
	client,
	jobExample,
	jobItems,
	jobDocumentExample,
	organization,
	pdfTemplateConfiguration,
	patientExample,
	jobTotals,
	paymentSlipData,
	sammelrechnungCalculationResult,
	selectedPdfTemplate,
	logoUrl,
	clientAvatarUrl,
}: {
	client: ClientEntityType;
	jobExample: JobEntityType;
	jobItems: JobItemEntityType[];
	jobDocumentExample: JobDocumentEntityType;
	organization: OrganizationEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	patientExample: PatientEntityType;
	jobTotals: CalculateTotalsResult;
	paymentSlipData: PaymentSlipDataInput;
	sammelrechnungCalculationResult: ReturnType<
		typeof calculateSammelrechnungRowsAndTotals
	>;
	selectedPdfTemplate: PdfTemplateType;
	logoUrl?: string;
	clientAvatarUrl?: string;
}) => {
	const templates = {
		[PdfTemplateType.KOSTENVORANSCHLAG]: (
			<KostenvoranschlagTemplate
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				patient={patientExample}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.LIEFERSCHEIN]: (
			<LieferscheinTemplate
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.GUTSCHRIFT]: (
			<GutschriftTemplate
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.RECHNUNG]: (
			<RechnungTemplate
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				paymentSlipData={paymentSlipData}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.SAMMELRECHNUNG]: (
			<SammelrechnungTemplate
				recipient={client}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				sammelrechnungCalculationResult={
					sammelrechnungCalculationResult
				}
				paymentSlipData={paymentSlipData}
				logoUrl={logoUrl}
			/>
		),

		[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: (
			<KartenzahlungsquittungTemplate
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				paymentSlipData={paymentSlipData}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.BARZAHLUNGSQUITTUNG]: (
			<BarzahlungsquittungTemplate
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				client={client}
				job={jobExample}
				jobItems={jobItems.slice(0, 10)}
				jobDocument={jobDocumentExample}
				paymentSlipData={paymentSlipData}
				jobTotals={jobTotals}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.AKONTORECHNUNG]: (
			<AkontorechnungTemplate
				client={client}
				job={jobExample}
				jobItems={jobItems}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				paymentSlipData={paymentSlipData}
				date="01.01.2022" //TODO(pdf)
				logoUrl={logoUrl}
			/>
		),

		[PdfTemplateType.ZAHLUNGSERRINERUNG]: (
			<ZahlungserinnerungTemplate
				client={client}
				job={jobExample}
				jobDocument={jobDocumentExample}
				jobItems={jobItems}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				dateInvoice="01.01.2022" //TODO(pdf)
				dateZahlungserinnerung="01.02.2022" //TODO(pdf)
				paymentSlipData={paymentSlipData}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.ERSTE_MAHNUNG]: (
			<ErsteMahnungTemplate
				client={client}
				job={jobExample}
				jobDocument={jobDocumentExample}
				jobItems={jobItems}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				dateMahnung="01.01.2022" //TODO(pdf)
				paymentSlipData={paymentSlipData}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.FOLGEMAHNUNG]: (
			<FolgemahnungTemplate
				client={client}
				job={jobExample}
				jobDocument={jobDocumentExample}
				jobItems={jobItems}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				dateMahnung="01.01.2022" //TODO(pdf)
				paymentSlipData={paymentSlipData}
				logoUrl={logoUrl}
			/>
		),
		[PdfTemplateType.AUFTRAGSBLATT]: (
			<AuftragsblattTemplate
				client={client}
				job={jobExample}
				jobDocument={jobDocumentExample}
				jobItems={jobItems}
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				patient={patientExample}
				// Having a dummy value here is fine as it is only for the preview
				qrCodeUrl="https://www.google.com"
				clientAvatarUrl={clientAvatarUrl}
			/>
		),
		[PdfTemplateType.RECHNUNGSDOPPEL]: <></>,
	};

	return templates[selectedPdfTemplate];
};

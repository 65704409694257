import {
	JobItemEntityType,
	PatientEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "../pdf-templates/document-template.types";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../store/Central/selectors";
import {
	AccountingDocumentStatusEnum,
	GsStatusEnum,
	KvStatusEnum,
	LsStatusEnum,
} from "../hooks/actions/actions-hooks-types";
import { InvoiceType, useInvoiceRpc } from "../hooks/actions/useInvoiceRpc";
import { calculateJobTotalsLegacy } from "../lib/utils/calculate";
import { useCentralStore } from "../store/Central";
import { useJobStore } from "../store/Jobs";

import { useExportActions } from "../hooks/actions/useExportActions";
import { useState } from "react";
import { getUnderscoredString } from "../lib/utils/utils-functions";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../types/enums";
import { JobStatusEnum } from "@/lib/types/job";
import { useJobActions } from "../hooks/actions/useJobActions";
import { JobDocumentWithFiles } from "../pages/job-page/job-document/job-document.component";
import { usePatientStore } from "../store/Patient";

const documentIsInvoice = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.RECHNUNG ||
		type === PdfTemplateType.BARZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.KARTENZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.AKONTORECHNUNG
	);
};

const documentIsDocument = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.KOSTENVORANSCHLAG ||
		type === PdfTemplateType.LIEFERSCHEIN ||
		type === PdfTemplateType.AUFTRAGSBLATT ||
		type === PdfTemplateType.GUTSCHRIFT
	);
};

const documentIsXml = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === XmlTemplateType.KOSTENVORANSCHLAG_XML ||
		type === XmlTemplateType.LIEFERSCHEIN_XML
	);
};

export const useHandleExportDocument = () => {
	const {
		documentPropsFor,
		paymentSlipDataFor,
		bankAccountFor,
		exportRechnung,
		exportBarzahlungsquittung,
		exportKartenzahlungsquittung,
		exportKostenvoranschlag,
		exportLieferschein,
		exportAuftragsblatt,
		exportGutschrift,
		exportXmlKostenvoranschlag,
		exportXmlLieferschein,
	} = useExportActions();

	const { patientsLookup } = usePatientStore((state) => ({
		patientsLookup: state.patientsLookup,
	}));

	const { updateJobStatus } = useJobActions();

	const { job, updateJobDocumentStatus, fetchJobDocument } = useJobStore(
		(state) => ({
			job: state.job,
			updateJobDocumentStatus: state.updateJobDocumentStatus,
			fetchJobDocument: state.fetchJobDocument,
		})
	);

	const { organization, client } = useCentralStore((state) => ({
		organization: state.organization,
		client: state.client,
	}));

	const { createInvoiceSingle } = useInvoiceRpc();

	const [jobDocumentLoading, setJobDocumentLoading] = useState<number | null>(
		null
	);
	const handleExportDocument = async (
		templateType: PdfTemplateType | XmlTemplateType,
		jobItems: JobItemEntityType[],
		jobDocument: JobDocumentWithFiles
	) => {
		if (!job) {
			Logger.error("Job not found");
			return;
		}
		if (!client) {
			Logger.error("Client not found");
			return;
		}
		if (!organization) {
			Logger.error("Organization not found");
			return;
		}

		setJobDocumentLoading(jobDocument.id);

		const completeJobInformation = {
			client,
			job,
			jobItems,
			jobDocument,
		};

		const bankAccount = bankAccountFor(client);
		if (!bankAccount) {
			setJobDocumentLoading(null);
			showNotification({
				message: "Bankkonto nicht gefunden",
				type: "error",
			});
			return;
		}

		const documentProps = documentPropsFor(completeJobInformation);
		if (!documentProps) {
			showNotification({
				message: "Dokumentinformationen konnten nicht erstellt werden",
				type: "error",
			});
			return;
		}

		if (documentIsInvoice(templateType)) {
			let fileNamePrefix = "Rechnung-";
			let invoiceStatus = AccountingDocumentStatusEnum.CREATED;
			let invoiceType = InvoiceType.RECHNUNG;
			if (templateType === PdfTemplateType.BARZAHLUNGSQUITTUNG) {
				fileNamePrefix = "Barzahlungsquittung-";
				invoiceStatus = AccountingDocumentStatusEnum.PAID;
				invoiceType = InvoiceType.BARRECHNUNG;
			} else if (
				templateType === PdfTemplateType.KARTENZAHLUNGSQUITTUNG
			) {
				fileNamePrefix = "Kartenzahlungsquittung-";
				invoiceStatus = AccountingDocumentStatusEnum.PAID;
				invoiceType = InvoiceType.KARTENZAHLUNGSRECHNUNG;
			} else if (templateType === PdfTemplateType.AKONTORECHNUNG) {
				showNotification({
					message: "Akontorechnung noch nicht implementiert",
					type: "error",
				});
			}

			// TODO: For Akontorechnung this would be different
			const invoiceValue = calculateJobTotalsLegacy({
				job,
				jobDocument,
				jobItems,
				organization,
			}).total.value;

			const {
				success: createInvoiceSuccess,
				data: createInvoiceData,
				error: createInvoiceError,
			} = await createInvoiceSingle({
				fileNamePrefix,
				invoiceValue,
				bankAccount,
				invoiceType,
				pdfTemplateType: templateType as PdfTemplateType,
				client,
				invoiceStatus,
				organization,
				jobDocument,
				job,
			});

			if (!createInvoiceSuccess || !createInvoiceData) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Rechnung konnte nicht erstellt werden",
					type: "error",
				});
				Logger.error("Error creating invoice", { createInvoiceError });
				return;
			}

			const { invoiceNumber, filePath } = createInvoiceData;

			const paymentSlipData = paymentSlipDataFor(
				completeJobInformation,
				invoiceNumber
			);
			if (!paymentSlipData) {
				return {
					success: false,
					error: "Zahlungsinformationen konnten nicht erstellt werden",
				};
			}

			let success;
			if (templateType === PdfTemplateType.RECHNUNG) {
				const { success: s } = await exportRechnung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (templateType === PdfTemplateType.BARZAHLUNGSQUITTUNG) {
				const { success: s } = await exportBarzahlungsquittung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (
				templateType === PdfTemplateType.KARTENZAHLUNGSQUITTUNG
			) {
				const { success: s } = await exportKartenzahlungsquittung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (templateType === PdfTemplateType.AKONTORECHNUNG) {
				// TODO
				success = false;
			} else {
				success = false;
				Logger.error("Unknown invoice type");
			}

			if (!success) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Rechnung konnte nicht exportiert werden",
					type: "error",
				});
				return;
			}
		} else if (documentIsDocument(templateType)) {
			// kv
			let fileName = `${getUnderscoredString(
				jobDocument.title ?? ""
			)}-${job?.code}`;
			let pathName = uuidv4() + "/" + fileName;
			if (templateType === PdfTemplateType.LIEFERSCHEIN) {
				fileName = `${getUnderscoredString(
					jobDocument.title ?? ""
				)}-${job?.code}`;
				pathName = uuidv4() + "/" + fileName;
			} else if (templateType === PdfTemplateType.AUFTRAGSBLATT) {
				fileName = `Auftagsblatt-${job?.code}`;
				pathName = uuidv4() + "/" + fileName;
			} else if (templateType === PdfTemplateType.GUTSCHRIFT) {
				fileName = `${getUnderscoredString(
					jobDocument.title ?? ""
				)}-${job?.code}`;
				pathName = uuidv4() + "/" + fileName;
			}

			const { error } = await supabase
				.from(SupabaseTableEnum.FILES)
				.insert([
					{
						file_name: fileName,
						bucket_name: StorageBucketsEnum.V1,
						path_name: pathName,
						job_id: job.id,
						meta_data: {
							type: "pdf",
							pdf_template_type: templateType,
						},
						organization_id: organization.id,
						job_document_id: jobDocument.id,
					},
				])
				.select();

			if (error) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Fehler beim Erstellen des Dokuments (DB)",
					type: "error",
				});
				Logger.error("Error creating document", { error });
				return;
			}

			let exportError = null;
			if (templateType === PdfTemplateType.KOSTENVORANSCHLAG) {
				await updateJobDocumentStatus(
					jobDocument.id,
					KvStatusEnum.COMPLETED,
					calculateJobTotalsLegacy({
						job,
						jobDocument,
						jobItems,
						organization,
					}).total.value,
					null
				);
				const { error } = await exportKostenvoranschlag(
					documentProps,
					pathName
				);
				exportError = error;
			} else if (templateType === PdfTemplateType.LIEFERSCHEIN) {
				updateJobDocumentStatus(
					jobDocument.id,
					LsStatusEnum.COMPLETED,
					calculateJobTotalsLegacy({
						job,
						jobDocument,
						jobItems,
						organization,
					}).total.value,
					null
				);
				// when the delivery note is exported, the job is completed and gets an invoice amount
				updateJobStatus(job.id as number, JobStatusEnum.COMPLETED);
				const { error } = await exportLieferschein(
					documentProps,
					pathName
				);
				exportError = error;
			} else if (templateType === PdfTemplateType.AUFTRAGSBLATT) {
				const { error } = await exportAuftragsblatt(
					documentProps,
					pathName
				);
				exportError = error;
			} else if (templateType === PdfTemplateType.GUTSCHRIFT) {
				updateJobDocumentStatus(
					jobDocument.id,
					GsStatusEnum.COMPLETED,
					calculateJobTotalsLegacy({
						job,
						jobDocument,
						jobItems,
						organization,
					}).total.value,
					null
				);
				const { error } = await exportGutschrift(
					documentProps,
					pathName
				);
				exportError = error;
			}

			if (exportError) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Fehler beim Erstellen des Dokuments (Export)",
					type: "error",
				});
				Logger.error("Error creating Lieferschein 1", { exportError });
				return;
			}
		} else if (documentIsXml(templateType)) {
			if (!job.patient_id || !patientsLookup[job.patient_id]) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Patient nicht gefunden",
					type: "error",
				});
				return;
			}

			let exportError = null;
			if (templateType === XmlTemplateType.KOSTENVORANSCHLAG_XML) {
				const { error } = await exportXmlKostenvoranschlag(
					completeJobInformation,
					patientsLookup[job.patient_id] as PatientEntityType
				);
				exportError = error;
			} else if (templateType === XmlTemplateType.LIEFERSCHEIN_XML) {
				const { error } = await exportXmlLieferschein(
					completeJobInformation,
					patientsLookup[job.patient_id] as PatientEntityType
				);
				exportError = error;
			}

			if (exportError) {
				setJobDocumentLoading(null);
				showNotification({
					message: "Fehler beim Erstellen des Dokuments (XML Export)",
					type: "error",
				});
				Logger.error("Error creating XML document", { exportError });
				return;
			}
		}

		// wait for 1 second before refetching the job documents
		// TODO: instead call database to see when the file is there
		await new Promise((resolve) => setTimeout(resolve, 1000));
		fetchJobDocument(jobDocument.id);
		setJobDocumentLoading(null);
	};

	return {
		jobDocumentLoading,
		handleExportDocument,
	};
};

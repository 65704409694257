import { supabase } from "@/lib/supabase";
import { TariffsConfigDefault } from "../../forms/form-components/configuration/configuration-defaults";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { State } from "./types";
import { StorageBucketsEnum } from "../../types/enums";
import { useCentralStore } from ".";

export const initialState: State = {
	mfaVerified: false,
	mfaAuthenticated: false,
	mfaEnrolled: false,
	role: null,
	mfaDialogOpen: false,
	connectRelationshipId: null,
	initialized: false,
	clientId: null,
	organization: null,
	userId: null,
	userEmail: null,
	isConnectUser: false,
	clientsLookup: {},
	client: null,
	tariffs: {},
	articles: {},
	profile: null,
	guarantorLookup: {},

	searchConfig: null,
	tariffsConfig: TariffsConfigDefault,
	printersConfig: {
		default: null,
		[PdfTemplateType.AKONTORECHNUNG]: null,
		[PdfTemplateType.AUFTRAGSBLATT]: null,
		[PdfTemplateType.BARZAHLUNGSQUITTUNG]: null,
		[PdfTemplateType.ERSTE_MAHNUNG]: null,
		[PdfTemplateType.FOLGEMAHNUNG]: null,
		[PdfTemplateType.GUTSCHRIFT]: null,
		[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: null,
		[PdfTemplateType.KOSTENVORANSCHLAG]: null,
		[PdfTemplateType.LIEFERSCHEIN]: null,
		[PdfTemplateType.RECHNUNG]: null,
		[PdfTemplateType.RECHNUNGSDOPPEL]: null,
		[PdfTemplateType.SAMMELRECHNUNG]: null,
		[PdfTemplateType.ZAHLUNGSERRINERUNG]: null,
		etik_auftrag: null,
		etik_modell: null,
		etik_versand: null,
	},
	clientLoading: true,
	clientsLoading: true,
};

export const fetchClientAvatar = async (avatar_path: string) => {
	const organizationId = useCentralStore.getState().organization?.id;
	const image_src = (
		await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.createSignedUrl(`${organizationId}/${avatar_path}`, 3600, {
				transform: {
					quality: 35,
				},
			})
	).data?.signedUrl as string;

	return image_src;
};

import {
	ClientEntityType,
	GuarantorEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { PdfJobInformation } from "../pdf-template-components/pdf-job-information.component";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PdfJobItemsTable } from "../pdf-template-components/pdf-job-items-table";
import { PdfTemplateType } from "../document-template.types";
import "../fonts.css";
import { CalculateTotalsResult } from "../../lib/utils/calculate";

export interface GutschriftTemplateProps {
	client: ClientEntityType | null;
	job: JobEntityType | null;
	jobDocument: JobDocumentEntityType | null;
	jobItems: JobItemEntityType[] | null;
	organization: OrganizationEntityType | null;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
	jobTotals: CalculateTotalsResult;
	logoUrl?: string;
}

export const GutschriftTemplate: React.FC<GutschriftTemplateProps> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	jobTotals,
	logoUrl,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.GUTSCHRIFT}
				logoUrl={logoUrl}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate
					title={jobDocument?.title ?? "Gutschrift"}
					date={jobDocument?.date}
				/>
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<PdfJobInformation
						pdfTemplateType={PdfTemplateType.GUTSCHRIFT}
						client={client}
						job={job}
						jobItems={jobItems}
						patient={patient}
					/>
				</div>
				<div
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					{jobItems && job && (
						<PdfJobItemsTable
							jobTotals={jobTotals}
							jobDocument={jobDocument}
							jobItems={jobItems}
							organization={organization}
							showDrTax={true}
						/>
					)}
				</div>
				<div
					aria-label="Fusszeile"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					{
						pdfTemplateConfiguration[PdfTemplateType.GUTSCHRIFT]
							.footerText
					}
				</div>
			</div>
		</PdfLayout>
	);
};

import { Paper } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { useToast } from "../../context/ToastContext";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../../store/Central";

const columns: GridColDef[] = [
	{ field: "code", headerName: "Auftraggeber-Nummer", width: 150 },
	{ field: "incoming_order_date", headerName: "Eingangsdatum", width: 150 },
	{ field: "title", headerName: "Titel", width: 150 },
	{ field: "tooth_color", headerName: "Zahnfarbe", width: 150 },
	{ field: "description", headerName: "Beschreibung", width: 150 },
	{ field: "additional_information", headerName: "Zusatz", width: 150 },
	{ field: "pat_code", headerName: "Patienten-Nummer", width: 150 }, // TODO: ability to edit patient from job
	{ field: "currency", headerName: "Währung", width: 150 },
	{ field: "exchange_ratio", headerName: "Wechselkurs", width: 150 },
	{ field: "discount_work", headerName: "Rabatt Arbeit", width: 150 },
	{ field: "discount_material", headerName: "Rabatt Material", width: 150 },
	{ field: "tax_rate", headerName: "MwSt", width: 150 },
	{ field: "prod_country_choice", headerName: "Produktionsland", width: 150 },
	{ field: "max_inv_value", headerName: "Max. Rechnungswert", width: 150 },
	{ field: "status", headerName: "Status", width: 150 },
	{ field: "tp_value", headerName: "TP-Wert", width: 150 },
];

export const JobList: React.FC = () => {
	const [rows, setRows] = useState<GridRowsProp>([]);
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { showToast } = useToast();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const { data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.JOBS,
			{
				column: "organization_id",
				value: organizationId,
			}
		);
		if (error) {
			showToast("Error beim Abrufen der Aufträge", "error");
			Logger.error("Error fetching jobs", error);
		}
		setRows(data ?? []);
	};

	return (
		<Paper>
			<DataGrid rows={rows} columns={columns} autoHeight />
		</Paper>
	);
};

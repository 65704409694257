import {
	ClientEntityType,
	GuarantorEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { CalculateTotalsResult } from "../../lib/utils/calculate";
import { PdfInvoiceBody } from "../pdf-template-components/pdf-invoice-body.component";

export interface KartenzahlungsquittungTemplateProps {
	client: ClientEntityType | null;
	job: JobEntityType | null;
	jobDocument: JobDocumentEntityType | null;
	jobItems: JobItemEntityType[] | null;
	organization: OrganizationEntityType | null;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
	paymentSlipData: PaymentSlipDataInput;
	jobTotals: CalculateTotalsResult;
	logoUrl?: string;
}

export const KartenzahlungsquittungTemplate: React.FC<
	KartenzahlungsquittungTemplateProps
> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	paymentSlipData,
	jobTotals,
	logoUrl,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client ||
		!paymentSlipData
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				logoUrl={logoUrl}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
			<PdfInvoiceBody
				title="Quittung"
				client={client}
				job={job}
				jobDocument={jobDocument}
				jobItems={jobItems}
				patient={patient}
				paymentSlipData={paymentSlipData}
				organization={organization}
				jobTotals={jobTotals}
			/>
			<div
				aria-label="Fusszeile"
				style={{
					paddingTop: "5mm",
					fontSize: "10pt",
				}}
			>
				{
					pdfTemplateConfiguration[
						PdfTemplateType.KARTENZAHLUNGSQUITTUNG
					].footerText
				}
			</div>
		</PdfLayout>
	);
};

import { JobDocumentEntityType } from "@/lib/supabase/supabaseTypes";

export const jobDocumentExample: JobDocumentEntityType = {
	id: 1341,
	created_at: "2024-02-18 20:14:36.056809+00",
	modified_at: "2024-02-18 20:14:36.056809+00",
	title: "Kostenvoranschlag",
	created_by: null,
	modified_by: null,
	job_id: 1856,
	type: "qt",
	type_data: null,
	status: 1,
	amount: null,
	date: "2024-02-18",
	discount_material: 10,
	discount_work: 20,
	fae_id: null,
};

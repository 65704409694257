import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileButtonWithPopover } from "../../../components/button-with-popover/profile-button-with-popover.component";
import { SearchDialog } from "../../../components/search-dialog.component";
import {
	PractitionerForm,
	PractitionerFormProps,
} from "../../../forms/form-components/practitioner/insert-update/practitioner.form";
import { useFormDialog } from "../../../hooks/useFormDialog";
import {
	PractitionerEntityType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";
import { useDatabaseFunctionsWithPromise } from "../../../hooks/useDatabase";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

export const PractitionerDetails = () => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const { job } = useJobStore((state) => ({
		job: state.job,
	}));
	const {
		practitioners: { practitionersLookupById },
	}: any = usePractitionersContext();

	const [practitioner, setPractitioner] = useState<any>(
		job?.practitioner_id
			? practitionersLookupById[job?.practitioner_id]
			: null
	);

	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();

	const handleChange = (practitioner: PractitionerEntityType) => {
		updatePractitioner(practitioner);
	};

	const updatePractitioner = async (practitioner: PractitionerEntityType) => {
		const { success, data, error } = await updateDataWithPromise(
			SupabaseTableEnum.JOBS,
			[{ id: job?.id, practitioner_id: practitioner.id }]
		);
		if (success) {
			setPractitioner(practitioner);
		}
		if (error) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
				type: "error",
			});
		}
	};

	const { openDialog, dialog } = useFormDialog<PractitionerFormProps>({
		onSubmit: (practitioner: PractitionerEntityType) => {
			handleChange(practitioner);
		},
		FormComponent: PractitionerForm,
		dialogTitle: "Behandler hinzufügen",
	});

	const clientId = job?.client_id;

	return (
		<>
			{dialog}
			<ProfileButtonWithPopover
				key="practitioner"
				name={{
					first_name: practitioner?.first_name,
					last_name: practitioner?.last_name,
				}}
				label="Behandler"
				onClick={() => navigate(`/practitioner/${practitioner.id}`)}
				onAdd={() => setOpen(true)}
			>
				<Stack direction="column">
					<Typography variant="h6">
						{practitioner?.first_name} {practitioner?.last_name}
					</Typography>
					<Button
						onClick={() =>
							navigate(`/practitioner/${practitioner.id}`)
						}
					>
						Behandler ansehen
					</Button>
					<Button
						onClick={() =>
							navigate(`/jobs?practitioner_id=${practitioner.id}`)
						}
					>
						Alle Behandler
					</Button>
					<Button onClick={() => setOpen(true)}>
						Behandler ändern
					</Button>
				</Stack>
			</ProfileButtonWithPopover>
			{clientId && (
				<SearchDialog
					open={open}
					onClose={() => setOpen(false)}
					supabaseTable="practitioners"
					onSelect={(practitioner: PractitionerEntityType) => {
						handleChange(practitioner), setOpen(false);
					}}
					onAction={() => {
						setOpen(false);
						openDialog({ clientId: clientId });
					}}
					actionText="Neuen Behandler anlegen"
					dialogTitle="Behandler auswählen"
					customFetch={{ column: "client_id", value: clientId }}
				/>
			)}
		</>
	);
};

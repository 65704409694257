import {
	PatientEntityType,
	PatientWithShare,
	SupabaseShareEnitityViewEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { useCentralStore } from "../Central";
import { Logger } from "@/lib/logger/Logger";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";

export interface PatientStoreSlice {
	patientsLookup: Record<string, PatientWithShare>;
	fetchPatients: () => Promise<void>;
	upsertPatient: (patient: PatientWithShare) => void;
}

export const createPatientStore: StateCreator<PatientStoreSlice> = (set) => ({
	patientsLookup: {},
	fetchPatients: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) {
			Logger.error("No organization ID");
			return;
		}

		const { success, data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseShareEnitityViewEnum.PATIENTS_WITH_SHARES)
				.select()
				.eq("organization_id", organizationId)
				.order("modified_at", { ascending: false })
		);

		if (success && data) {
			const patientsLookup = data.reduce(
				(
					acc: Record<string, PatientEntityType>,
					patient: PatientEntityType
				) => {
					acc[patient.id] = patient;
					return acc;
				},
				{} as Record<string, PatientEntityType>
			);

			set({
				patientsLookup,
			});
		} else {
			Logger.error("fetchPatients Error", error);
		}
	},
	upsertPatient: (patient) => {
		set((state) => ({
			patientsLookup: {
				...state.patientsLookup,
				[patient.id as string]: {
					...patient,
					shared_ids: patient.shared_ids ?? null,
				},
			},
		}));
	},
});

import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { SignInLayout } from "./sign-in-layout.component";

export const TechnicianSignIn: React.FC<{
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}> = ({ onSubmit }) => {
  return (
    <SignInLayout
      title="Willkommen zurück!"
      subtitle="Melden Sie sich mit Ihren dentLAB-Daten an."
    >
      <Box component="form" onSubmit={onSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-Mail"
          name="email"
          autoComplete="email"
          autoFocus
          size="medium"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Passwort"
          type="password"
          id="password"
          autoComplete="current-password"
          size="medium"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          size="large"
        >
          Anmelden
        </Button>
      </Box>
      <a
        href={`mailto:support@dentdesk.ch?subject=Passwort zurückseten&body=Bitte senden Sie mir einen Link, um das Passwort für meinen Account zurückzusetzen. Meine Email lautet: [BITTE EMAIL ANGEBEN].`}
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <Typography variant="body2" align="center">
          Passwort vergessen?
        </Typography>
      </a>
      <a
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          margin: "20px",
          fontSize: "16px",
          color: "var(--neutral-light)",
          textDecoration: "none",
        }}
        // @ts-ignore
        href={import.meta.env.VITE_DENTCONNECT_URL}
        rel="noopener noreferrer"
      >
        Ich bin Zahnarzt
      </a>
    </SignInLayout>
  );
};

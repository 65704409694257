import { ArticleEntityType } from "../../../../../../lib/supabase/supabaseTypes";
import { DialogPageWithAddEntityForm } from "../../../../components/dialog-components/dialog-pages/dialog-page-with-add-entity-form/dialog-page-with-add-entity-form.component";
import { DefaultPositionsList } from "../../../../components/list-components/default-positions-list.component";
import { useJobItemsContext } from "../../../../context/JobItemsContext";
import { ArticlesForm } from "../../../../forms/form-components/articles-form";
import { articlesColumns } from "./articles.columns";

export const ManageArticlesTab: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { articles, recognizeNewArticle, handleUpdateArticle } =
    useJobItemsContext();
  return (
    <DialogPageWithAddEntityForm
      FormComponent={ArticlesForm}
      onClose={onClose}
      tools={<></>}
      isFormOpen={false}
      formAddButtonLabel="Neuer Artikel"
      formAddTitle="Artikel hinzufügen"
      onAddEntity={(article: ArticleEntityType) => {
        recognizeNewArticle(article);
      }}
    >
      <DefaultPositionsList
        rows={articles}
        columns={articlesColumns}
        gridConfigName="articles"
        onRowUpdate={(row: ArticleEntityType) => {
          handleUpdateArticle(row);
        }}
      />
    </DialogPageWithAddEntityForm>
  );
};

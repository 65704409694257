import {
	BankAccountEntityType,
	ClientEntityType,
	JobDocumentEntityType,
	JobEntityType,
	OrganizationEntityType,
	SupabaseRpcEnum,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { AccountingDocumentStatusEnum } from "./actions-hooks-types";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";

const DEFAULT_CURRENCY = "CHF";

export enum InvoiceType {
	RECHNUNG = 0,
	BARRECHNUNG = 1,
	KARTENZAHLUNGSRECHNUNG = 11, // New type for card payments
	AKONTORECHNUNG = 2,
	//TODO Akontoverrechnung = 6,
	//TODO Rueckverguetung = 7,
	//TODO: Verlustbuchung = 8,
	MONATSRG = 20,
}

export enum PaymentType {
	ZAHLUNG = 3,
	BARZAHLUNG = 4,
	KARTENZAHLUNG = 5,
}

interface CreateInvoiceSingleProps {
	fileNamePrefix: string;
	invoiceValue: number;
	bankAccount: BankAccountEntityType;
	invoiceType: InvoiceType;
	pdfTemplateType: PdfTemplateType;
	client: ClientEntityType;
	invoiceStatus: AccountingDocumentStatusEnum;
	organization: OrganizationEntityType;
	jobDocument: JobDocumentEntityType;
	job: JobEntityType;
}

interface CreateInvoiceMonthlyProps {
	invoiceValue: number;
	bankAccountId: number;
	clientId: string | null;
	guarantorId: string | null;
	organizationId: string;
	jobDocumentIds: number[];
}

interface CreateInvoiceReturnType {
	success: boolean;
	data: {
		invoiceNumber: string;
		filePath: string;
	} | null;
	error: string | null;
}

/**
 * Should be the only way to interact with the invoice table.
 * Because doc numbers and so on need to be set on the server side.
 */
export const useInvoiceRpc = () => {
	const createInvoiceSingle = async ({
		fileNamePrefix,
		invoiceValue,
		bankAccount,
		invoiceType,
		pdfTemplateType,
		client,
		invoiceStatus,
		organization,
		jobDocument,
		job,
	}: CreateInvoiceSingleProps): Promise<CreateInvoiceReturnType> => {
		const rpcParams = {
			file_name_prefix: fileNamePrefix,
			invoice_value: invoiceValue,
			invoice_bank_account_id: bankAccount.id,
			invoice_type: invoiceType,
			pdf_template_type: pdfTemplateType,
			invoice_currency: DEFAULT_CURRENCY,
			invoice_client_id: client.id,
			invoice_guarantor_id: null as unknown as string, // TODO: can normal invoices go to guarantors?
			invoice_status: invoiceStatus,
			invoice_organization_id: organization.id,
			job_document_id: jobDocument.id,
			job_id: job.id as number,
		};
		const { data, error } = await supabase.rpc(
			SupabaseRpcEnum.CREATE_INVOICE_SINGLE,
			rpcParams
		);
		if (error) {
			return { success: false, data: null, error: error.message };
		}

		const invoiceNumber = data?.f1 as string;
		const filePath = data?.f2 as string;

		if (!invoiceNumber || !filePath) {
			return {
				success: false,
				data: null,
				error: "No invoice number or file path returned",
			};
		}

		return {
			success: true,
			data: { invoiceNumber, filePath },
			error: null,
		};
	};

	const createInvoiceMonthly = async ({
		invoiceValue,
		bankAccountId,
		clientId,
		guarantorId,
		organizationId,
		jobDocumentIds,
	}: CreateInvoiceMonthlyProps): Promise<CreateInvoiceReturnType> => {
		if (!clientId && !guarantorId) {
			return {
				success: false,
				data: null,
				error: "Either client or guarantor must be set.",
			};
		}

		const { data, error } = await supabase.rpc(
			SupabaseRpcEnum.CREATE_INVOICE_MONTHLY,
			{
				file_name_prefix: "Sammelrechnung-",
				invoice_value: invoiceValue,
				invoice_bank_account_id: bankAccountId,
				invoice_type: InvoiceType.MONATSRG,
				pdf_template_type: PdfTemplateType.SAMMELRECHNUNG,
				invoice_currency: DEFAULT_CURRENCY,
				invoice_client_id: clientId as string,
				invoice_guarantor_id: guarantorId as string,
				invoice_status: AccountingDocumentStatusEnum.CREATED,
				invoice_organization_id: organizationId,
				job_document_ids: jobDocumentIds,
			}
		);
		if (error) {
			Logger.error("Error creating monthly invoice via rpc", { error });
			return { success: false, data: null, error: error.message };
		}

		const invoiceNumber = data?.f1 as string;
		const filePath = data?.f2 as string;

		if (!invoiceNumber || !filePath) {
			return {
				success: false,
				data: null,
				error: "No invoice number or file path returned",
			};
		}

		return {
			success: true,
			data: { invoiceNumber, filePath },
			error: null,
		};
	};

	return {
		createInvoiceSingle,
		createInvoiceMonthly,
	};
};

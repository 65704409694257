import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "../../hooks/useForm/useForm";
import { FormProps } from "../form-types";
import { Database } from "../../../../lib/supabase";
import { FormSectionWithSaveButton } from "../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { FormSectionWithTitle } from "../../components/form-section-with-title/form-section-with-title.component";
import {
	ArticleEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useCustomDialogContext } from "../../components/dialog-components/custom-dialog/custom-dialog.component";
import { CustomSelect } from "../../components/custom-select.component";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { CustomTextField } from "../form-input-components/custom-input-components/custom-text-field.component";
import { useCentralStore } from "../../store/Central";
import { ClusterSelect } from "../form-input-components/selects/cluster-select/cluster.select";

export const ArticlesForm: React.FC<FormProps> = ({
	onSave = (article: ArticleEntityType) => {},
	submitRef = null,
}) => {
	const { organization, getArticles } = useCentralStore((state) => ({
		organization: state.organization,
		getArticles: state.getArticles,
	}));
	const { setIsPendingChanges } = useCustomDialogContext();

	const {
		handleInputChange,
		handleCheckboxChange,
		handleCreate,
		formData,
		formErrors,
		resetFormData,
		handleValueChange,
	} = useForm<Database["public"]["Tables"]["articles"]["Insert"]>(
		{
			organization_id: organization?.id,
		},
		SupabaseTableEnum.ARTICLES,
		undefined,
		undefined,
		"Artikel",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		// assert that fields are valid here
		const { success, data, error } = await handleCreate(event);
		if (data) {
			const article = data[0];
			onSave(article);
			await getArticles();
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<FormSectionWithTitle title="Artikeldaten">
				<TextField
					label="Artikelcode"
					value={formData.code || ""}
					name="code"
					onChange={handleInputChange}
					required
				/>
				<TextField
					label="Bezeichnung (DE)"
					value={formData.description_de || ""}
					name="description_de"
					onChange={handleInputChange}
				/>
				<TextField
					label="Bezeichnung (FR)"
					value={formData.description_fr || ""}
					name="description_fr"
					onChange={handleInputChange}
				/>
				<TextField
					label="Bezeichnung (IT)"
					value={formData.description_it || ""}
					name="description_it"
					onChange={handleInputChange}
				/>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Artikelwerte">
				<TextField
					label="Einheit"
					value={formData.unit || ""}
					name="unit"
					onChange={handleInputChange}
				/>
				<CustomTextField
					label="Preis"
					value={formData.price || ""}
					name="price"
					onChange={handleInputChange}
					validationError={formErrors.price}
					required
				/>
				<CustomTextField
					label="Einstandspreis"
					value={formData.purchase_price || ""}
					name="purchase_price"
					onChange={handleInputChange}
					validationError={formErrors.purchase_price}
				/>
				<CustomTextField
					label="Marge"
					value={formData.margin || ""}
					name="margin"
					onChange={handleInputChange}
					validationError={formErrors.margin}
				/>
				<CustomTextField
					label="Vorgabemenge"
					value={formData.standard_quantity || ""}
					name="standard_quantity"
					onChange={handleInputChange}
					validationError={formErrors.standard_quantity}
				/>
				<CustomTextField
					label="MWSt.-Satz"
					value={formData.tax_rate || ""}
					name="tax_rate"
					onChange={handleInputChange}
					validationError={formErrors.tax_rate}
				/>
			</FormSectionWithTitle>
			<CustomSelect
				inputLabel="Artikelart"
				value={formData.type?.toString() || ""}
				onChange={(value: string) =>
					handleValueChange("type", parseInt(value))
				}
				options={[
					{
						value: JobItemTypeEnum.ARTICLE_MATERIAL,
						label: "Material",
					},
					{ value: JobItemTypeEnum.ARTICLE_POSTAGE, label: "Porto" },
					{
						value: JobItemTypeEnum.ARTICLE_EXTERNAL_WORK,
						label: "Fremdarbeiten",
					},
					{
						value: JobItemTypeEnum.ARTICLE_DISCOUNTS,
						label: "Abzug/Barrabatt",
					},
					{
						value: JobItemTypeEnum.ARTICLE_FIXED_RATE,
						label: "Arbeitspauschale",
					},
				]}
				size="small"
				width="500px"
				required
			/>
			<ClusterSelect
				value={formData.cluster || ""}
				onChange={(value: string) =>
					handleValueChange("cluster", value)
				}
				width="500px"
				required
			/>
			<FormSectionWithTitle title="Weiteres">
				<TextField
					label="Zusatztext"
					value={formData.additional_text || ""}
					name="additional_text"
					onChange={handleInputChange}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.is_req_dr_tax ? true : false}
							onChange={handleCheckboxChange}
							name="is_req_dr_tax"
						/>
					}
					label="Ärztesteuerpflichtig"
				/>
				<TextField
					label="Hersteller"
					value={formData.manufacturer || ""}
					name="manufacturer"
					onChange={handleInputChange}
				/>
				<TextField
					label="CE-Nummer"
					value={formData.ce_number || ""}
					name="ce_number"
					onChange={handleInputChange}
				/>
				<TextField
					label="Zusammensetzung"
					value={formData.composition || ""}
					name="composition"
					onChange={handleInputChange}
				/>
				<TextField
					label="Lager"
					value={formData.stock_quantity || ""}
					name="stock_quantity"
					onChange={handleInputChange}
				/>
				<TextField
					label="Einkauf"
					value={formData.purchase_quantity || ""}
					name="purchase_quantity"
					onChange={handleInputChange}
				/>
				<TextField
					label="LOT-Nummer"
					value={formData.lot || ""}
					name="lot"
					onChange={handleInputChange}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};

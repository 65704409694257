import { Button, DialogActions, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { SearchableClickableList } from "./searchable-clickable-list.component";
import { PractitionerEntityType } from "@/lib/supabase/supabaseTypes";

interface SearchDialogProps {
	open: boolean;
	onClose: () => void;
	onSelect: (entry: PractitionerEntityType) => void;
	supabaseTable: string;
	onAction?: () => void;
	actionText?: string;
	dialogTitle?: string;
	// if customFetch is provided, the list results will be fetched accordingly, otherwise SearchableClickableList defaults to fetching by organizationId
	customFetch?: { column: string; value: string } | null;
}

export function SearchDialog({
	open,
	onClose,
	onSelect,
	supabaseTable,
	onAction,
	actionText,
	dialogTitle,
	customFetch = null,
}: SearchDialogProps) {
	return (
		<div>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>
					<SearchableClickableList
						supabaseTable={supabaseTable}
						onClick={(entry) =>
							onSelect(entry as unknown as PractitionerEntityType)
						}
						customFetch={customFetch}
					/>
				</DialogContent>
				{onAction && actionText && (
					<DialogActions>
						<Button onClick={onAction}>{actionText}</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
}

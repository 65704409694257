import { useState } from "react";
import { ClientSettingsDialog } from "./client-settings.dialog";
import { MenuItemButton } from "../../../../components/src/menu-item-button/menu-item-button.component";
import EditIcon from "@mui/icons-material/Edit";
import { useCentralStore } from "../../store/Central";

export const ClientPageTitleWithSettingsPopup = () => {
	const client = useCentralStore((state) => state.client);

	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				borderBottom: "var(--border-sm)",
				height: "var(--height-header-md)",
				justifyContent: "center",
			}}
			data-testid="client-page-title-with-settings-popup"
		>
			<div
				style={{
					display: "inline-flex",
				}}
			>
				<MenuItemButton
					title={`${client?.first_name} ${client?.last_name}`}
					iconRight={<EditIcon fontSize="small" />}
					onClick={handleOpen}
					textStyles="title-sm"
					dataTestId="client-page-title-with-settings-popup"
				/>
			</div>
			<ClientSettingsDialog open={open} onClose={handleClose} />
		</div>
	);
};

import { Alert, FormControl } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database } from "../../../../../../lib/supabase";
import { CustomSelect } from "../../../../components/custom-select.component";
import { patientFormTexts } from "../patient-form-texts";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import {
	PatientEntityType,
	SupabaseTableEnum,
} from "../../../../../../lib/supabase/supabaseTypes";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { CustomDateInput } from "../../../form-input-components/custom-input-components/custom-date-input.component";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { usePrivacyContext } from "../../../../context/PrivacyContext";
import { Logger } from "../../../../../../lib/logger/Logger";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";

interface PatientFormProps extends FormProps {
	patientId?: string;
}

export const PatientFormInDialog: React.FC<PatientFormProps> = ({
	onSave = (patient: PatientEntityType) => {},
	submitRef = null,
	patientId = undefined,
}) => {
	const { setIsPendingChanges } = useCustomDialogContext();
	return (
		<PatientForm
			onSave={onSave}
			submitRef={submitRef}
			setIsPendingChanges={setIsPendingChanges}
			patientId={patientId}
			isRenderedInDialog
		/>
	);
};

export const PatientForm: React.FC<
	PatientFormProps & {
		isRenderedInDialog?: boolean;
		setIsPendingChanges?: (isPendingChanges: boolean) => void;
	}
> = ({
	onSave = (patient: PatientEntityType) => {},
	submitRef = null,
	setIsPendingChanges = () => {},
	patientId = undefined,
	isRenderedInDialog = false,
}) => {
	const supabaseTable = SupabaseTableEnum.PATIENTS;

	const organizationId = useCentralStore((state) => state.organization?.id);

	const isUpdate = patientId ? true : false;
	const useFormCustomFields = {
		organization_id: organizationId,
	};

	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? patientId : undefined;

	const {
		handleInputChange,
		handleValueChange,
		handleCreate,
		handleUpdate,
		formData,
		formErrors,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["patients"]["Row"]>(
		useFormCustomFields,
		supabaseTable,
		useFormFetchData,
		useFormFetchId,
		"Patient",
		setIsPendingChanges
	);

	const handleUpdateSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleUpdate(event, patientId);
	};

	const handleInsertSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		Logger.log("PatientForm: onSubmit: event", event);
		const { success, data, error }: any = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		Logger.log("Data after insert:", data);
		if (data) {
			const patient = data[0];
			onSave(patient);
		}
	};

	const { isPrivacyOn } = usePrivacyContext();

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
			isRenderedInDialog={isRenderedInDialog}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<FormSectionWithTitle title="" margin="none">
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					{isPrivacyOn ? (
						<Alert
							severity="info"
							sx={{
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							Name-Feld im Datenschutz-Modus nicht verfügbar
						</Alert>
					) : (
						<TitleAndNameGroup
							formData={formData}
							formErrors={formErrors}
							onChange={handleInputChange}
							direction="column"
							onValueChange={(args: any) =>
								handleValueChange(args.name, args.value)
							}
						/>
					)}
					<CustomTextField
						label="Code"
						name="code"
						customHelperText="Patientennummer beim Zahnarzt."
						value={formData.code || ""}
						onChange={handleInputChange}
						validationError={formErrors.code}
						required
					/>
					{/* Geschlecht */}
					<FormControl>
						<CustomSelect
							options={patientFormTexts.options.gender}
							inputLabel="Geschlecht"
							// TODO
							onChange={(value) =>
								handleValueChange("gender", value)
							}
							value={formData.gender || ""}
						/>
					</FormControl>
					<FormControl>
						<CustomSelect
							options={patientFormTexts.options.inv_covered_by}
							inputLabel={patientFormTexts.labels.inv_covered_by}
							// TODO
							onChange={(value) =>
								handleValueChange("inv_covered_by", value)
							}
							value={formData.inv_covered_by || ""}
						/>
					</FormControl>
					{formData.inv_covered_by === "public_incurance" ||
						(formData.inv_covered_by === "private_insurance" && (
							<CustomTextField
								label="Versicherung"
								name="insurance"
								customHelperText="Name der Krankenkasse oder Versicherung."
								value={formData.insurance || ""}
								onChange={handleInputChange}
								validationError={formErrors.insurance}
							/>
						))}

					<CustomDateInput
						value={formData.birth_date || ""}
						onValueChange={(value: Date) =>
							handleValueChange("birth_date", value)
						}
						label="Geburtsdatum"
					/>

					{/* TODO: DW-334 CustomFormattedTextField und AHV-Nummer Input */}
					{isPrivacyOn ? (
						<Alert
							severity="info"
							sx={{
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							AHV-Nummer im Datenschutz-Modus nicht verfügbar
						</Alert>
					) : (
						<CustomTextField
							label="AHV Nummer"
							name="ahv_number"
							value={formData.ahv_number || ""}
							onChange={handleInputChange}
							validationError={formErrors.ahv_number}
						/>
					)}
				</Box>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};

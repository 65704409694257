import { StateCreator } from "zustand";
import { State } from "./types";
import { fetchClientAvatar, initialState } from "./utils";
import {
	ClientEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { supabase } from "../../../../lib/supabase";
import { Logger } from "../../../../lib/logger/Logger";

export interface ClientSlice extends State {
	setClientId: (clientId: string) => Promise<void>;
	getClients: () => Promise<void>;
	upsertClient: (client: ClientEntityType) => Promise<void>;
}

export const createClientStore: StateCreator<ClientSlice> = (set, get) => ({
	...initialState,
	setClientId: async (clientId: string) => {
		set({ clientLoading: true });
		const clients = get().clientsLookup;
		set({
			connectRelationshipId: null,
		});
		if (!clients || !clients[clientId]) {
			set({ client: null, clientLoading: false });
			Logger.warn("No clients found or clientId does not exist");
			return;
		}
		const client = clients[clientId];
		set({
			clientId,
			client,
			clientLoading: false,
			connectRelationshipId: client.connect_relationship_id,
		});
	},

	getClients: async () => {
		set({ clientsLoading: true });

		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.warn("Organization id is not set");
			set({ clientsLoading: false });
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.select()
			.eq("organization_id", organizationId as string)
			.order("created_at", { ascending: false });

		if (error) {
			set({ clientsLoading: false });
			Logger.warn(error);
			return;
		}

		if (!data) {
			set({ clientsLoading: false });
			return;
		}

		const newClientsLookup = data?.reduce(
			(
				acc: Record<string, ClientEntityType>,
				client: ClientEntityType
			) => {
				acc[client.id] = client;
				return acc;
			},
			{}
		);

		// Set the clientsLookup instead of waiting for the avatars to be fetched
		set({
			clientsLookup: newClientsLookup,
			clientsLoading: false,
		});

		const createClientsLookupWithAvatars = async () => {
			const newClientsLookup: Record<
				string,
				ClientEntityType & { image_src?: string }
			> = {};

			for (const client of data) {
				if (client.avatar_path) {
					const image_src = await fetchClientAvatar(
						client.avatar_path
					);
					// @ts-expect-error this is a new field to store the
					// image url
					if (image_src) client.image_src = image_src;
				}

				newClientsLookup[client.id] = client;
			}
		};

		// This will update the clients avatar path without having the user wait for the avatars to be fetched
		// Beauty of asynchrony
		createClientsLookupWithAvatars();
	},
	upsertClient: async (client: ClientEntityType & { image_src?: string }) => {
		if (client.avatar_path) {
			const image_src = await fetchClientAvatar(client.avatar_path);

			if (image_src) client.image_src = image_src;
		}
		set((state) => ({
			clientsLookup: {
				...state.clientsLookup,
				[client.id]: client,
			},
			client,
			clientId: client.id,
			connectRelationshipId: client.connect_relationship_id,
		}));
		const { image_src, ...rest } = client;

		await supabase.from(SupabaseTableEnum.CLIENTS).upsert(rest, {
			onConflict: "id",
		});
	},
});

import React from "react";
import { useSessionContext } from "../../../lib/contexts/SessionContext";
import { useProfileContext } from "../../../lib/contexts/ProfileContext";

/**
 * EnvTag - Displays the current environment in the bottom right corner of the screen. Displays nothing if in production.
 * @param param0
 * @returns
 */
export const EnvTag: React.FC<{
	envMode: string;
	envTitle: string;
}> = ({ envMode, envTitle }) => {
	const {
		session: { userId },
	}: any = useSessionContext();
	const { organizationId } = useProfileContext();
	if (envMode === "production") {
		return null;
	}
	// TODO: Re-add after launch event
	return null;
	/* 	return (
			<div
				style={{
					position: "fixed",
					bottom: 0,
					right: 0,
					backgroundColor: "gray",
					color: "white",
					padding: "5px",
					fontSize: "10px",
				}}
			>
				#{envTitle}{" "}
				{(envMode === "development" || envMode === "development_testing") &&
					`(${userId}) (${organizationId})`}
			</div>
		); */
};

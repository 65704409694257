import { Button, IconButton } from "@mui/material";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { useEffect, useState } from "react";
import { showNotification } from "../../store/Central/selectors";
import { compressImage } from "@/lib/utils/compress-image";
import { useStorage } from "../../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "@/lib/logger/Logger";
import SaveIcon from "@mui/icons-material/Save";
import { RestartAlt } from "@mui/icons-material";
import clsx from "clsx";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { useCentralStore } from "../../store/Central";

export const PdfTemplateLogo = ({
	pdfTemplateConfiguration,
	handleLogoUpdate,
}: {
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	handleLogoUpdate({ logoPath }: { logoPath?: string }): Promise<void>;
}) => {
	const imagePath = pdfTemplateConfiguration?.general.logoPath;
	const [logoPic, setLogoPic] = useState<string | null>(null);
	const organizationId = useCentralStore((state) => state.organization?.id);

	const [logoPicPath, setLogoPicPath] = useState<string | null>(null);
	const { fileUrl, uploadFileWithProvidedPath } = useStorage();
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		const fetchImageUrl = async () => {
			setLogoPicPath(imagePath ?? "");
			if (!imagePath) return;
			const imageSrc = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.createSignedUrl(`${organizationId}/${imagePath}`, 3600, {
					transform: {
						quality: 35,
					},
				});

			if (!imageSrc.data) return;

			setLogoPic(imageSrc.data.signedUrl);
		};

		fetchImageUrl();
	}, [imagePath]);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setUploading(true);
		if (!e.target.files || e.target.files.length <= 0) return;

		const file = e.target.files[0];

		if (file.size > 5 * 1024 * 1024) {
			showNotification({
				message: "Bitte wählen Sie eine Datei kleiner als 5MB",
				type: "error",
			});

			return;
		}

		const compressedFile = await compressImage(file, 0.6);

		const reader = new FileReader();

		reader.onloadend = () => {
			setLogoPic(reader.result as string);
		};

		reader.readAsDataURL(file);

		const imageName = `logos/${uuidv4()}/${file.name}`;

		const { error } = await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.upload(`${organizationId}/${imageName}`, compressedFile);

		if (error) {
			Logger.error("Error uploading");
			showNotification({
				message: "Fehler beim Hochladen des Logos",
				type: "error",
			});

			return;
		}

		setLogoPicPath(imageName);
		setUploading(false);
	};
	return (
		<div className="w-full flex flex-col gap-4">
			<div className="flex flex-col items-center gap-4">
				<div
					className={clsx(
						"h-[20mm] relative  border  overflow-hidden max-w-60",
						{
							"w-full": !logoPic,
						}
					)}
				>
					{uploading && (
						<div className="absolute flex items-center justify-center top-0 left-0 w-full h-full">
							<p>Hochladen...</p>
						</div>
					)}
					{logoPic && (
						<img
							style={{
								opacity: uploading ? 0.5 : 1,
							}}
							alt="logo"
							src={logoPic}
							className=" h-full object-cover aspect-auto"
						/>
					)}
				</div>
				<input
					id="logo"
					name="logo"
					onChange={handleFileChange}
					type="file"
					className="hidden"
				/>
				<Button onClick={() => {}} disabled={uploading}>
					<label htmlFor="logo">Hochladen</label>
				</Button>
			</div>
			<div className="w-full flex justify-end">
				<IconButton
					disabled={!logoPicPath}
					color="primary"
					onClick={async () => {
						await handleLogoUpdate({
							logoPath: "",
						});
						setLogoPicPath("");
						setLogoPic("");
					}}
				>
					<RestartAlt />
				</IconButton>
				<IconButton
					disabled={logoPicPath === imagePath}
					color="primary"
					onClick={() => {
						handleLogoUpdate({
							...(logoPicPath &&
								logoPicPath !== imagePath && {
									logoPath: logoPicPath,
								}),
						});
					}}
				>
					<SaveIcon />
				</IconButton>
			</div>
		</div>
	);
};

import { Autocomplete, TextField } from "@mui/material";
import { Logger } from "../../../../../lib/logger/Logger";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";
import { useToast } from "../../../context/ToastContext";
import { useCallback, useEffect, useState } from "react";
import { PractitionerEntityType } from "../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../../../../../dentlab/src/store/Central";

export type PractitionerOptionType = {
	label: string;
	name: string;
	value: number;
};

export const PractitionerEmailsAutocomplete: React.FC<{
	selectedPractitioners: PractitionerOptionType[];
	setSelectedPractitioners: (practitioners: PractitionerOptionType[]) => void;
}> = ({ selectedPractitioners, setSelectedPractitioners }) => {
	const { showToast } = useToast();
	const { client } = useCentralStore();
	const [practitionerOptions, setPractitionerOptions] = useState<
		PractitionerOptionType[]
	>([]);

	// get practitioners of this client
	// because as of Nov 2023, only one dentCONNECT user per client is allowed,
	// we don't need to have state of what practitioners were selected.
	const {
		practitioners: { practitionersByClientIdLookup },
	} = usePractitionersContext();

	const getPractitioners = useCallback(async () => {
		const practitioners = client?.id
			? practitionersByClientIdLookup[client?.id]
			: [];

		// if there's not practitioners, something went wrong
		if (!practitioners) {
			Logger.error("No practitioners found for client", client?.id);
			showToast("Keine Behandler gefunden", "error");
		}

		const practitionersWithAuthId = practitioners.filter(
			(row: PractitionerEntityType) => row.profile_id
		);

		// if there's no practitioners with auth_id, something went wrong
		if (!practitionersWithAuthId) {
			Logger.error(
				"No practitioners with auth_id found for client",
				client?.id
			);
			showToast("Keine Behandler mit auth_id gefunden", "error");
		}

		// bring practitioners into the right format
		const _practitionerOptions = practitioners.map(
			(row: PractitionerEntityType) => ({
				label: row.email ?? "",
				name: `${row?.title || ""} ${row.first_name} ${row.last_name}`,
				value: row.id,
			})
		);
		setPractitionerOptions(_practitionerOptions);

		Logger.log(
			"PractitionerEmailsAutocomplete: practitioners",
			_practitionerOptions,
			selectedPractitioners
		);
		// TODO: this might be removed if there should no longer be a default email selected
		if (
			_practitionerOptions.length > 0 &&
			selectedPractitioners.length === 0
		) {
			Logger.log(
				"PractitionerEmailsAutocomplete: setting default practitioner"
			);
			setSelectedPractitioners([_practitionerOptions[0]]);
		}
	}, [practitionersByClientIdLookup, client?.id]);

	useEffect(() => {
		getPractitioners();
	}, [getPractitioners]);

	if (practitionerOptions.length === 0) {
		return null;
	}
	return (
		<Autocomplete
			disablePortal
			multiple
			options={practitionerOptions}
			sx={{
				marginTop: "1rem",
			}}
			renderInput={(params) => (
				<TextField {...params} label="Personen benachrichtigen" />
			)}
			value={selectedPractitioners}
			onChange={(event, newValue) => {
				setSelectedPractitioners(newValue);
			}}
			disabled
		/>
	);
};

import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";

const getClientSearchPattern = (query: string) =>
	`code.ilike.%${query}%, first_name.ilike.%${query}%, last_name.ilike.%${query}%, email.ilike.%${query}%`;

const getPatientSearchPattern = (query: string) =>
	`code.ilike.%${query}%, first_name.ilike.%${query}%, last_name.ilike.%${query}%`;

const getJobSearchPattern = (query: string) =>
	`code.ilike.%${query}%, title.ilike.%${query}%`;

interface Actions {
	fetchSearchResults: (query: string) => Promise<void>;
	fetchSearchResultsClients: (query: string) => Promise<void>;
	fetchSearchResultsPatients: (query: string) => Promise<void>;
	fetchSearchResultsJobs: (query: string) => Promise<void>;
}

export type SearchSlice = State & Actions;

export const createSearchStore: StateCreator<SearchSlice> = (set, get) => ({
	...initialState,

	fetchSearchResults: async (query) => {
		await Promise.all([
			get().fetchSearchResultsClients(query),
			get().fetchSearchResultsPatients(query),
			get().fetchSearchResultsJobs(query),
		]);
	},

	fetchSearchResultsClients: async (query: string) => {
		set({ loadingClients: true });
		const {
			success,
			data: clientResults,
			error,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.CLIENTS)
				.select()
				.or(getClientSearchPattern(query))
		);
		if (success) {
			set({
				searchResultsClients: clientResults || [], // if there are no results, we reset to an empty array
			});
		} else {
			Logger.log(error);
		}
		set({ loadingClients: false });
	},

	fetchSearchResultsPatients: async (query: string) => {
		set({ loadingPatients: true });
		const {
			success,
			data: patientResults,
			error,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.PATIENTS)
				.select()
				.or(getPatientSearchPattern(query))
		);
		if (success) {
			set({
				searchResultsPatients: patientResults || [], // if there are no results, we reset to an empty array
			});
		} else {
			Logger.log(error);
		}
		set({ loadingPatients: false });
	},

	fetchSearchResultsJobs: async (query: string) => {
		set({ loadingJobs: true });
		const {
			success,
			data: jobResults,
			error,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOBS)
				.select()
				.or(getJobSearchPattern(query))
				.limit(50)
		);
		if (success) {
			set({
				searchResultsJobs: jobResults || [], // if there are no results, we reset to an empty array
			});
		} else {
			Logger.log(error);
		}
		set({ loadingJobs: false });
	},
});

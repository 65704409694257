import { Switch } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ShieldIcon from "@mui/icons-material/Shield";
import { usePrivacyContext } from "../../../../../../../context/PrivacyContext";

export function PrivacySwitch() {
  const { isPrivacyOn, setIsPrivacyOn } = usePrivacyContext();
  return (
    <Switch
      checked={isPrivacyOn}
      onChange={(e) => setIsPrivacyOn(e.target.checked)}
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "green",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "green",
        },
      }}
      checkedIcon={
        <VerifiedUserIcon
          sx={{
            fontSize: "1.3rem",
            color: "green",
          }}
        />
      }
      icon={
        <ShieldIcon
          sx={{
            fontSize: "1.3rem",
            color: "gray",
          }}
        />
      }
    />
  );
}

import { Elements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { getDaysLeftInTrial } from "../../layout/layout-components/header-bar/header-bar.component";
import { useCentralStore } from "../../store/Central";
import { useUserManagementStore } from "../../store/UserManagement";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { EditIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

/* export const SubscriptionPage: React.FC = () => {

    

    const [clientSecret, setClientSecret] = useState<string | null>(null);

    useEffect(() => {
        const fetchClientSecret = async () => {
        const response = await fetch("/api/stripe/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data.clientSecret;
    };
        fetchClientSecret().then(setClientSecret);
    }, []);

    if (!clientSecret) {
        return <div>Lädt...</div>;
    }

    return <Elements
        stripe={
            stripePromise
        }
        options={{
            clientSecret: clientSecret,
        }}
    >
        <PaymentElement />
    </Elements>
}; */

const getStartDateTrial = (createdAt: string | null | undefined) => {
	if (!createdAt) {
		return "01.01.2024";
	}
	const daysLeft = getDaysLeftInTrial(createdAt);
	const startDate = new Date(createdAt);
	startDate.setDate(startDate.getDate() + daysLeft);
	return startDate.toLocaleDateString("de-CH");
};

export const SubscriptionPage: React.FC = () => {
	const navigate = useNavigate();
	const organizationCreatedAt = useCentralStore(
		(state) => state.organization?.created_at
	);
	const [isDentalLabXpUser, setIsDentalLabXpUser] = useState(true);

	const { members, getMembers } = useUserManagementStore((state) => ({
		members: state.members,
		getMembers: state.getMembers,
	}));

	useEffect(() => {
		getMembers();
	}, []);

	const yearlyPayment = 960 + (members.length - 1) * 120;
	const yearlyPaymentWithDiscount =
		(isDentalLabXpUser ? 0.75 : 1) * yearlyPayment;

	return (
		<div className="p-16 flex flex-col gap-4">
			<h1 className="text-3xl font-bold">Ihre Zahlung</h1>
			<h2 className="text-gray-900 text-2xl">
				Sie befinden sich im kostenlosen Testzeitraum. Ihr Test läuft in{" "}
				<b>{getDaysLeftInTrial(organizationCreatedAt)}</b> Tagen aus.
			</h2>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<h3 className="text-xl">
						Anzahl Nutzer: {members.length}
						<IconButton
							aria-label="edit"
							size="small"
							className="ml-2"
						>
							<EditIcon
								size={16}
								className="text-gray-900"
								onClick={() => navigate("/user-management")}
							/>
						</IconButton>
					</h3>
				</div>
				<ul className="list-disc pl-5">
					{members.map((member) => (
						<li key={member.id}>
							{member.first_name} {member.last_name}
						</li>
					))}
				</ul>
			</div>

			<h3 className="text-xl">
				Dental lab XP Nutzer?{" "}
				<Checkbox
					checked={isDentalLabXpUser}
					onChange={(e) => setIsDentalLabXpUser(e.target.checked)}
				/>
			</h3>
			<h3 className="text-xl">
				Jährliche Zahlung: CHF {yearlyPayment} (CHF {yearlyPayment / 12}{" "}
				pro Monat)
			</h3>
			<h3 className="text-xl">
				Nächste Zahlung am {getStartDateTrial(organizationCreatedAt)}:
				CHF {yearlyPaymentWithDiscount} (Gutschein: CHF{" "}
				{yearlyPayment - yearlyPaymentWithDiscount})
			</h3>
			<div className="mt-4"></div>
		</div>
	);
};

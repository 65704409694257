export const tariffsColumns: any[] = [
  {
    field: "custom",
    type: "boolean",
    headerName: "Eigener/Bearbeitet",
    width: 140,
    editable: false,
  },
  { field: "code_e", headerName: "Tarifcode", width: 100, editable: true },
  {
    field: "description_de",
    headerName: "Bezeichnung (D)",
    width: 300,
    editable: true,
  },
  {
    field: "description_fr",
    headerName: "Bezeichnung (F)",
    width: 300,
    editable: true,
  },
  {
    field: "description_it",
    headerName: "Bezeichnung (I)",
    width: 300,
    editable: true,
  },
  { field: "tp_sv", headerName: "SV", width: 100, editable: true },
  { field: "tp_pp1", headerName: "PP1", width: 100, editable: true },
  { field: "tp_pp2", headerName: "PP2", width: 100, editable: true },
  { field: "tp_pp3", headerName: "PP3", width: 100, editable: true },
  {
    field: "is_own_tp",
    type: "boolean",
    headerName: "Eigener Taxpunktwert",
    width: 100,
    editable: true,
  },
  { field: "tp_value", headerName: "Taxpunktwert", width: 150, editable: true },
  {
    field: "standard_quantity",
    headerName: "Vorgabemenge",
    width: 100,
    editable: true,
  },
  { field: "tax_rate", headerName: "MWSt.-Satz", width: 100, editable: true },
  {
    field: "additional_text",
    headerName: "Zusatztext",
    width: 300,
    editable: true,
  },
  {
    field: "is_req_dr_tax",
    type: "boolean",
    headerName: "Ärztesteuerpflichtig",
    width: 100,
    editable: true,
  },
  {
    field: "composition",
    headerName: "Zusammensetzung",
    width: 300,
    editable: true,
  },
  {
    field: "modified_at",
    headerName: "Letzte Änderung",
    width: 200,
    editable: false,
  },
];

import { Logger } from "../../../../../lib/logger/Logger";
import { AdrTp } from "./qr-payment-types";

// Online Validator https://www.swiss-qr-invoice.org/validator/?lang=de

/**
 * isIBANValid - For elements of type IBAN (KONTO)
 * Feste Länge: 21 alphanumerische Zeichen, nur IBANs mit
 * CH- oder LI-Landescode zulässig.
 */
export const isIBANValid = (KONTO: string): boolean => {
	return (
		KONTO.length === 21 &&
		(KONTO.startsWith("CH") || KONTO.startsWith("LI"))
	);
};

/**
 * istNameValid - For elements of type Name (ZE_NAME, EZE_NAME, EZP_NAME)
 * Maximal 70 Zeichen zulässig
 * Vorname (optional, Lieferung empfohlen, falls verfügbar)
 * + Name oder Firmenbezeichnung
 */
export const isNameValid = (name: string): boolean => {
	return name.length <= 70;
};

/**
 * isStrtNmOrAdrLine1Valid - For elements of type StrtNmOrAdrLine1 (ZE_STRASSE_ODER_ADRESSZEILE_1, EZE_STRASSE_ODER_ADRESSZEILE_1, EZP_STRASSE_ODER_ADRESSZEILE_1)
 * Maximal 70 Zeichen zulässig
 */
export const isStrtNmOrAdrLine1Valid = (street: string): boolean => {
	return street.length <= 70;
};

/**
 *
 * Strukturierte Adresse: maximal 16 Zeichen zulässig
 * Kombinierte Adressfelder: maximal 70 Zeichen zulässig
 * Muss geliefert werden bei Adress-Typ «K».
 */
export const isBldgNbOrAdrLine2Valid = (
	houseNumber: string,
	addressType: AdrTp
): boolean => {
	if (addressType === "S") {
		return houseNumber.length <= 16;
	} else if (addressType === "K") {
		return houseNumber.length <= 70 && houseNumber.length > 0;
	}
	return false;
};

/**
 * isPstCdValid - For elements of type PstCd (ZE_POSTLEITZAHL, EZE_POSTLEITZAHL, EZP_POSTLEITZAHL)
 * Maximal 16 Zeichen zulässig
 * Die Postleitzahl ist immer ohne vorangestellten Landescode anzugeben.
 * Kombinierte Adressfelder: darf nicht verwendet werden
 */
export const isPstCdValid = (postCode: string, addressType: AdrTp): boolean => {
	if (addressType === "S") {
		return postCode.length <= 16;
	} else if (addressType === "K") {
		return postCode.length === 0;
	}
	return false;
};

/**
 * isTwnNmValid - For elements of type TwnNm (ZE_ORT, EZE_ORT, EZP_ORT)
 * Maximal 35 Zeichen zulässig
 * Kombinierte Adressfelder: darf nicht verwendet werden
 * @param town
 * @returns
 */
export const isTwnNmValid = (town: string, addressType: AdrTp): boolean => {
	if (addressType === "S") {
		return town.length > 0 && town.length <= 35;
	}
	if (addressType === "K") {
		return town.length === 0;
	}
	return false;
};

/**
 * isCtryValid - For elements of type Ctry (ZE_LAND, EZE_LAND, EZP_LAND)
 * 2-stelliger Landescode gemäss ISO 3166-1
 */
export const isCtryValid = (country: string): boolean => {
	return country.length === 2;
};

/**
 * isAmtValid - For elements of type Amt (BETRAG)
 * Das Element Betrag ist ohne führende Nullen inklusive Dezimaltrennzeichen und 2 Nachkomastellen anzugeben.
 * Dezimal, maximal 12 Stellen zulässig, inklusive Dezimaltrennzeichen.
 * Als Dezimaltrennzeichen ist nur das Punktzeichen (.) zulässig.
 * Der Betrag muss zwischen CHF / EUR 0.01 und CHF / EUR 999999999.99 liegen.
 */
export const isAmtValid = (amount: string): boolean => {
	// check if string starts with any zeros
	if (amount.startsWith("0")) {
		return false;
	}

	// check if string includes a decimal point
	if (!amount.includes(".")) {
		return false;
	}

	// check if string contains below 12 characters after the decimal point and at least 2 characters after the decimal point
	const decimalPointIndex = amount.indexOf(".");
	const amountAfterDecimalPoint = amount.substring(decimalPointIndex + 1);
	if (
		amountAfterDecimalPoint.length > 12 ||
		amountAfterDecimalPoint.length < 2
	) {
		return false;
	}

	// check if string contains only numbers
	const amountWithoutDecimalPoint = amount.replace(".", "");
	if (!/^\d+$/.test(amountWithoutDecimalPoint)) {
		return false;
	}

	// check if number is between 0.01 and 999999999.99
	const amountNumber = Number(amount);
	if (amountNumber < 0.01 || amountNumber > 999999999.99) {
		return false;
	}
	return true;
};

/**
 * isRefValid - For elements of type Ref (REFERENZ)
 * Bei QR-Referenz:
 * - Muss zusammen mit einer QR-IBAN verwendet werden
 * - Immer 27 Zeichen
 * - Numerisch
 * - Prüfzifferberechnung nach Modulo 10 rekursiv (27. Stelle der Referenz)
 */
export const isRefValid = (reference: string): boolean => {
	// remove spaces
	const newReference = reference.replace(/\s/g, "");
	Logger.log("newReference: " + newReference);
	if (newReference.length !== 27) {
		return false;
	}
	if (!/^\d+$/.test(newReference)) {
		return false;
	}
	return true;
};

/**
 * isUstrdValid - For elements of type Ustrd (UNSTRUKTURIERTE_MITTEILUNG)
 * Maximal 140 Zeichen zulässig
 */
export const isUstrdValid = (unstructuredMessage: string): boolean => {
	return unstructuredMessage.length <= 140;
};

/**
 * isStrdBkgInfValid - For elements of type StrdBkgInf (RECHNUNGSINFORMATIONEN)
 * Maximal 140 Zeichen zulässig
 */
export const isStrdBkgInfValid = (invoiceInformation: string): boolean => {
	return invoiceInformation.length <= 140;
};

/**
 * isAddInfValid - For elements of type AddInf (Ustrd, StrdBkgInf)
 * Unstrukturierte Mitteilung und Rechnungsinformationen dürfen zusammen maximal 140 Zeichen enthalten
 */
export const isAddInfValid = (
	unstructuredMessage: string,
	invoiceInformation: string
): boolean => {
	return unstructuredMessage.length + invoiceInformation.length <= 140;
};

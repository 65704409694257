import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useState } from "react";
import { Logger } from "../../../../lib/logger/Logger";
import { ItemPatientDetail } from "../../pages/articles/item-patient-detail.component";
import { Dialog } from "@mui/material";

// TODO: explanations for columns (especially "custom")
// TODO: "reset to default" column
// TODO: last modified column, automatically sort by last modified

/**
 * DefaultPositionsList
 */
export const DefaultPositionsList: React.FC<{
	rows: GridRowsProp;
	columns: GridColDef[];
	gridConfigName: "tariffs" | "articles";
	onRowUpdate: (row: any) => void;
}> = ({ rows, columns, onRowUpdate }) => {
	const [itemId, setItemId] = useState<string | null>(null);

	columns.push({
		field: "custom",
		headerName: " ",
		width: 200,
		renderCell: (params) => {
			return (
				<button
					onClick={() => {
						setItemId(params.row.id as string);
						Logger.log("params.row", params.row);
					}}
				>
					Rückverfolgung
				</button>
			);
		},
	});

	return (
		<>
			<DataGrid
				rows={rows}
				columns={columns}
				autoHeight
				density="compact"
				processRowUpdate={(newRow, oldRow) => {
					Logger.log("processRowUpdate", newRow, oldRow);
					// to compare objects two different objects, we need to stringify them
					if (JSON.stringify(oldRow) === JSON.stringify(newRow)) {
						return newRow;
					} else {
						onRowUpdate(newRow);
						return { ...newRow, custom: true };
					}
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "modified_at", sort: "desc" }],
					},
				}}
			/>
			{itemId && (
				<Dialog onClose={() => setItemId(null)} open={true}>
					<ItemPatientDetail itemId={itemId} />
				</Dialog>
			)}
		</>
	);
};

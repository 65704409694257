import { useEffect, useState } from "react";
import { FormProps } from "../../form-types";
import { Logger } from "../../../../../lib/logger/Logger";
import { ConfigurationColumns } from "../../../types/enums";
import { SupabaseTableEnum } from "../../../../../lib/supabase/supabaseTypes";
import { TpTierSelect } from "../../form-input-components/selects/tp-tier.select";
import { TariffsConfigurationType } from "../../../types/configuration.types";
import { FormSectionWithSaveButton } from "../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { useToast } from "../../../context/ToastContext";
import { TariffValuesGroup } from "../../form-input-components/groups/tariff-values.group";
import { FormSectionWithTitle } from "../../../components/form-section-with-title/form-section-with-title.component";
import { useDatabaseFunctionsWithPromise } from "../../../hooks/useDatabase";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const TariffsConfigurationForm: React.FC<FormProps> = () => {
	const SUPABASE_TABLE = SupabaseTableEnum.ORGANIZATIONS;
	const SUPABASE_COLUMN = ConfigurationColumns.TARIFFS; // TODO: import from supabaseTypes.ts

	const { showToast } = useToast();
	const { setIsPendingChanges } = useCustomDialogContext();

	const organizationId = useCentralStore((state) => state.organization?.id);
	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();
	const [formData, setFormData] = useState<TariffsConfigurationType | null>(
		null
	);
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	useEffect(() => {
		if (organizationId) {
			fetch(organizationId);
		}
	}, [organizationId]);

	const fetch = async (organizationId_: string) => {
		const { success, data, error }: any = await fetchDataWithPromise(
			SUPABASE_TABLE,
			{
				column: "id",
				value: organizationId_,
			},
			SUPABASE_COLUMN
		);
		Logger.log("TariffsConfiguration", success, data, error);
		if (success && data && data[SUPABASE_COLUMN]) {
			setFormData(JSON.parse(data[SUPABASE_COLUMN]));
		}
	};

	const submit = async (event: any) => {
		event.preventDefault();
		Logger.log(formData);
		const { success, data, error } = await updateDataWithPromise(
			SUPABASE_TABLE,
			[
				{
					id: organizationId,
					[SUPABASE_COLUMN]: JSON.stringify(formData),
				},
			]
		);
		Logger.log("TariffsConfiguration", success, data, error);
		if (success) {
			showToast("Daten aktualisiert!", "success");
		} else {
			showToast("Error", "error");
		}
	};

	const handleResetFormData = () => {
		setFormData(null);
		setIsPendingChanges(false);
	};

	const handleTpTierSelectChange = (value: any) => {
		setFormData((prev: any) => ({
			...prev,
			tp_tier: value,
		}));
		setIsPendingChanges(true);
	};

	const handleTariffValuesGroupValueChange = (args: any) => {
		setFormData((prev: any) => ({
			...prev,
			[args.name]: args.value,
		}));
		setIsPendingChanges(true);
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={submit}
			disabled={false}
			margin="none"
			onResetForm={handleResetFormData}
		>
			<FormSectionWithTitle title="">
				<TpTierSelect
					value={formData?.tp_tier}
					onChange={handleTpTierSelectChange}
				/>

				<TariffValuesGroup
					onValueChange={handleTariffValuesGroupValueChange}
					formData={formData}
					formErrors={{}}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};

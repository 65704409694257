import {
	ClientEntityType,
	GuarantorEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

export const PdfRecipientAddress: React.FC<{
	recipient: ClientEntityType | GuarantorEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
}> = ({ recipient, pdfTemplateConfiguration }) => {
	return (
		<div
			aria-label="Empfängeradresse"
			style={{
				height: "25mm",
				paddingTop: "7mm", // "Die erste Zeile der Adresse des Adressaten beginnt 52mm von oben."
				paddingBottom: "10mm",
				width: "80mm",
				paddingLeft: pdfTemplateConfiguration?.general.clientAddressLeft
					? "6mm"
					: "97mm", // Linksadressierungen beginnen 26mm von links, Rechtsadressierungen 117mm von links.
				fontSize: "10pt",
			}}
		>
			<p>{recipient.title}</p>
			<p>
				{recipient.last_name} {recipient.first_name}
			</p>
			<p>{recipient.street}</p>
			<p>
				{recipient.postal_code} {recipient.city}
			</p>
		</div>
	);
};

import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import { stringAvatar } from "../lib/utils/avatar-functions";

interface ProfileButtonProps {
	avatar: string;
	firstName: string;
	lastName: string;
	onClick: () => void;
	disableClick?: boolean;
}

export const ProfileButton: React.FC<
	ProfileButtonProps & { variant?: "box" | "chip" }
> = (props) => {
	const { variant } = props;
	if (variant && variant === "box") {
		return <ProfileButtonBox {...props} />;
	}
	if (variant && variant === "chip") {
		return <ProfileButtonChip {...props} />;
	}
	return <ProfileButtonBox {...props} />;
};

const ProfileButtonChip: React.FC<ProfileButtonProps> = ({
	firstName,
	lastName,
	onClick,
}) => {
	return (
		<>
			{/* TODO: Add avatar */}
			<Chip
				label={`${firstName} ${lastName}`}
				onClick={onClick}
				variant="filled"
			/>
		</>
	);
};

const ProfileButtonBox: React.FC<ProfileButtonProps> = ({
	avatar,
	firstName,
	lastName,
	onClick,
	disableClick = false,
}) => {
	return (
		<>
			<Button
				aria-owns="mouse-over-popover"
				aria-haspopup="true"
				onClick={onClick}
				disabled={disableClick} // TODO: override style if disabled
			>
				<>
					{avatar ? (
						avatar
					) : (
						<Box sx={{ mr: "10px" }}>
							<Avatar
								{...stringAvatar(`${firstName} ${lastName}`)}
							/>
						</Box>
					)}
					<Typography variant="body1">
						{firstName} {lastName}
					</Typography>
				</>
			</Button>
		</>
	);
};

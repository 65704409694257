import { createContext, useContext, useState } from "react";

/**
 * PrivacyContext - boolean value of whether privacy switch is turned on or off, returns isPrivacyOn and setIsPrivacyOn
 */
const PrivacyContext = createContext<{
  isPrivacyOn: boolean;
  setIsPrivacyOn: (isPrivacyOn: boolean) => void;
}>({
  isPrivacyOn: false,
  setIsPrivacyOn: () => {},
});

export const PrivacyContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }: any) => {
  const [isPrivacyOn, setIsPrivacyOn] = useState<boolean>(true);

  return (
    <PrivacyContext.Provider
      value={{
        isPrivacyOn,
        setIsPrivacyOn,
      }}
    >
      {children}
    </PrivacyContext.Provider>
  );
};

export const usePrivacyContext = () => {
  const context = useContext(PrivacyContext);
  if (!context) {
    throw new Error(
      "usePrivacyContext must be used within a PrivacyContextProvider",
    );
  }
  return context;
};

import { handleDatabaseOperation } from "@/dentlab/src/lib/utils/utils-functions";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import {
	OrganizationEntityType,
	ProfileEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";

export interface CentralSlice {
	userId: string | null;
	userEmail: string | null;
	clientId: string | null;
	profile: ProfileEntityType | null;
	organization: OrganizationEntityType | null;
	initialized: boolean;
	setClientId: (clientId: string) => void;
	initialize: () => Promise<void>;
	getSession: () => Promise<string | null>;
	getProfile: () => Promise<void>;
	getOrganization: (organizationId: string) => Promise<void>;
	handleSignOut: () => Promise<void>;
}

export const createCentralStore: StateCreator<CentralSlice> = (set, get) => ({
	userId: null,
	userEmail: null,
	profile: null,
	organization: null,
	initialized: false,
	clientId: null,
	setClientId: (clientId: string) =>
		set({
			clientId,
		}),
	initialize: async () => {
		const { getSession, getOrganization } = get();

		if (get().initialized) {
			Logger.warn("Central store already initialized");
			return;
		}

		const organizationId = await getSession();
		if (!organizationId) return;

		await getOrganization(organizationId);

		set({
			initialized: true,
		});
	},
	getSession: async () => {
		const { data } = await supabase.auth.getSession();

		set({
			userId: data.session?.user.id,
			userEmail: data.session?.user.email ?? "",
		});

		return data.session?.user.app_metadata.organization_id;
	},
	getProfile: async () => {
		const { userId } = get();
		if (!userId) {
			Logger.error("User ID is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select()
			.eq("id", userId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}

		const profile = data;
		set({ profile });
	},
	getOrganization: async (organizationId: string) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.ORGANIZATIONS)
			.select()
			.eq("id", organizationId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}
		if (data) {
			set({
				organization: data,
			});
		}
	},
	handleSignOut: async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) {
				showNotification({
					message:
						"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut",
					type: "error",
				});
				Logger.error(error);
				return;
			}
			set({
				userId: null,
				userEmail: null,
				organization: null,
				profile: null,
			});

			window.location.href = "/";
		} catch (error) {
			Logger.error("Error signing out", error);
			showNotification({
				message:
					"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut.",
				type: "error",
			});
		}
	},
});

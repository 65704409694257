import { Button } from "@mui/material";
import { parseCAMTTransactions } from "../../lib/xmlparser/xmlparser";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { useCentralStore } from "../../store/Central";
import { SupabaseRpcEnum } from "@/lib/supabase/supabaseTypes";
import { AccountingTable } from "./components/accouting-table.component";
import { useAccountingStore } from "../../store/Accounting";
import { useEffect, useState } from "react";
import { AccountingStatusSelector } from "./components/accounting-status-selector.component";
import { AccountingStatus } from "./types";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { AccountingRecipientSelector } from "./components/accounting-recipient-selector.component";
import { useSearchParams } from "react-router-dom";
import { showNotification } from "../../store/Central/selectors";
import { PageTitle } from "@/components/src/page-title/page-title.component";

export const AccountingPage: React.FC = () => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const [loading, setLoading] = useState<boolean>(true);
	const [searchParams] = useSearchParams();
	const [selectedRecipient, setSelectedRecipient] = useState<string>(
		searchParams.get("recipient") || ""
	);
	const [selectedStatuses, setSelectedStatuses] = useState<
		AccountingStatus[]
	>([]);
	const currentDate = new Date();

	const defaultStartDate = () => {
		currentDate.setDate(currentDate.getDate() - 90);
		return currentDate;
	};
	const [dateValue, setDateValue] = useState<SelectionRange>({
		start: defaultStartDate(),
		end: new Date(),
	});

	const parseAndProcessCAMT54Transactions = async (xmlContent: string) => {
		if (!organizationId) {
			showNotification({
				message: "Keine Organisation gefunden",
				type: "error",
			});
			Logger.error("No organization id found");
			return;
		}
		const transactions = await parseCAMTTransactions(xmlContent);
		const { data, error } = await supabase.rpc(
			SupabaseRpcEnum.PROCESS_CAMT54_TRANSACTIONS,
			{
				organization_id: organizationId,
				transactions: transactions.map((ts) => ({
					Amount: ts.Amount,
					AmountCurrency: ts.AmountCurrency,
					RemittanceInformation: ts.RemittanceInformation,
					ValueDate: ts.ValueDate,
					BookingDate: ts.BookingDate,
				})),
			}
		);
		Logger.info("Process camt54 transactions", data, error);
	};

	const handleXMLUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!organizationId) {
			Logger.error("No organization id found");
			return;
		}
		const file = e.target.files?.[0];
		if (!file) return;

		const reader = new FileReader();

		reader.onload = (e) => {
			const content = e.target?.result;
			if (typeof content !== "string") return;

			parseAndProcessCAMT54Transactions(content);
		};

		reader.readAsText(file);
	};
	const { accountingData, fetchAccountingData, recipientsLookup } =
		useAccountingStore((state) => ({
			accountingData: state.accountingData,
			fetchAccountingData: state.fetchAccountingData,
			recipientsLookup: state.recipientsLookup,
		}));

	useEffect(() => {
		if (!loading) {
			setLoading(true);
		}
		if (dateValue.start && dateValue.end) {
			fetchAccountingData(
				selectedStatuses,
				dateValue.start.toDateString(),
				dateValue.end.toDateString(),
				selectedRecipient
			).then(() => {
				setLoading(false);
			});
		}
	}, [selectedStatuses, dateValue, dateValue, selectedRecipient]);

	return (
		<div className="flex flex-col px-12 py-6">
			<PageTitle title={[{ name: "Buchhaltung" }]} />
			<div className="flex justify-between py-4">
				<div className="flex gap-8 items-center">
					<AccountingStatusSelector
						selectedStatuses={selectedStatuses}
						onSelect={setSelectedStatuses}
					/>
					<CustomDateRangePicker
						dateVal={dateValue}
						handleDateChange={setDateValue}
					/>
					<AccountingRecipientSelector
						selectedRecipient={selectedRecipient}
						onSelect={setSelectedRecipient}
						recipientList={recipientsLookup}
					/>
				</div>
				<div>
					<input
						type="file"
						className="hidden"
						id="file"
						onChange={handleXMLUpload}
					/>
					<Button
						sx={{
							padding: 0,
						}}
						variant="contained"
						color="primary"
					>
						<label
							className="px-3 h-full w-full py-1 cursor-pointer"
							htmlFor="file"
						>
							CAMT54 Hochladen
						</label>
					</Button>
				</div>
			</div>
			<AccountingTable
				loading={loading}
				accountingData={accountingData}
			/>
		</div>
	);
};

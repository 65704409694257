import { Box, Button, Stack, TextField } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { Database } from "../../../../../../lib/supabase";
import { FormProps } from "../../../form-types";
import { JobEntityType } from "../../../../../../lib/supabase/supabaseTypes";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { JobStatusSelect } from "../../../form-input-components/selects/job-status.select";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { useJobStore } from "@/dentlab/src/store/Jobs";

export const JobGeneralForm: React.FC<FormProps> = ({
	onSave = (job: JobEntityType) => {},
	submitRef = null,
}) => {
	const supabaseTable = SupabaseTableEnum.JOBS;

	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));

	const { job, changeJobStatus, fetchJob } = useJobStore((state) => ({
		job: state.job,
		changeJobStatus: state.changeJobStatus,
		fetchJob: state.fetchJob,
	}));

	// TODO [DW-157]: Laufnummer, startet jeden Monat neu bei 1
	const jobNumber = "0001";

	const {
		handleInputChange,
		handleUpdate,
		formData,
		isPendingChanges,
		handleValueChange,
	} = useForm<Database["public"]["Tables"]["jobs"]["Update"]>(
		{
			organization_id: organization?.id,
		},
		supabaseTable,
		true,
		job?.id,
		"Auftrag"
	);

	const assertSubmit = () => {
		return true;
	};

	const onSubmit = async (event: React.FormEvent) => {
		if (assertSubmit()) {
			const { success, data, error } = await handleUpdate(
				event,
				job?.id as number
			);
			if (data) {
				onSave(data[0]);
			}
		}
		if (job) await fetchJob(job.id as number);
	};

	return (
		<form onSubmit={onSubmit}>
			<Stack direction="column" spacing={2}>
				<JobStatusSelect
					value={formData.status?.toString()}
					onChange={(value) => {
						handleValueChange("status", parseInt(value));
						if (job) changeJobStatus(job, parseInt(value));
					}}
				/>
				<TextField
					label="Eingangsdatum"
					name="incoming_order_date"
					value={formData.incoming_order_date || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					fullWidth
				/>
				<TextField
					label="Ausgangsdatum"
					name="outgoing_order_date"
					value={formData.outgoing_order_date || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					fullWidth
				/>
				<TextField
					label="Zahnfarbe"
					name="tooth_color"
					value={formData.tooth_color || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					fullWidth
				/>
				<TextField
					label="Beschreibung"
					name="description"
					value={formData.description || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					fullWidth
					rows={2}
					multiline
				/>
				<Box>
					<Button
						type="submit"
						variant="contained"
						sx={{ my: 1 }}
						disabled={!isPendingChanges}
					>
						Speichern
					</Button>
				</Box>
			</Stack>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
		</form>
	);
};

import { CustomSelect } from "../../../components/custom-select.component";
import { TariffsConfigKeys } from "../../../types/enums";
import { SelectProps } from "../../form-types";

const options = [
	{ value: TariffsConfigKeys.SV, label: "SV" },
	{ value: TariffsConfigKeys.PP1, label: "PP1" },
	{ value: TariffsConfigKeys.PP2, label: "PP2" },
	{ value: TariffsConfigKeys.PP3, label: "PP3" },
];

/**
 * TpTierSelect - Select for SV, PP1, PP2, PP3
 * @param onChange
 * @param value
 */
export const TpTierSelect: React.FC<SelectProps> = (props) => {
	return (
		<CustomSelect
			options={options}
			inputLabel={props?.label ? props.label : "TP-Stufe"}
			{...props}
		/>
	);
};

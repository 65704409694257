import { Box, Button, TextField } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { Database } from "../../../../../../lib/supabase";
import { BankAccountSelect } from "../../bank-account/input-components/bank-account.select";
import { FormProps } from "../../../form-types";
import { useParams } from "react-router-dom";
import { ProdCountryChoiceSelect } from "../../../form-input-components/selects/prod-country-choice.select";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

export const JobInvoiceForm: React.FC<FormProps> = ({
	onSave = () => {},
	submitRef = null,
}) => {
	const organizationId = useCentralStore((state) => state.organization?.id);

	const { jobId } = useParams();

	const {
		handleInputChange,
		handleUpdate,
		formData,
		isPendingChanges,
		handleValueChange,
	} = useForm<Database["public"]["Tables"]["jobs"]["Update"]>(
		{
			organization_id: organizationId,
		},
		SupabaseTableEnum.JOBS,
		true,
		jobId,
		"Auftrag"
	);

	const assertSubmit = () => {
		return true;
	};

	const onSubmit = async (event: React.FormEvent) => {
		if (assertSubmit()) {
			const { success, data } = await handleUpdate(event, jobId);
			if (success && data) {
				const job = data[0];
				onSave(job);
			} else {
				showNotification({
					message: "Fehler beim Speichern des Auftrags",
					type: "error",
				});
			}
		}
	};

	return (
		<Box>
			<form onSubmit={onSubmit}>
				{/* TODO: separate form parts */}
				<TextField
					label="Max. Rg.-Betrag"
					name="max_inv_value"
					value={formData.max_inv_value || ""}
					onChange={handleInputChange}
					fullWidth
				/>
				<TextField
					label="Taxpunktwert"
					name="tp_value"
					value={formData.tp_value || ""}
					onChange={handleInputChange}
					fullWidth
				/>
				<BankAccountSelect
					onChange={(value) =>
						handleValueChange("bank_account_id", value)
					}
					value={
						formData.bank_account_id
							? formData.bank_account_id.toString()
							: ""
					}
					width="400px"
				/>
				<TextField
					label="MwSt."
					name="tax_rate"
					value={formData.tax_rate || ""}
					onChange={handleInputChange}
					fullWidth
				/>
				<ProdCountryChoiceSelect
					value={formData.prod_country_choice || ""}
					onChange={(value) =>
						handleValueChange("prod_country_choice", value)
					}
				/>
				{/* TODO: letzte Auswahl beibehalten */}
				{formData.prod_country_choice === "other" && (
					<TextField
						label="Fertigungsland (Ausland)"
						name="prod_country_other"
						value={formData.prod_country_other || ""}
						onChange={handleInputChange}
						variant="outlined"
						size="small"
						sx={{ my: 1, mr: 1 }}
						fullWidth
					/>
				)}
				{/* TODO: LS/GS/KV-Datum */}
				{/* TODO: Ohne MR-Rabatt */}
				<Box>
					<Button
						type="submit"
						variant="contained"
						sx={{ my: 1 }}
						disabled={!isPendingChanges}
					>
						Speichern
					</Button>
				</Box>
				<button
					ref={submitRef}
					type="submit"
					style={{ display: "none" }}
				/>
			</form>
		</Box>
	);
};

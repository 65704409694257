import { useEffect, useState } from "react";
import { PatientDialog } from "./components/patient-dialog.component";
import { PatientFormDialog } from "@/dentlab/src/pages-via-dialog/patient/patient.dialog";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import {
	PatientEntityType,
	PatientWithShare,
} from "@/lib/supabase/supabaseTypes";

export const PatientInfoDialog: React.FC<{
	handleSidebarClose: () => void;
}> = ({ handleSidebarClose }) => {
	const [isAddNew, setIsAddNew] = useState(false);
	const [patientFormDialogOpen, setPatientFormDialogOpen] = useState(false);
	const { selectPatientForJob, job } = useJobStore((state) => ({
		selectPatientForJob: state.selectPatientForJob,
		job: state.job,
	}));
	const { patient, upsertPatient } = usePatientStore((state) => ({
		patient: job?.patient_id ? state.patientsLookup[job.patient_id] : null,
		upsertPatient: state.upsertPatient,
	}));

	const [isPatientDialogOpen, setIsPatientDialogOpen] = useState(false);

	useEffect(() => {
		if (!patient?.id) {
			setIsPatientDialogOpen(true);
		} else {
			setPatientFormDialogOpen(true);
		}
	}, [patient]);

	return (
		<>
			{patient?.id ? (
				<PatientFormDialog
					open={patientFormDialogOpen}
					onClose={() => {
						handleSidebarClose();
						setPatientFormDialogOpen(false);
					}}
					patient={patient}
					setPatient={(patient) => {
						upsertPatient(patient as PatientWithShare);
					}}
					onSave={(patient) => {
						upsertPatient(patient as PatientWithShare);
					}}
				/>
			) : (
				<>
					{isAddNew ? (
						<PatientFormDialog
							open={isAddNew}
							onClose={() => {
								setIsAddNew(false);
								handleSidebarClose();
							}}
							onSave={(patient) => {
								upsertPatient(patient as PatientWithShare);
								selectPatientForJob(patient.id as string);
								handleSidebarClose();
							}}
						/>
					) : (
						<PatientDialog
							onPatientSelect={(patient) => {
								selectPatientForJob(patient?.id);
								setIsPatientDialogOpen(false);
								handleSidebarClose();
							}}
							openNewPatientDialog={() => setIsAddNew(true)}
							open={isPatientDialogOpen}
							setOpen={() => {
								setIsPatientDialogOpen(false);
								handleSidebarClose();
							}}
						/>
					)}
				</>
			)}
		</>
	);
};

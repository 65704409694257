import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

const SHOW_ALL = "ALL";

export const AccountingRecipientSelector: React.FC<{
	onSelect: React.Dispatch<React.SetStateAction<string>>;
	selectedRecipient: string;
	recipientList: Record<
		string,
		{
			type: "client" | "guarantor";
			name: string;
		}
	>;
}> = ({ selectedRecipient, onSelect, recipientList }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const handleSelect = (e: SelectChangeEvent<typeof selectedRecipient>) => {
		const value = e.target.value as unknown as string;

		setSearchParams({ recipient: value });
		onSelect(value);
	};
	return (
		<div className="h-full gap-2 pt-1 flex items-end">
			<FormControl variant="filled" size="small">
				<InputLabel>Empfänger</InputLabel>
				<Select
					sx={{
						width: "250px",
					}}
					value={
						selectedRecipient.length ? selectedRecipient : SHOW_ALL
					}
					onChange={handleSelect}
					label="Empfänger"
				>
					<MenuItem value={SHOW_ALL}>Alle</MenuItem>)
					{Object.entries(recipientList).map(([id, { name }]) => {
						return (
							<MenuItem key={id} value={id}>
								{name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>

			<div className="h-full py-1 flex">
				<Button
					size="large"
					onClick={() => {
						onSelect("");
						setSearchParams({});
					}}
				>
					Adressaten entfernen
				</Button>
			</div>
		</div>
	);
};

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "./styles.css";
import { usePatientNameByFirstAndLastName } from "../../../../hooks/useName/useNameHooks";

export const PatientProfileButton: React.FC<{
  firstName: string | null;
  lastName: string | null;
  onClick: () => void;
}> = ({ firstName, lastName, onClick }) => {
  const { name: patientName } = usePatientNameByFirstAndLastName(
    firstName,
    lastName,
  );
  return (
    <div
      className="patient-profile-button"
      onClick={onClick}
      data-testid="patient-profile-button"
    >
      <AccountBoxIcon
        sx={{
          fontSize: "1.5rem",
        }}
      />
      <span>{patientName}</span>
    </div>
  );
};

// This is just for keeping track for possible future use

import { TariffsConfigurationType } from "../../../types/configuration.types";
import { TariffsConfigKeys } from "../../../types/enums";

/**
 * @example {
    "tp_pp1": 5.55,
    "tp_pp2": 5.55,
    "tp_pp3": 6.2,
    "tp_sv": 5.55,
    "tp_tier": "tp_pp2"
}
 */
export const TariffsConfigDefault: TariffsConfigurationType = {
  [TariffsConfigKeys.SV]: 1, // TpW_SV
  [TariffsConfigKeys.PP1]: 1, // TpW_PP1
  [TariffsConfigKeys.PP2]: 1, // TpW_PP2
  [TariffsConfigKeys.PP3]: 1, // TpW_PP3
  // tp_pp1, tp_pp2, tp_pp3, tp_sv - this is new
  [TariffsConfigKeys.TIER]: "tp_pp2", // TpTier
};

import { Typography } from "@/components/src/typography/typography.component";
import { useSearchStore } from "../../store/Search";
import {
	ClientEntityType,
	JobEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { Card, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "../../store/Central";

const ResultCard: React.FC<{
	onClick: () => void;
	children: React.ReactNode;
}> = ({ onClick, children }) => {
	return (
		<Card
			sx={{
				marginBottom: "10px",
				cursor: "pointer",
			}}
			onClick={onClick}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "10px",
					padding: "10px",
				}}
			>
				{children}
			</div>
		</Card>
	);
};

const ClientResultCard: React.FC<{ client: ClientEntityType }> = ({
	client,
}) => {
	const navigate = useNavigate();
	return (
		<ResultCard
			onClick={() => {
				navigate(`/client/${client.id}`);
			}}
		>
			<Chip label={client.code} size="small" color="secondary" />
			<Typography variant="body-lg">
				{client.title} {client?.first_name} {client?.last_name}
			</Typography>
			<Typography variant="body-md">{client.email}</Typography>
			<Typography variant="body-md">
				{client.street} {client.postal_code} {client.city}
			</Typography>
		</ResultCard>
	);
};

const PatientResultCard: React.FC<{ patient: PatientEntityType }> = ({
	patient,
}) => {
	// TODO: apply privacy mode
	// TODO: add way of going to patient or viewing/editing patient info
	return (
		<ResultCard onClick={() => {}}>
			<Chip label={patient.code} size="small" color="primary" />
			<Typography variant="body-lg">
				{patient.title} {patient?.first_name} {patient?.last_name}
			</Typography>
		</ResultCard>
	);
};

const JobResultCard: React.FC<{ job: JobEntityType }> = ({ job }) => {
	const navigate = useNavigate();
	const client =
		useCentralStore.getState().clientsLookup[job.client_id as string];
	return (
		<ResultCard
			onClick={() => {
				navigate(`/client/${job.client_id}/job/${job.id}`);
			}}
		>
			<Chip label={job.code} size="small" color="info" />
			<Chip
				label={client?.first_name + " " + client?.last_name}
				size="small"
				color="secondary"
			/>
			<Typography variant="body-lg">{job?.title}</Typography>
		</ResultCard>
	);
};

export const SearchPage: React.FC = () => {
	const {
		clients,
		patients,
		jobs,
		loadingClients,
		loadingPatients,
		loadingJobs,
	} = useSearchStore((state) => ({
		clients: state.searchResultsClients,
		patients: state.searchResultsPatients,
		jobs: state.searchResultsJobs,
		loadingClients: state.loadingClients,
		loadingPatients: state.loadingPatients,
		loadingJobs: state.loadingJobs,
	}));

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "20px 40px 0 40px",
			}}
			data-testid="search-page"
		>
			<div>
				<Typography variant="title-md">Auftraggeber</Typography>
				{loadingClients && (
					<Typography variant="title-sm">Lade...</Typography>
				)}
				{clients?.map((client) => {
					return <ClientResultCard key={client.id} client={client} />;
				})}
			</div>
			<div>
				{loadingPatients && (
					<Typography variant="title-sm">Lade...</Typography>
				)}
				<Typography variant="title-md">Patienten</Typography>
				{patients?.map((patient) => {
					return (
						<PatientResultCard key={patient.id} patient={patient} />
					);
				})}
			</div>
			<div>
				{loadingJobs && (
					<Typography variant="title-sm">Lade...</Typography>
				)}
				<Typography variant="title-md">Aufträge</Typography>
				{jobs?.map((job) => {
					return <JobResultCard key={job.id} job={job} />;
				})}
			</div>
		</div>
	);
};

import { Box } from "@mui/material";
import { AccordionComponent } from "../../../components/accordion.component";
import { EmployeeDetails } from "./employee-details.component";
import { ActivityFooter } from "../../../components/activity-footer.component";
import { JobGeneralForm } from "../../../forms/form-components/job/update/job-general.form";
import { JobInvoiceForm } from "../../../forms/form-components/job/update/job-invoice.form";
import { PractitionerDetails } from "./practitioner-details.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { GuarantorSelect } from "@/dentlab/src/forms/form-input-components/selects/guarantor.select";

export const JobInfo: React.FC = () => {
	const { job } = useJobStore();
	return (
		<div
			style={{
				padding: "10px",
			}}
		>
			<JobGeneralForm />
			<PractitionerDetails />
			<EmployeeDetails />
			<JobInfoGuarantorSelect />
			<AccordionComponent
				title="Rechnung"
				// TODO: show description based on screen width titleDescription="Konto, Garant, Taxpunktwert"
				defaultExpanded={false}
			>
				<JobInvoiceForm />
			</AccordionComponent>
			<Box sx={{ mt: "10px" }}>
				{job && <ActivityFooter entity={job} />}
			</Box>
		</div>
	);
};

const JobInfoGuarantorSelect: React.FC = () => {
	const { updateGuarantor, guarantorId } = useJobStore((state) => ({
		updateGuarantor: state.updateGuarantor,
		guarantorId: state.job?.guarantor_id,
	}));

	const handleGuarantorChange = (value: string) => {
		if (value) {
			updateGuarantor(value);
		} else {
			updateGuarantor(null);
		}
	};

	return (
		<GuarantorSelect
			onChange={handleGuarantorChange}
			value={guarantorId?.toString()}
		/>
	);
};

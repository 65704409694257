import {
	JobItemTemplateGroupWithJobItemTemplatesType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { initialState } from "./utils";
import { State } from "./types";
import { useCentralStore } from "../Central";

interface Actions {
	fetchJobItemGroups: () => Promise<void>;
	deleteJobItemGroups: (id: number) => Promise<void>;
}

// Must be hoisted to another file if this store gets expanded
export type JobItemGroupsSlice = State & Actions;

export const createJobItemGroupsStore: StateCreator<JobItemGroupsSlice> = (
	set,
	get
) => ({
	...initialState,
	fetchJobItemGroups: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return;

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS)
				.select("id, name, job_item_templates (*)")
				.eq("organization_id", organizationId)
		);
		if (error) {
			Logger.error(error);
			return;
		}
		set({
			jobItemGroups:
				data as JobItemTemplateGroupWithJobItemTemplatesType[],
		});
	},

	deleteJobItemGroups: async (id: number) => {
		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS)
				.delete()
				.eq("id", id)
				.select()
		);

		if (error) {
			Logger.error(error);
			return;
		}
		const { fetchJobItemGroups } = get();
		fetchJobItemGroups();
	},
});

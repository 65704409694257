import { useEffect, useState } from "react";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { MonthlyInvoicesCard } from "./monthly-invoices-card.component";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { supabase } from "@/lib/supabase";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { useCentralStore } from "../../store/Central";
import { showNotification } from "../../store/Central/selectors";
import { RecipientTypeEnum } from "./types";
import { PageTitle } from "@/components/src/page-title/page-title.component";

/** We use views to get the data used here

CREATE VIEW completed_job_documents_per_client_view AS
SELECT
  j.organization_id,
  j.client_id,
  json_agg(
	json_build_object(
	  'id', jd.id,
	  'date', jd.date,
	  'type', jd.type,
	  'amount', jd.amount,
	  'job_id', j.id,
	  'job_patient_id', j.patient_id,
	  'job_title', j.title,
	  'job_code', j.code,
	  'job_client_id', j.client_id
	) ORDER BY jd.date
  ) AS job_documents
FROM
  job_documents jd
  JOIN jobs j ON jd.job_id = j.id
WHERE
  jd.status = 2
  AND (jd.type = 'dn' OR jd.type = 'cn')
  AND j.guarantor_id IS NULL
GROUP BY
  j.organization_id,
  j.client_id;

CREATE VIEW completed_job_documents_per_guarantor_view AS
SELECT
 j.organization_id,
 j.guarantor_id,
 json_agg(
   json_build_object(
	 'id', jd.id,
	 'date', jd.date,
	 'type', jd.type,
	 'amount', jd.amount,
	 'job_id', j.id,
	 'job_patient_id', j.patient_id,
	 'job_title', j.title,
	 'job_code', j.code,
	 'job_client_id', j.client_id
   ) ORDER BY jd.date
 ) AS job_documents
FROM
 job_documents jd
 JOIN jobs j ON jd.job_id = j.id
WHERE
 jd.status = 2
 AND (jd.type = 'dn' OR jd.type = 'cn')
 AND j.guarantor_id IS NOT NULL
GROUP BY
 j.organization_id,
 j.guarantor_id;

*/

export type CompletedJobDocumentFromViewType = {
	id: number;
	date: string;
	type: string;
	amount: number;
	job_id: number;
	job_patient_id: string;
	job_title: string;
	job_code: string;
	job_client_id: string;
};

type CompletedJobDocumentsGroupClients = {
	client_id: string;
	job_documents: CompletedJobDocumentFromViewType[];
};

type CompletedJobDocumentsGroupGuarantors = {
	guarantor_id: string;
	job_documents: CompletedJobDocumentFromViewType[];
};

export const MonthlyInvoicesPage = () => {
	const organization = useCentralStore((state) => state.organization);
	const [loading, setLoading] = useState<boolean>(false);
	const [clients, setClients] = useState<CompletedJobDocumentsGroupClients[]>(
		[]
	);
	const [guarantors, setGuarantors] = useState<
		CompletedJobDocumentsGroupGuarantors[]
	>([]);

	const [dateValue, setDateValue] = useState<SelectionRange>({
		// first day of current month
		start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		// today
		end: new Date(),
	});

	useEffect(() => {
		if (organization?.id) {
			fetchData();
		}
	}, [organization?.id]);

	const fetchData = async () => {
		setLoading(true);
		const { data: jobDocumentsPerClient, error: perClientError } =
			await handleDatabaseOperation(
				supabase
					.from("completed_job_documents_per_client_view")
					.select("client_id, job_documents")
					.eq("organization_id", organization?.id as string)
			);
		if (jobDocumentsPerClient) {
			setClients(jobDocumentsPerClient);
		}
		const { data: jobDocumentsPerGuarantor, error: perGuarantorError } =
			await supabase
				.from("completed_job_documents_per_guarantor_view")
				.select("guarantor_id, job_documents")
				.eq("organization_id", organization?.id as string);
		if (jobDocumentsPerGuarantor) {
			setGuarantors(
				jobDocumentsPerGuarantor as CompletedJobDocumentsGroupGuarantors[]
			);
		}
		if (perClientError || perGuarantorError) {
			showNotification({
				message: "Fehler beim Laden der Daten",
				type: "error",
			});
		}
		setLoading(false);
	};

	return (
		<div className="flex flex-col px-12 py-6">
			<PageTitle title={[{ name: "Sammelrechnungen" }]} />
			<div className="flex flex-col gap-6 py-4">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						paddingBottom: "20px",
					}}
				>
					<span
						style={{
							fontSize: "14px",
							fontWeight: "bold",
						}}
					>
						Schnellauswahl
					</span>
					<CustomDateRangePicker
						dateVal={dateValue}
						handleDateChange={setDateValue}
					/>
				</div>
				{loading && <div>Lädt...</div>}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					{clients.map((documentGroup) => {
						return (
							<MonthlyInvoicesCard
								key={documentGroup.client_id}
								recipient={
									useCentralStore.getState().clientsLookup[
										documentGroup.client_id
									]
								}
								dateRange={dateValue}
								onManualSelect={() => {}}
								rows={documentGroup.job_documents}
								onFetchData={() => fetchData()}
								recipientType={RecipientTypeEnum.CLIENT}
							/>
						);
					})}
					{guarantors.map((documentGroup) => {
						return (
							<MonthlyInvoicesCard
								key={documentGroup.guarantor_id}
								recipient={
									useCentralStore.getState().guarantorLookup[
										documentGroup.guarantor_id
									]
								}
								dateRange={dateValue}
								onManualSelect={() => {}}
								rows={documentGroup.job_documents}
								onFetchData={() => fetchData()}
								recipientType={RecipientTypeEnum.GUARANTOR}
							/>
						);
					})}
				</div>
				{clients.length === 0 && guarantors.length === 0 && (
					<div
						style={{
							padding: "30px",
						}}
					>
						Keine abgeschlossenen Lieferscheine oder Gutschriften,
						die noch keiner Sammelrechnung angerechnet sind.
					</div>
				)}
			</div>
		</div>
	);
};

import { IconButton, Paper } from "@mui/material";
import {
	JobItemTemplateEntityType,
	JobItemTemplateGroupEntityType,
	JobItemTemplateGroupWithJobItemTemplatesType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import { EditableTitle } from "../../../../../components/src/editable-title/editable-title.component";
import { CustomTable } from "../../../components/custom-table/custom-table.component";
import { jobItemsTableConfigDefault } from "../../job-page/job-document/job-document-components/job-items-table/job-items-table-config-default";
import { Logger } from "../../../../../lib/logger/Logger";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "../../../hooks/useForm/useForm";
import { useDatabaseFunctionsWithPromise } from "../../../hooks/useDatabase";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loading } from "@/components/src/animations/loading";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const JobItemGroupCard: React.FC<{
	selectedJobItemGroup: number;
	refetch: () => Promise<void>;
	onDeleteGroup: () => void;
}> = ({ selectedJobItemGroup, refetch, onDeleteGroup }) => {
	const [group, setGroup] =
		useState<JobItemTemplateGroupWithJobItemTemplatesType | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
	const organizationId = useCentralStore((state) => state.organization?.id);

	const fetchSingleJobItemGroup = useCallback(
		async (id: number) => {
			setLoading(true);
			const { data, error } = await fetchDataWithPromise(
				SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS,
				[
					{
						column: "organization_id",
						value: organizationId,
					},
					{
						column: "id",
						value: id,
					},
				],
				"*, job_item_templates (*)"
			);

			if (error) {
				Logger.error(error);
				return;
			}

			if (!data || !data.length) return;

			setGroup(data[0] as JobItemTemplateGroupWithJobItemTemplatesType);

			setJobItemTemplates(
				data[0].job_item_templates as JobItemTemplateEntityType[]
			);
			setLoading(false);
		},
		[organizationId]
	);

	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();

	const [jobItemTemplates, setJobItemTemplates] = useState<
		JobItemTemplateEntityType[]
	>([]);

	useEffect(() => {
		if (selectedJobItemGroup) {
			fetchSingleJobItemGroup(selectedJobItemGroup);
		}
	}, [selectedJobItemGroup, fetchSingleJobItemGroup]);

	// TODO: Determine type of newRow
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleRowChange = async (newRow: any) => {
		const { inEdit, selected, expanded, ...newRowWithoutMeta } = newRow;
		const { success, data, error } = await updateDataWithPromise(
			SupabaseTableEnum.JOB_ITEM_TEMPLATES,
			[newRowWithoutMeta]
		);
		if (success) {
			const newJobItemTemplates = jobItemTemplates.map(
				(jobItemTemplate) => {
					if (jobItemTemplate.id === newRow.id) {
						return { ...jobItemTemplate, ...newRowWithoutMeta };
					}
					return jobItemTemplate;
				}
			);
			setJobItemTemplates(newJobItemTemplates);
		}
		Logger.info("handleRowChange", newRow);
		refetch();
	};

	if (!group || loading)
		return (
			<div className="w-full h-full flex items-center justify-center">
				<Loading />
			</div>
		);

	if (!loading && !jobItemTemplates.length)
		return (
			<div className="w-full h-full flex items-center justify-center">
				Diese Gruppe ist leer
			</div>
		);

	return (
		<Paper sx={{ marginBottom: "1rem" }}>
			<div
				style={{
					padding: "5px",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<JobItemGroupCardEditableTitle
					onChange={refetch}
					groupId={group.id}
				/>
				<IconButton
					onClick={onDeleteGroup}
					size="small"
					sx={{ marginLeft: "auto" }}
				>
					<DeleteIcon />
				</IconButton>
			</div>
			<CustomTable
				rows={jobItemTemplates}
				defaultConfig={{ ...jobItemsTableConfigDefault, dataState: {} }}
				onConfigChange={() => {}}
				onRowChange={handleRowChange}
				onSelectedChange={() => {}}
				onPasteRows={() => {}}
				onCutRows={() => {}}
				dataCompatibility={["job_items"]}
				onDeleteRows={() => {}}
				noToolbar
				noGroups
			/>
		</Paper>
	);
};

const JobItemGroupCardEditableTitle: React.FC<{
	groupId: number;
	onChange: () => Promise<void>;
}> = ({ groupId, onChange }) => {
	const { handleUpdate, resetFormData, formData, handleValueChange } =
		useForm<JobItemTemplateGroupEntityType>(
			{},
			SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS,
			true,
			groupId,
			"Name"
		);

	const handleSubmit = async () => {
		const { success, error, data } = await handleUpdate(null, groupId);
		await onChange();
	};

	return (
		<EditableTitle
			name="title"
			value={formData.name}
			onChange={(e) => {
				handleValueChange("name", e.target.value);
			}}
			onSubmit={handleSubmit}
			loading={false}
			onReset={resetFormData}
		/>
	);
};

export enum TariffsConfigKeys {
	PP1 = "tp_pp1",
	PP2 = "tp_pp2",
	PP3 = "tp_pp3",
	SV = "tp_sv",
	TIER = "tp_tier",
}

export enum ConfigurationColumns {
	TARIFFS = "tariffs_config",
	DOCUMENTS = "documents_config",
}

/**
 * V1 main bucket, guarded via RLS on public.files (if you have access to the entry in public.files, you have access to the path specified in the path_name column)
 * - read is guarded by RLS on public.files
 * - anyone can upload if authenticated
 * In ORG_V1 every organization has its own folder by org_id and can only access the folder of their organization (via org_id in app_metadata). The paths inside that folder don't matter.
 * - all operations are allowed in one's own folder
 */
export enum StorageBucketsEnum {
	V1 = "v1",
	ORG_V1 = "org_v1",
}

export enum Currency {
	CHF = "CHF",
	EUR = "EUR",
}

export enum RolesEnum {
	OWNER = 0,
	ADMIN = 1,
	MEMBER = 2,
}

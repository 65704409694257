import { Stack } from "@mui/material";
import { JobItemGroupCard } from "./job-item-groups-components/job-item-group-card.component";
import { useCallback, useEffect, useState } from "react";
import {
	JobItemTemplateGroupWithJobItemTemplatesType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";
import { Loading } from "@/components/src/animations/loading";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { MenuItemButton } from "@/components/src/menu-item-button/menu-item-button.component";
import { useCentralStore } from "../../store/Central";
import { useSearchParams } from "react-router-dom";
import { PageTitle } from "@/components/src/page-title/page-title.component";

export const JobItemGroupsPage = () => {
	const [searchParams, _setSearchParams] = useSearchParams("groupId");
	const groupId = searchParams.get("groupId");
	const organizationId = useCentralStore((state) => state.organization?.id);
	const [jobItemGroups, setJobItemGroups] = useState<
		JobItemTemplateGroupWithJobItemTemplatesType[] | null
	>(null);
	const [selectedJobItemGroup, setSelectedJobItemGroup] = useState<
		number | null
	>(groupId ? Number(groupId) : null);
	const { fetchDataWithPromise, deleteDataWithPromise } =
		useDatabaseFunctionsWithPromise();
	const [loading, setLoading] = useState<boolean>(false);
	const fetchJobItemGroups = useCallback(async () => {
		setLoading(true);
		if (!organizationId) return;

		const { data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS,
			{
				column: "organization_id",
				value: organizationId,
			},
			"id, name"
		);
		if (error) {
			Logger.error(error);
			return;
		}

		setJobItemGroups(data ?? []);
		setLoading(false);
	}, [organizationId]);

	const deleteJobItemGroups = useCallback(
		async (id: number) => {
			const { success, error, data } = await deleteDataWithPromise(
				SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS,
				[id]
			);

			if (error) {
				Logger.error(error);
				return;
			}
			setJobItemGroups(
				jobItemGroups?.filter((group) => group.id !== id) ?? []
			);
			setSelectedJobItemGroup(null);
			await fetchJobItemGroups();
		},
		[fetchJobItemGroups]
	);

	useEffect(() => {
		if (organizationId) fetchJobItemGroups();
	}, [organizationId, fetchJobItemGroups]);

	useEffect(() => {
		if (groupId) {
			setSelectedJobItemGroup(parseInt(groupId));
		}
	}, [groupId]);

	if (!jobItemGroups && loading) return <Loading />;

	return (
		<div className="flex flex-col h-full px-12 py-6">
			<PageTitle title={[{ name: "Vorlagen" }]} />
			<div className="flex flex-row h-full">
				<div
					style={{
						backgroundColor:
							"var(--color-elevation-coloredSurface)",
					}}
					className="flex w-60 min-w-60 max-w-60 flex-col gap-2 my-4 py-2 px-3 rounded-lg h-full"
				>
					{jobItemGroups?.map((group) => (
						<MenuItemButton
							selected={selectedJobItemGroup === group.id}
							title={group.name}
							onClick={() => {
								setSelectedJobItemGroup(group.id);
							}}
							style={{
								borderRadius: "4px",
								textAlign: "left",
							}}
						/>
					))}
					{jobItemGroups?.length === 0 && (
						<div>Sie haben noch keine Gruppen hinzugefügt.</div>
					)}
				</div>
				<Stack
					spacing={2}
					sx={{
						padding: "1rem",
						margin: "1rem",
						borderRadius: "1rem",
						width: "100%",
					}}
				>
					{selectedJobItemGroup ? (
						<JobItemGroupCard
							selectedJobItemGroup={selectedJobItemGroup}
							refetch={fetchJobItemGroups}
							onDeleteGroup={() =>
								deleteJobItemGroups(selectedJobItemGroup)
							}
						/>
					) : (
						<div
							style={{
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
							}}
						>
							Keine Vorlage ausgewählt.
						</div>
					)}
				</Stack>
			</div>
		</div>
	);
};

import { OrganizationEntityType } from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import vzlsLogo from "../../assets/vzls.ico";
import { PdfTemplateType } from "../document-template.types";

export const PdfLetterhead: React.FC<{
	organization: OrganizationEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	pdfType: PdfTemplateType;
	logoUrl?: string;
}> = ({ organization, pdfTemplateConfiguration, pdfType, logoUrl }) => {
	const showLogo = pdfTemplateConfiguration[pdfType].showLogo;

	return (
		<div aria-label="Briefkopf" style={{ height: "45mm" }}>
			<div
				aria-label="Oberhalb der Trennlinie mit Firmenlogo, Adresse, Kontaktinformationen und Mitgliedschaftsstatus"
				style={{
					borderBottom: "1px solid black",

					// space between vertically
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					height: "38mm", // "Falls ein Briefkopf mit Logo oder Adresse verwendet wird, ist dieser nicht höher als 38mm"
				}}
			>
				{/* TODO: Logo is not exactly 20mm https://dentdesk.atlassian.net/browse/DW-883 */}
				{showLogo && (
					<div
						style={{
							height: "27mm",
							paddingTop: "4mm",
							paddingBottom: "3mm",
							overflow: "hidden",
						}}
					>
						<img
							alt="logo"
							style={{
								objectFit: "cover",
								height: "20mm",
								width: "20mm",
							}}
							src={logoUrl ?? ""}
						/>
					</div>
				)}
				<div aria-label="Firmenadresse">
					<p>{organization?.name}</p>
					<p>{organization?.name_or_additional_information}</p>
					<p>{organization?.street}</p>
					<p>{organization?.city}</p>
				</div>
				<div
					aria-label="Kontaktinformationen und Mitgliedschaftsstatus"
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						paddingBottom: "1mm",
						fontSize: "10pt",
						alignItems: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "2mm",
						}}
					>
						<p>{organization?.phone_and_fax}</p>
						<p>{pdfTemplateConfiguration?.general?.email}</p>
					</div>
					{pdfTemplateConfiguration?.general?.showVzlsMembership && (
						<div
							aria-label="VZLS-Logo und Mitgliedschaftsstatus"
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "2mm",
								alignItems: "center",
							}}
						>
							<p
								style={{
									fontStyle: "italic",
								}}
							>
								Mitglied VZLS
							</p>
							<img src={vzlsLogo} height={20} />
						</div>
					)}
				</div>
			</div>
			<div
				aria-label="Unterhalb der Trennlinie mit MWST und GLN"
				style={{
					display: "flex",
					flexDirection: "row",
					paddingTop: "2mm",
					fontSize: "8px",
					height: "5mm",
					gap: "8mm",
				}}
			>
				{organization.mwst_number && (
					<p>MwSt.-Nummer: {organization.mwst_number}</p>
				)}
				<p>GLN: {organization.gln}</p>
			</div>
		</div>
	);
};

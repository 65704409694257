// Although the organization context has the document configuration, it can be hard for React to deal with state u
// pdates of very nested data. Therefore, we have a separate context for accessing and updating the pdf template configuration.

import { createContext, useContext, useEffect, useState } from "react";
import { ConfigurationColumns } from "../types/enums";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../hooks/useDatabase";
import { useToast } from "./ToastContext";
import { PdfTemplateType } from "../pdf-templates/document-template.types";
import { useCentralStore } from "../store/Central";
import { showNotification } from "../store/Central/selectors";
import { Logger } from "@/lib/logger/Logger";

export interface PdfTemplateConfiguration {
	general: {
		clientAddressLeft: boolean;
		email: string;
		showVzlsMembership: boolean;
		marginTop: number;
		marginBottom: number;
		marginLeft: number;
		marginRight: number;
		logoPath: string;
	};
	[PdfTemplateType.KOSTENVORANSCHLAG]: {
		footerText: string;
		showSignature: boolean;
		showLogo: boolean;
	};
	[PdfTemplateType.LIEFERSCHEIN]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.RECHNUNG]: {
		footerText: string;
		textAbovePaymentSlip: string;
		showLogo: boolean;
	};
	[PdfTemplateType.RECHNUNGSDOPPEL]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.ZAHLUNGSERRINERUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.ERSTE_MAHNUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.FOLGEMAHNUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.BARZAHLUNGSQUITTUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.AKONTORECHNUNG]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.GUTSCHRIFT]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.AUFTRAGSBLATT]: {
		footerText: string;
		showLogo: boolean;
	};
	[PdfTemplateType.SAMMELRECHNUNG]: {
		footerText: string;
		showLogo: boolean;
	};
}

const DEFAULT_TEMPLATE_CONFIGURATION: PdfTemplateConfiguration = {
	general: {
		clientAddressLeft: true,
		email: "",
		showVzlsMembership: true,
		marginTop: 5,
		marginBottom: 10,
		marginLeft: 20,
		marginRight: 10,
		logoPath: "",
	},
	[PdfTemplateType.KOSTENVORANSCHLAG]: {
		footerText:
			"Die angegebenen Kosten können bis zu 15% abweichen. Falls zusätzliche Arbeiten notwendig werden, die einen grösseren Mehraufwand bedingen, werden wir Sie darauf aufmerksam machen.",
		showSignature: true,
		showLogo: true,
	},
	[PdfTemplateType.LIEFERSCHEIN]: {
		footerText:
			"Wir versichern, dass das Produkt für den oben erwähnten Patienten hergestellt wurde, und dass es den in Anhang I und insbesondere VIII der RL93/42/EWG genannten grundlegenden Anforderungen entspricht.",
		showLogo: true,
	},
	[PdfTemplateType.RECHNUNG]: {
		footerText: "",
		textAbovePaymentSlip: "Zahlbar nach Erhalt der Rechnung, netto",
		showLogo: true,
	},
	[PdfTemplateType.RECHNUNGSDOPPEL]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.ZAHLUNGSERRINERUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.ERSTE_MAHNUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.FOLGEMAHNUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.BARZAHLUNGSQUITTUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.AKONTORECHNUNG]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.GUTSCHRIFT]: {
		footerText:
			"Wir versichern, dass das Produkt für den oben erwähnten Patienten hergestellt wurde, und dass es den in Anhang I der RL93/42/EWG genannten grundlegenden Anforderungen entspricht.",
		showLogo: true,
	},
	[PdfTemplateType.AUFTRAGSBLATT]: {
		footerText: "",
		showLogo: true,
	},
	[PdfTemplateType.SAMMELRECHNUNG]: {
		footerText: "",
		showLogo: true,
	},
};

// The columns of the sub-configurations that can be updated
type SettingsColumn = PdfTemplateType | "general";

// The columns of each sub-configuration that can be updated (particularly used for resetting the configuration)
type ConfigurationColumnNames =
	| keyof PdfTemplateConfiguration["general"]
	| keyof PdfTemplateConfiguration[PdfTemplateType.KOSTENVORANSCHLAG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.LIEFERSCHEIN]
	| keyof PdfTemplateConfiguration[PdfTemplateType.RECHNUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.RECHNUNGSDOPPEL]
	| keyof PdfTemplateConfiguration[PdfTemplateType.ZAHLUNGSERRINERUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.ERSTE_MAHNUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.FOLGEMAHNUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.BARZAHLUNGSQUITTUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.AKONTORECHNUNG]
	| keyof PdfTemplateConfiguration[PdfTemplateType.GUTSCHRIFT]
	| keyof PdfTemplateConfiguration[PdfTemplateType.AUFTRAGSBLATT]
	| keyof PdfTemplateConfiguration[PdfTemplateType.SAMMELRECHNUNG];

type PartialPdfTemplateConfiguration = Partial<
	| PdfTemplateConfiguration["general"]
	| PdfTemplateConfiguration[PdfTemplateType.KOSTENVORANSCHLAG]
	| PdfTemplateConfiguration[PdfTemplateType.LIEFERSCHEIN]
	| PdfTemplateConfiguration[PdfTemplateType.RECHNUNG]
	| PdfTemplateConfiguration[PdfTemplateType.RECHNUNGSDOPPEL]
	| PdfTemplateConfiguration[PdfTemplateType.ZAHLUNGSERRINERUNG]
	| PdfTemplateConfiguration[PdfTemplateType.ERSTE_MAHNUNG]
	| PdfTemplateConfiguration[PdfTemplateType.FOLGEMAHNUNG]
	| PdfTemplateConfiguration[PdfTemplateType.BARZAHLUNGSQUITTUNG]
	| PdfTemplateConfiguration[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]
	| PdfTemplateConfiguration[PdfTemplateType.AKONTORECHNUNG]
	| PdfTemplateConfiguration[PdfTemplateType.GUTSCHRIFT]
	| PdfTemplateConfiguration[PdfTemplateType.AUFTRAGSBLATT]
	| PdfTemplateConfiguration[PdfTemplateType.SAMMELRECHNUNG]
>;

// We export this because it's used in pdf-template-single-configurations.component.tsx
export interface PdfTemplateConfigurationContextProps {
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	updatePdfTemplateConfiguration: (
		settingsColumn: SettingsColumn,
		newConfig: PartialPdfTemplateConfiguration
	) => Promise<void>;
	resetConfigurationColumn: (
		settingsColumn: SettingsColumn,
		column: ConfigurationColumnNames
	) => Promise<void>;
}

const PdfTemplateConfigurationContext = createContext<
	PdfTemplateConfigurationContextProps | undefined
>(undefined);

export const PdfTemplateConfigurationContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: any) => {
	const [pdfTemplateConfiguration, setPdfTemplateConfiguration] =
		useState<PdfTemplateConfiguration>(DEFAULT_TEMPLATE_CONFIGURATION);
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { showToast } = useToast();

	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	useEffect(() => {
		if (organizationId) {
			fetchPdfTemplateConfiguration();
		}
	}, [organizationId]);

	const fetchPdfTemplateConfiguration = async () => {
		const { success, data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.ORGANIZATIONS,
			{
				column: "id",
				value: organizationId,
			},
			ConfigurationColumns.DOCUMENTS
		);

		if (!success) {
			showNotification({
				message: "Fehler beim Laden der PDF-Einstellungen",
				type: "error",
			});
			return;
		}

		const pdfTemplateConfiguration =
			data[0][ConfigurationColumns.DOCUMENTS] ??
			DEFAULT_TEMPLATE_CONFIGURATION;

		// In case some fields haven't been set, for every column we merge the default configuration with configuration from the db
		setPdfTemplateConfiguration({
			general: {
				...DEFAULT_TEMPLATE_CONFIGURATION.general,
				...pdfTemplateConfiguration.general,
			},
			[PdfTemplateType.KOSTENVORANSCHLAG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.KOSTENVORANSCHLAG
				],
				...pdfTemplateConfiguration[PdfTemplateType.KOSTENVORANSCHLAG],
			},
			[PdfTemplateType.LIEFERSCHEIN]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[PdfTemplateType.LIEFERSCHEIN],
				...pdfTemplateConfiguration[PdfTemplateType.LIEFERSCHEIN],
			},
			[PdfTemplateType.RECHNUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[PdfTemplateType.RECHNUNG],
				...pdfTemplateConfiguration[PdfTemplateType.RECHNUNG],
			},
			[PdfTemplateType.RECHNUNGSDOPPEL]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.RECHNUNGSDOPPEL
				],
				...pdfTemplateConfiguration[PdfTemplateType.RECHNUNGSDOPPEL],
			},
			[PdfTemplateType.ZAHLUNGSERRINERUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.ZAHLUNGSERRINERUNG
				],
				...pdfTemplateConfiguration[PdfTemplateType.ZAHLUNGSERRINERUNG],
			},
			[PdfTemplateType.ERSTE_MAHNUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.ERSTE_MAHNUNG
				],
				...pdfTemplateConfiguration[PdfTemplateType.ERSTE_MAHNUNG],
			},
			[PdfTemplateType.FOLGEMAHNUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[PdfTemplateType.FOLGEMAHNUNG],
				...pdfTemplateConfiguration[PdfTemplateType.FOLGEMAHNUNG],
			},
			[PdfTemplateType.BARZAHLUNGSQUITTUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.BARZAHLUNGSQUITTUNG
				],
				...pdfTemplateConfiguration[
					PdfTemplateType.BARZAHLUNGSQUITTUNG
				],
			},
			[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.KARTENZAHLUNGSQUITTUNG
				],
				...pdfTemplateConfiguration[
					PdfTemplateType.KARTENZAHLUNGSQUITTUNG
				],
			},
			[PdfTemplateType.AKONTORECHNUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.AKONTORECHNUNG
				],
				...pdfTemplateConfiguration[PdfTemplateType.AKONTORECHNUNG],
			},
			[PdfTemplateType.GUTSCHRIFT]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[PdfTemplateType.GUTSCHRIFT],
				...pdfTemplateConfiguration[PdfTemplateType.GUTSCHRIFT],
			},
			[PdfTemplateType.AUFTRAGSBLATT]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.AUFTRAGSBLATT
				],
				...pdfTemplateConfiguration[PdfTemplateType.AUFTRAGSBLATT],
			},
			[PdfTemplateType.SAMMELRECHNUNG]: {
				...DEFAULT_TEMPLATE_CONFIGURATION[
					PdfTemplateType.SAMMELRECHNUNG
				],
				...pdfTemplateConfiguration[PdfTemplateType.SAMMELRECHNUNG],
			},
		});
	};

	/**
	 * updatePdfTemplateConfiguration - updates the document config column in the organizations table
	 * @param newConfig - the new config to be saved
	 * @returns
	 */
	const updatePdfTemplateConfiguration = async (
		settingsColumn: SettingsColumn,
		newConfig: PartialPdfTemplateConfiguration
	) => {
		let updatedConfig;
		setPdfTemplateConfiguration((prev) => {
			updatedConfig = {
				...prev,
				[settingsColumn]: {
					...prev[settingsColumn],
					...newConfig,
				},
			};
			return updatedConfig;
		});
		const { success, data, error } = await updateDataWithPromise(
			SupabaseTableEnum.ORGANIZATIONS,
			[
				{
					id: organizationId,
					[ConfigurationColumns.DOCUMENTS]: updatedConfig,
				},
			]
		);

		if (!success) {
			showToast("Fehler beim Speichern der Einstellungen", "error");
			return;
		}
	};

	/**
	 * Resets a document configuration to the default values
	 * @param settingsColumn - the column to be reset
	 * @param column - the column to be reset
	 */
	const resetConfigurationColumn = async (
		settingsColumn: SettingsColumn,
		column: ConfigurationColumnNames
	) => {
		updatePdfTemplateConfiguration(settingsColumn, {
			[column]: (DEFAULT_TEMPLATE_CONFIGURATION[settingsColumn] as any)[
				column
			],
		});
	};

	return (
		<PdfTemplateConfigurationContext.Provider
			value={{
				pdfTemplateConfiguration,
				updatePdfTemplateConfiguration,
				resetConfigurationColumn,
			}}
		>
			{children}
		</PdfTemplateConfigurationContext.Provider>
	);
};

/**
 * usePdfTemplateConfigurationContext - returns the document configuration context
 * @example const { pdfTemplateConfiguration, updatePdfTemplateConfiguration } = usePdfTemplateConfigurationContext();
 */
export const usePdfTemplateConfigurationContext = () => {
	const context = useContext(PdfTemplateConfigurationContext);
	if (!context) {
		throw new Error(
			"usePdfTemplateConfiguration must be used within pdfTemplateConfigurationurationProvider"
		);
	}
	return context;
};

import { Stack, Typography } from "@mui/material";
import { ConvertTimestampToDate } from "../lib/utils/utils-functions";

type Entity = {
  created_at: string | null;
  created_by: string | null;
  modified_at: string | null;
  modified_by: string | null;
};

interface ActivityFooterProps {
  entity: Entity;
}

export const ActivityFooter: React.FC<ActivityFooterProps> = ({ entity }) => {
  return (
    <Stack>
      {entity.created_at && (
        <Typography variant="body2" gutterBottom>
          Erstellt {ConvertTimestampToDate(entity?.created_at)}
          {entity.created_by && `von ${entity?.created_by}`}
        </Typography>
      )}
      {entity.modified_at && (
        <Typography variant="body2" gutterBottom>
          Zuletzt bearbeitet {ConvertTimestampToDate(entity.modified_at)}
          {entity.modified_by && `von ${entity.modified_by}`}
        </Typography>
      )}
    </Stack>
  );
};

import React from "react";
import { usePatientNameByFirstAndLastName } from "../../../../dentlab/src/hooks/useName/useNameHooks";
import { PatientWithShare } from "../../../../lib/supabase/supabaseTypes";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

export const ActivityPatientLink: React.FC<{
	patient: PatientWithShare;
}> = ({ patient }) => {
	const { name: patientName } = usePatientNameByFirstAndLastName(
		patient?.first_name,
		patient?.last_name
	);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "start",
				gap: "5px",
				alignItems: "center",
			}}
		>
			<AccountBoxIcon
				sx={{
					fontSize: "20px",
				}}
			/>
			<p
				style={{
					fontWeight: "500",
					fontSize: "14px",
					lineHeight: 0, // to align with the icon
				}}
			>
				{patientName}
			</p>
		</div>
	);
};

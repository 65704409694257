import { StateCreator } from "zustand";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	AccountingViewType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { AccountingStatus } from "../../pages/accounting/types";
import { RecipientTypeEnum } from "../../pages/monthly-invoices/types";

type RecipientsLookupValue = { type: RecipientTypeEnum; name: string };

export interface AccountingSlice {
	accountingData: AccountingViewType[];
	recipientsLookup: Record<string, RecipientsLookupValue>;
	fetchRecipients: () => Promise<void>;
	fetchAccountingData: (
		statuses: AccountingStatus[],
		startDate: string,
		endDate: string,
		recipient: string
	) => Promise<void>;
	markInvoiceAsPaid: (invoiceId: number) => Promise<void>;
	cancelInvoice: (invoiceId: number) => Promise<void>;
	refreshSingleAccountingData: (invoiceId: number) => Promise<void>;
}

export const createAccountingStore: StateCreator<AccountingSlice> = (
	set,
	get
) => ({
	accountingData: [],
	recipientsLookup: {},
	refreshSingleAccountingData: async (invoiceId) => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ACCOUNTING_VIEW)
				.select()
				.eq("invoice_id", invoiceId)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			set((state) => ({
				...state,
				accountingData: state.accountingData.map((invoice) => {
					if (invoice.invoice_id === invoiceId) {
						return data[0];
					}
					return invoice;
				}),
			}));
		}
	},
	markInvoiceAsPaid: async (invoiceId) => {
		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.INVOICES)
				.update({ is_marked_as_paid: true })
				.match({ id: invoiceId })
		);

		if (error) {
			Logger.error(error);
			return;
		}

		await get().refreshSingleAccountingData(invoiceId);
	},

	cancelInvoice: async (invoiceId) => {
		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.INVOICES)
				.update({ is_cancelled: true })
				.match({ id: invoiceId })
		);

		if (error) {
			Logger.error(error);
			return;
		}

		await get().refreshSingleAccountingData(invoiceId);
	},
	fetchAccountingData: async (
		statuses,
		startDate,
		endDate,
		selectedRecipient
	) => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return Logger.error("Organization ID not found");
		let query = supabase
			.from(SupabaseTableEnum.ACCOUNTING_VIEW)
			.select()
			.eq("organization_id", organizationId)
			.order("invoice_date", { ascending: false })
			.gte("invoice_date", startDate)
			.lte("invoice_date", endDate);

		if (statuses.length > 0) {
			const filter = statuses
				.map((status) => `status.ilike.${status}%`)
				.join(",");
			query = query.or(filter);
		}
		await get().fetchRecipients();

		const recipient = get().recipientsLookup[selectedRecipient];

		if (selectedRecipient && recipient) {
			if (recipient.type === "client") {
				query.eq("client_id", selectedRecipient);
			} else if (recipient.type === "guarantor") {
				query.eq("guarantor_id", selectedRecipient);
			}
		}

		const { data, error } = await handleDatabaseOperation(query);

		if (error) {
			Logger.error(error);
			return;
		}

		set({ accountingData: data ?? [] });
	},
	fetchRecipients: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return Logger.error("Organization ID not found");

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ACCOUNTING_RECIPIENTS)
				.select("*")
				.eq("organization_id", organizationId)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			const recipientsLookup = data.reduce(
				(
					acc: Record<string, RecipientsLookupValue>,
					curr: {
						recipient_type: "c" | "g";
						recipient_id: string;
						recipient_name: string;
					}
				) => {
					acc[curr.recipient_id] = {
						type:
							curr.recipient_type === "c"
								? RecipientTypeEnum.CLIENT
								: RecipientTypeEnum.GUARANTOR,
						name: curr.recipient_name,
					};
					return acc;
				},
				{}
			);

			set({ recipientsLookup: recipientsLookup });
		}
	},
});

import { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../../../../lib/logger/Logger";
import {
	BankAccountEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { useCentralStore } from "../../store/Central";

type BankAccountsType = {
	bankAccounts: BankAccountEntityType[];
	bankAccountsLookup: {
		[key: string]: BankAccountEntityType;
	};
};

const BankAccounts = createContext<{
	bankAccounts: BankAccountsType;
	setBankAccounts: React.Dispatch<React.SetStateAction<BankAccountsType>>;
}>({
	bankAccounts: {
		bankAccounts: [],
		bankAccountsLookup: {},
	},
	setBankAccounts: () => {},
});

export const BankAccountsProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: any) => {
	const [bankAccounts, setBankAccounts] = useState<BankAccountsType>({
		bankAccounts: [],
		bankAccountsLookup: {},
	});
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	useEffect(() => {
		Logger.log("BankAccountsProvider: useEffect", organizationId);
		if (organizationId) {
			Logger.log(
				"BankAccountsProvider: useEffect, fetching",
				organizationId
			);
			fetchBankAccounts(organizationId);
		}
	}, [organizationId]);

	const fetchBankAccounts = async (organizationId_: string) => {
		const { success, data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.BANK_ACCOUNTS,
			{
				column: "organization_id",
				value: organizationId_,
			}
		);
		Logger.log(
			"BankAccountsProvider: fetchBankAccounts",
			success,
			data,
			error
		);
		if (success && data) {
			Logger.log("fetchBankAccounts", data);
			const bankAccountsLookup = data.reduce(
				(acc: any, bankAccount: BankAccountEntityType) => ({
					...acc,
					[bankAccount.id]: bankAccount,
				}),
				{}
			);
			Logger.log("bankAccountsLookup", bankAccountsLookup);
			setBankAccounts({
				bankAccounts: data,
				bankAccountsLookup: bankAccountsLookup,
			});
		}
	};

	return (
		<BankAccounts.Provider value={{ bankAccounts, setBankAccounts }}>
			{children}
		</BankAccounts.Provider>
	);
};

/**
 * useBankAccountsContext is a custom hook that simplifies the access to the bankAccounts context
 * @example const { bankAccounts: { bankAccountsLookup } }: any = useBankAccountsContext();
 */
export const useBankAccountsContext = () => {
	const context = useContext(BankAccounts);
	if (!context) {
		throw new Error(
			"useBankAccountsContext must be used within a BankAccountsProvider. Wrap a parent component in <BankAccountsProvider> to fix this error."
		);
	}
	return context;
};

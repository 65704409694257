import React, { useEffect, useState } from "react";
import { TextField, List, ListItemText, ListItemButton } from "@mui/material";
import { supabase } from "../../../lib/supabase";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";
import { usePrivacyContext } from "../context/PrivacyContext";
import {
	createSecurePatientName,
	handleDatabaseOperation,
} from "../lib/utils/utils-functions";
import { useToast } from "../context/ToastContext";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../store/Central";

export type SearchabelClickableListSearchResult = {
	first_name: string;
	last_name: string;
	code: string;
	moidified_at: string;
	id: string;
};

// defaults to fetch by organizationId if no customFetch is provided
interface SearchableClickableListProps {
	supabaseTable: string;
	onClick: (entry: SearchabelClickableListSearchResult) => void;
	customFetch?: { column: string; value: string } | null;
}

export const SearchableClickableList: React.FC<
	SearchableClickableListProps
> = ({ onClick, supabaseTable, customFetch = null }) => {
	const [searchValue, setSearchValue] = useState("");
	const [data, setData] = useState<SearchabelClickableListSearchResult[]>([]);
	const { showToast } = useToast();

	const organizationId = useCentralStore((state) => state.organization?.id);

	const fetchBy = customFetch
		? customFetch
		: { column: "organization_id", value: organizationId };

	useEffect(() => {
		fetchList();
	}, []);

	const fetchList = async () => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.from(supabaseTable as any)
				.select("*")
				.order("modified_at", { ascending: false })
				.eq(fetchBy.column, fetchBy.value)
		);

		if (error) {
			showToast("Error beim Aufrufen der Daten", "error");
			Logger.error("Error fetching data", error);
		}

		setData(data);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const filterResults = (
		results: SearchabelClickableListSearchResult[],
		query: string
	) => {
		const seenCodes = new Set<string>();

		return results.filter((result) => {
			const matchesQuery =
				(result.first_name &&
					result.first_name
						.toLowerCase()
						.includes(query.toLowerCase())) ||
				(result.last_name &&
					result.last_name
						.toLowerCase()
						.includes(query.toLowerCase())) ||
				(result.code &&
					result.code.toLowerCase().includes(query.toLowerCase()));

			const isDuplicate = seenCodes.has(result.id);

			if (matchesQuery && !isDuplicate) {
				seenCodes.add(result.id);
				return true;
			}
			return false;
		});
	};

	const { isPrivacyOn } = usePrivacyContext();

	return (
		<div>
			{data && (
				<div>
					<List>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginRight: "1rem",
								marginLeft: "1rem",
							}}
						>
							<TextField
								label="Search"
								value={searchValue || ""}
								onChange={handleChange}
								fullWidth
								variant="standard"
							/>
						</div>
						{filterResults(data, searchValue).map((entry) => {
							let name = "";
							if (
								supabaseTable === SupabaseTableEnum.PATIENTS &&
								isPrivacyOn
							) {
								name = createSecurePatientName(
									entry.first_name,
									entry.last_name,
									isPrivacyOn
								);
							} else {
								name = `${entry.first_name} ${entry.last_name}`;
							}
							return (
								<ListItemButton
									key={entry.id}
									onClick={() => onClick(entry)}
								>
									<ListItemText
										primary={name}
										secondary={
											entry.code && `Code: ${entry.code}`
										}
									/>
								</ListItemButton>
							);
						})}
					</List>
				</div>
			)}
		</div>
	);
};

import { useFileOptions } from "../../hooks/useFileOptions";
import { FileWithShareWithUrl, useStorage } from "../../hooks/useStorage";
import { SimpleMenu } from "../simple-menu.component";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import PrintIcon from "@mui/icons-material/Print";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { ShareDialog } from "../sharing/share-dialog/share-dialog.component";
import {
	ActivityTypeEnum,
	isShared,
} from "../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../lib/logger/Logger";
import { useShared } from "../../hooks/useActivities";
import { useCentralStore } from "../../store/Central";
import { useSendEmail } from "../../hooks/useSendEmail";
import { PractitionerOptionType } from "../sharing/share-dialog/practitioner-emails-autocomplete";
import { useToast } from "../../context/ToastContext";
import { showNotification } from "../../store/Central/selectors";

/**
 * FileOptions - provides delete, download, sharing options for a file
 * @param file - file object to display options for
 */
export const FileOptions: React.FC<{
	file: FileWithShareWithUrl;
	setFile: (file: FileWithShareWithUrl) => void;
	clientId: string;
	onDeleteStart: () => void;
	onDeleteSuccess: () => void;
	variant?: "menu" | "button";
}> = ({ file, setFile, onDeleteStart, onDeleteSuccess, variant = "menu" }) => {
	// const { jobId: jobIdString } = useParams<{ jobId: string }>(); // for assigning notifications // Not being used
	// const jobId = jobIdString ? parseInt(jobIdString) : null; // Not being used
	const { deleteFiles } = useStorage();
	const { addSharedFileActivity } = useShared();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const { showToast } = useToast();

	const handleDelete = async () => {
		if (!file || !file.path_name) {
			Logger.error("FileOptions: file or file.path_name is null");
			return;
		}

		onDeleteStart();
		const { success, data, error } = await deleteFiles([file.path_name]);
		if (success) {
			Logger.log(data);
			onDeleteSuccess();
		}
		if (error) {
			Logger.log(error);
		}
	};

	const { downloadFile } = useFileOptions();

	const handleDownload = () => {
		downloadFile(file);
	};

	const handlePrint = () => {
		// TODO: printFile(file);
	};

	const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

	const handleShare = async () => {
		if (!connectRelationshipId || !file.id) {
			Logger.error("No connect relationship id found or file id is null");
			return;
		}

		const { data: activityNewEntry, error: activityError } =
			await addSharedFileActivity(
				connectRelationshipId,
				file.id,
				ActivityTypeEnum.SHARED_DOCUMENT
			);
		if (activityError || !activityNewEntry) {
			Logger.error(activityError);
			showToast("Error when adding shared document", "error");
		}
	};

	const handleUnshare = () => {
		// TODO: reimplement unshare
	};

	const { sendSharedFileEmailTemplate } = useSendEmail();
	const handleNotify = async (
		practitioners: PractitionerOptionType[],
		message: string
	) => {
		if (isShared(file)) {
			Logger.error("FileOptions", file);
			return;
		}

		const { label: emailAddress } = practitioners[0];

		sendSharedFileEmailTemplate({
			emailAddress,
			fileName: file.file_name ?? "Kein Dateiname",
			link: "connect.dentdesk.ch",
			message,
		});

		setIsSharePopupOpen(false);
	};

	const menuItems = [
		{ label: "Löschen", value: "delete" },
		{ label: "Herunterladen", value: "download" },
		{ label: "Drucken", value: "print" },
	];

	if (connectRelationshipId) {
		menuItems.push({ label: "Teilen", value: "share" });
	}

	const shareDialog = (
		<ShareDialog
			open={isSharePopupOpen}
			onClose={() => setIsSharePopupOpen(false)}
			entityName={file?.file_name || "Datei"}
			shared={isShared(file)}
			onShare={handleShare}
			onUnshare={handleUnshare}
			onNotify={handleNotify}
		/>
	);

	if (variant === "button") {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				{shareDialog}
				{connectRelationshipId && (
					<IconButton onClick={() => setIsSharePopupOpen(true)}>
						<ShareIcon
							style={{
								color: "white",
								fontSize: "2rem",
							}}
						/>
					</IconButton>
				)}
				<IconButton onClick={handlePrint}>
					<PrintIcon
						style={{
							color: "white",
							fontSize: "2rem",
						}}
					/>
				</IconButton>
				<IconButton onClick={handleDownload}>
					<DownloadIcon
						style={{
							color: "white",
							fontSize: "2rem",
						}}
					/>
				</IconButton>
				<SimpleMenu
					buttonVariant="icon"
					buttonContent={
						<MoreVertIcon
							style={{
								color: "white",
								fontSize: "2rem",
							}}
						/>
					}
					menuItems={[{ label: "Löschen", value: "delete" }]}
					onSelect={(value) => {
						if (value === "delete") {
							handleDelete();
						}
					}}
				/>
			</div>
		);
	} else {
		return (
			<>
				{shareDialog}
				<SimpleMenu
					buttonVariant="icon"
					buttonContent={<MoreVertIcon />}
					menuItems={menuItems}
					onSelect={(value) => {
						if (value === "delete") {
							handleDelete();
						} else if (value === "download") {
							handleDownload();
						} else if (value === "share") {
							setIsSharePopupOpen(true);
						} else if (value === "print") {
							handlePrint();
						} else {
							showNotification({
								message: "Unbekannter Wert " + value,
								type: "error",
							});
						}
					}}
				/>
			</>
		);
	}
};

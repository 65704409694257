import {
	ClientEntityType,
	GuarantorEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { QRCode } from "@progress/kendo-react-barcodes";
import "../fonts.css";
import { useRef } from "react";
import { PdfJobItemsTable } from "../pdf-template-components/pdf-job-items-table";

export interface AuftragsblattTemplateProps {
	client: ClientEntityType | null;
	job: JobEntityType | null;
	jobDocument: JobDocumentEntityType | null;
	jobItems: JobItemEntityType[] | null;
	organization: OrganizationEntityType | null;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
	qrCodeUrl: string;
	clientAvatarUrl?: string;
}

export const AuftragsblattTemplate: React.FC<AuftragsblattTemplateProps> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	qrCodeUrl,
	clientAvatarUrl,
}) => {
	const image = useRef<HTMLImageElement | null>(null);
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						fontSize: "16px",
					}}
				>
					<p>
						<b>Auftrag:</b> {job.title} ({job.code})
					</p>
					<p>
						<b>Dokument:</b> {jobDocument.title}
					</p>
					<p>
						<b>Auftraggeber:</b> {client.first_name}{" "}
						{client.last_name} ({client.code})
					</p>
					{patient && (
						<p>
							<b>Patient:</b> {patient.first_name}{" "}
							{patient.last_name}
						</p>
					)}
					{guarantor && (
						<p>
							<b>Garant:</b> {guarantor.first_name}{" "}
							{guarantor.last_name}
						</p>
					)}
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<QRCode
						value={qrCodeUrl}
						errorCorrection="H"
						encoding="UTF_8"
						padding={0}
					/>
					{clientAvatarUrl && (
						<img ref={image} src={clientAvatarUrl} width="150px" />
					)}
				</div>
			</div>
			<div
				style={{
					paddingTop: "15mm",
				}}
			>
				{jobItems && job && (
					<PdfJobItemsTable
						jobDocument={jobDocument}
						jobItems={jobItems}
						organization={organization}
						showDrTax={false}
						excludePrices={true}
					/>
				)}
			</div>
		</PdfLayout>
	);
};

import React from "react";
import { EditorTools } from "@progress/kendo-react-editor";
import { useState } from "react";
import { useSendReply } from "../../../../../dentlab/src/hooks/useSendReply";
import { Button } from "@mui/material";
import { useShared } from "../../../../../dentlab/src/hooks/useActivities";
import { Logger } from "../../../../../lib/logger/Logger";
import { useToast } from "../../../../../dentlab/src/context/ToastContext";
import { useSendEmail } from "../../../../../dentlab/src/hooks/useSendEmail";
import { RichTextEditor } from "@mantine/rte";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

interface ConnectTextInputGeneralProps {
	emailAddresses?: string[];
	onSubmit?: () => void;
	muiTextFieldProps?: any;
}

/**
 * ConnectTextInputForMessage
 * @param connectRelationshipId
 * @param emailAddresses - If emailAddresses are provided, send an email to these addresses
 * @param onSubmit
 * @param muiTextFieldProps - Any additional props for the MUI TextField
 */
export const ConnectTextInputForMessage: React.FC<
	{
		connectRelationshipId: string;
	} & ConnectTextInputGeneralProps
> = (props) => {
	return <ConnectTextInput {...props} />;
};

/**
 * ConnectTextInputForReply
 * @param messageId
 * @param emailAddresses - If emailAddresses are provided, send an email to these addresses
 * @param onSubmit
 * @param muiTextFieldProps - Any additional props for the MUI TextField
 */
export const ConnectTextInputForReply: React.FC<
	{
		messageId: number;
	} & ConnectTextInputGeneralProps
> = (props) => {
	return <ConnectTextInput {...props} />;
};

const { Bold, Italic, Underline } = EditorTools;

/**
 * MessageOrReplyInput
 *
 * To send an activity of type MESSAGE_GENERAL, provide a connectRelationshipId
 * @param connectRelationshipId
 *
 * To send a reply, only provide a messageId
 * @param messageId If a messageId is provided, it is a reply
 *
 * Other props:
 * @param {string[]} emailAddresses If emailAddresses are provided, send an email to these addresses
 * @param {() => void} onSubmit
 * @param muiTextFieldProps - Any additional props for the MUI TextField
 *
 * Note: Right now this component is only for organizations of type "lab"
 * // TODO: Generalize for "lab" and "med" and make available in dentCONNECT
 */
const ConnectTextInput: React.FC<{
	connectRelationshipId?: string;
	messageId?: number;
	emailAddresses?: string[];
	onSubmit?: () => void;
	muiTextFieldProps?: any;
}> = ({
	connectRelationshipId,
	messageId,
	emailAddresses,
	onSubmit,
	muiTextFieldProps,
}) => {
	const { addMessageActivity } = useShared();
	const { sendReply } = useSendReply();
	const { sendSharedMessageEmailTemplate } = useSendEmail();

	const [text, setText] = useState<any>("");

	const handleSend = async () => {
		if (!text) return; // TODO: more checks for text

		if (messageId) {
			await sendReply(messageId, text);
		} else {
			sendMessage();
		}
		setText("");
		if (onSubmit) onSubmit();
	};

	const sendMessage = async () => {
		if (!connectRelationshipId) {
			Logger.error("Missing connectRelationshipId");
			showNotification({
				message:
					"Fehler beim Senden der Nachricht. Keine Verbindungsbeziehung gefunden.",
				type: "error",
			});
			return;
		}

		const { data: activity, error } = await addMessageActivity(
			connectRelationshipId,
			text
		);
		Logger.log("MessageOrReplyInput: sendMessage:", activity, error);
		if (error) {
			Logger.error("Error sending message", error);
			showNotification({
				message: "Fehler beim Senden der Nachricht",
				type: "error",
			});
			return;
		} else {
			showNotification({
				message: "Nachricht gesendet",
				type: "success",
			});
		}
		if (emailAddresses && activity) {
			emailAddresses.forEach((emailAddress: string) => {
				Logger.log("Sending email to", emailAddress);
				sendSharedMessageEmailTemplate({
					emailAddress,
					message: text,
					senderName: "dentCONNECT",
				});
			});
		}
	};

	let label;
	if (messageId) {
		label = "Antwort senden";
	} else {
		label = "Nachricht senden";
	}

	return (
		<div
			style={{
				backgroundColor: "white",
				display: "flex",
				flexDirection: "column",
				alignItems: "end",
				gap: "12px",
				justifyContent: "space-between",
				position: "sticky",
				zIndex: 1,
				bottom: 0,
				marginLeft: "3px",
				marginRight: "3px",
				paddingTop: "10px",
				paddingBottom: "3px",
			}}
		>
			<RichTextEditor
				style={{
					width: "100%",
					maxWidth: "350px",
				}}
				controls={[["italic", "underline", "bold"]]}
				value={text}
				onChange={(e) => setText(e)}
			/>
			<Button onClick={handleSend}>Senden</Button>
		</div>
	);
};

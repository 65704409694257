import { Box } from "@mui/material";
import { JobList } from "../../components/list-components/job-list.component";

export const ManageJobsPage: React.FC = () => {
  return (
    <Box sx={{ pt: "15px" }}>
      <JobList />
    </Box>
  );
};

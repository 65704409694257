import React from "react";
import { supabase } from "../../../lib/supabase/supabaseClient";
import { useSessionContext } from "../../../lib/contexts/SessionContext";
import { useProfileContext } from "../../../lib/contexts/ProfileContext";
import { Logger } from "../../../lib/logger/Logger";
import { TechnicianSignIn } from "./signin-components/technician-signin.component";
import { DentistSignIn } from "./signin-components/dentist-signin.component";
import { useDatabaseFunctionsWithPromise } from "../../../dentlab/src/hooks/useDatabase";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../../../dentlab/src/store/Central";
import { useCentralStore as useConnectCentralStore } from "../../../dentconnect/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

export const SignIn: React.FC<{
	isConnectLogin: boolean;
}> = ({ isConnectLogin }) => {
	const {
		setSession: legacySession,
		setLoading,
		loading,
	} = useSessionContext();
	const { fetchProfile } = useProfileContext();
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { initialize } = useCentralStore();
	const { initialize: connectInitialize } = useConnectCentralStore();
	const fetchOrganizationId = async (userId: string) => {
		const { data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.PROFILES,
			{
				column: "id",
				value: userId,
			}
		);

		return data[0].organization_id;
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		const formData: any = new FormData(event.currentTarget);

		try {
			const { data, error }: any = await supabase.auth.signInWithPassword(
				{
					email: formData.get("email") as string,
					password: formData.get("password") as string,
				}
			);

			if (data.session) {
				Logger.log("Set session after sign in");
				legacySession({
					userId: data.session.user.id,
					userEmail: data.session.user.email,
					isConnectUser: isConnectLogin,
				});
				await initialize();

				fetchProfile(data.session.user.id);
				const organizationId = await fetchOrganizationId(
					data.session.user.id
				);
			}
			if (error) throw error;
		} catch (error: any) {
			showNotification({
				message: "Falscher Benutzername oder Passwort",
				type: "error",
			});
		} finally {
			setLoading(false);
			Logger.log("Sign in complete");
		}
	};

	if (loading) {
		// TODO
		return <>Loading at SignIn...</>;
	}
	return (
		<>
			{isConnectLogin ? (
				<DentistSignIn
					onSignIn={async (data: any) => {
						await connectInitialize();
						legacySession({
							userId: data.session.user.id,
							userEmail: data.session.user.email,
							isConnectUser: isConnectLogin,
						});
						fetchProfile(data.session.user.id);
						const organizationId = await fetchOrganizationId(
							data.session.user.id
						);
					}}
				/>
			) : (
				<TechnicianSignIn onSubmit={handleSubmit} />
			)}
		</>
	);
};

import { useState } from "react";
import { ProfileButtonWithPopover } from "../../../components/button-with-popover/profile-button-with-popover.component";
import { EmployeeSelectDialog } from "../../../components/employee-select-dialog.component";
import {
	ProfileEntityType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import {
	useDatabaseFetch,
	useDatabaseFunctionsWithPromise,
} from "../../../hooks/useDatabase";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

export const EmployeeDetails = () => {
	const { job } = useJobStore((state) => ({
		job: state.job,
	}));

	const {
		data,
		loading: employeeLoading,
		error: employeeError,
		setData: setEmployees,
	} = useDatabaseFetch(SupabaseTableEnum.PROFILES, {
		column: "id",
		value: job?.employee_id,
	});
	const employee: ProfileEntityType = data?.[0];

	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();

	const [open, setOpen] = useState(false);

	const updateEmployee = async (employee: any) => {
		const { success, data, error } = await updateDataWithPromise(
			SupabaseTableEnum.JOBS,
			[{ id: job?.id, employee_id: employee.id }]
		);
		if (success) {
			setEmployees([employee]);
		}
		if (error) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
				type: "error",
			});
		}
	};

	return (
		<>
			<ProfileButtonWithPopover
				key="employee"
				loading={employeeLoading}
				name={{
					first_name: employee?.first_name || "",
					last_name: employee?.last_name || "",
				}}
				label="Zugewiesen"
				onClick={() => setOpen(true)}
				onAdd={() => setOpen(true)}
			/>
			<EmployeeSelectDialog
				open={open}
				onClose={(emp) => {
					updateEmployee(emp), setOpen(false);
				}}
				selectedValue={employee}
			/>
		</>
	);
};

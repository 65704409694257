import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useTeamProfilesContext } from "../context/single-entity-contexts/TeamProfilesContext";
import { Logger } from "../../../lib/logger/Logger";
import { ProfileEntityType } from "../../../lib/supabase/supabaseTypes";

// TODO: reconsider if the selected value should just be the id and the whole entity should be passed separately
export const EmployeeSelectDialog: React.FC<{
	open: boolean;
	selectedValue: ProfileEntityType;
	onClose: (value: ProfileEntityType) => void;
}> = ({ onClose, selectedValue, open }) => {
	const navigate = useNavigate();

	const {
		teamProfiles: { teamProfiles },
	} = useTeamProfilesContext();

	Logger.log("teamProfiles", teamProfiles);

	return (
		<Dialog onClose={() => onClose(selectedValue)} open={open}>
			<DialogTitle>Mitarbeiter auswählen</DialogTitle>
			<List sx={{ pt: 0 }}>
				{teamProfiles.map((emp: ProfileEntityType) => (
					<ListItem key={emp.id} disableGutters>
						<ListItemButton
							onClick={() => onClose(emp)}
							key={emp.id}
						>
							<ListItemAvatar>
								<Avatar
									sx={{
										bgcolor: blue[100],
										color: blue[600],
									}}
								>
									<PersonIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={emp.first_name} />
						</ListItemButton>
					</ListItem>
				))}
				<ListItem disableGutters>
					<ListItemButton
						autoFocus
						onClick={() => navigate("/user-management")}
					>
						<ListItemAvatar>
							<Avatar>
								<AddIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Account hinzufügen" />
					</ListItemButton>
				</ListItem>
			</List>
		</Dialog>
	);
};

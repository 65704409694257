import { StateCreator } from "zustand";
import { PrintersConfig, State } from "./types";
import { initialState } from "./utils";
import { Json, supabase } from "../../../../lib/supabase";
import { useCentralStore } from ".";
import { Logger } from "../../../../lib/logger/Logger";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { GuarantorEntityType } from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { ConfigurationColumns } from "../../types/enums";
import { useDesktopsStore } from "../Desktops";
import { showNotification } from "./selectors";
import { usePatientStore } from "../Patient";

export interface CentralSlice extends State {
	getSession: () => Promise<boolean>;
	// Get profile and get organization are separated to be able to fetch them separately
	getProfile: () => Promise<void>;
	initialize: () => Promise<void>;
	updateSearchConfig: (search_config_job_list: {
		statuses: Record<JobStatusEnum, number>;
	}) => Promise<void>;
	updatePrintersConfig: (printersConfig: PrintersConfig) => Promise<void>;
	getOrganization: () => Promise<void>;
	getTariffs: () => Promise<void>;
	getArticles: () => Promise<void>;
	getTariffsConfig: () => Promise<void>;
	getGuarantorLookup: () => Promise<void>;
	handleSignOut: () => Promise<void>;
	setMFADialog: (open: boolean) => void;
	verifyMFA: () => Promise<void>;
	setupMFA: (verifyCode: string, factorId: string) => Promise<void>;
}

export const createCentralStore: StateCreator<CentralSlice> = (set, get) => ({
	...initialState,
	initialize: async () => {
		const {
			initialized,
			getSession,
			getTariffs,
			getArticles,
			getClients,
			getTariffsConfig,
			getProfile,
			getOrganization,
			getGuarantorLookup,
			fetchGridConfig,
			verifyMFA,
			mfaAuthenticated,
		} = useCentralStore.getState();

		const { fetchPatients } = usePatientStore.getState();

		// If the initialize function is already run before
		// prevent it from running again and causing rerenders
		if (initialized) {
			Logger.warn("Central store already initialized");
			return;
		}

		const loggedIn = await getSession();

		// Only continue if the user is logged in
		if (!loggedIn) {
			Logger.warn("[Sign in] User is not logged in");
			return;
		}
		await verifyMFA();

		if (!mfaAuthenticated) {
			Logger.error("MFA not authenticated");
			return;
		}
		Logger.log("[Sign in] User is logged in", loggedIn);

		await getProfile();
		await getOrganization();
		await getTariffs();
		await getArticles();
		await getClients();
		await getTariffsConfig();
		await getGuarantorLookup();
		await fetchGridConfig();
		await fetchPatients();
		await useDesktopsStore
			.getState()
			.fetchDesktops(get().organization?.id ?? "");
		set({ initialized: true });
	},
	getSession: async () => {
		Logger.log("[Sign in] Retrieving session");
		try {
			const { data } = await supabase.auth.getSession();

			if (!data || !data.session) {
				Logger.error("No session found");
				return false;
			}

			const { user: userInformation } = data.session;
			const {
				id: userId,
				email: userEmail,
				user_metadata: userMetaData,
				app_metadata: appMetaData,
			} = userInformation;
			const { is_connect_user: isConnectUser } = userMetaData;
			const { role } = appMetaData;

			set({
				userId,
				userEmail,
				isConnectUser: isConnectUser || false,
				role,
			});

			return true;
		} catch (error) {
			Logger.error("Error retrieving session:", error);
			return false;
		}
	},
	getProfile: async () => {
		Logger.log("[Sign in] Getting profile");
		const { userId } = get();
		if (!userId) {
			Logger.error("[Sign in] User ID is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select()
			.eq("id", userId);

		if (error) {
			Logger.error("[Sign in] Error getting profile", error);
			await get().handleSignOut();
			return;
		}

		if (data.length === 0) {
			Logger.error("[Sign in] No profile found");
			await get().handleSignOut();
			return;
		}

		const profile = data[0];
		Logger.log("[Sign in] Profile", profile);
		set({
			profile,
		});
		if (profile?.search_config_job_list) {
			set({
				searchConfig: profile.search_config_job_list as {
					statuses: Record<JobStatusEnum, number>;
				},
			});
		}
		if (profile?.printers_config) {
			set({
				printersConfig: profile.printers_config as PrintersConfig,
			});
		}
	},
	getOrganization: async () => {
		const profile = get().profile;
		if (!profile || !profile.organization_id) return;

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ORGANIZATIONS)
				.select()
				.eq("id", profile?.organization_id)
		);

		if (error) {
			Logger.error(error);
			return;
		}
		if (data) {
			set({
				organization: data[0],
			});
		}
	},
	updateSearchConfig: async (searchConfigJobList: {
		statuses: Record<JobStatusEnum, number>;
	}) => {
		const userId = useCentralStore.getState().userId;
		if (!userId) return Logger.error("Can't update search config");

		const { error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.update({
				search_config_job_list: searchConfigJobList,
			})
			.eq("id", userId);

		if (error) {
			Logger.error("Can't update search config");
			return;
		}

		set({
			searchConfig: searchConfigJobList,
		});
	},
	updatePrintersConfig: async (printersConfig: PrintersConfig) => {
		const userId = useCentralStore.getState().userId;
		if (!userId) {
			showNotification({
				message: "Fehler beim Aktualisieren der Drucker-Konfiguration",
				type: "error",
			});
			Logger.error("Can't update printers config");
			return;
		}

		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.PROFILES)
				.update({
					printers_config: printersConfig,
				})
				.eq("id", userId)
		);

		if (error) {
			showNotification({
				message: "Fehler beim Aktualisieren der Drucker-Konfiguration",
				type: "error",
			});
			Logger.error("Can't update printers config");
			return;
		}

		set({
			printersConfig,
		});
	},
	getTariffsConfig: async () => {
		const organizationId = get().organization?.id;
		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ORGANIZATIONS)
				.select(ConfigurationColumns.TARIFFS)
				.eq("id", organizationId)
		);

		if (error) {
			Logger.error(error);
			return;
		}
		const tariffsConfig = data?.[0]?.[ConfigurationColumns.TARIFFS];
		if (!tariffsConfig) {
			Logger.error("Tariffs config is not set");
			return;
		}

		set({
			tariffsConfig,
		});
	},
	getTariffs: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set for tariffs");
			return;
		}
		const { data: defaultData, error } = await supabase
			.from(SupabaseTableEnum.TARIFFS_DEFAULT)
			.select("*");

		if (error) {
			Logger.error(error);
			return;
		}

		const { data: userTariffs, error: userTariffsError } = await supabase
			.from(SupabaseTableEnum.TARIFFS)
			.select("*")
			.eq("organization_id", organizationId);

		if (userTariffsError) {
			Logger.error(userTariffsError);
			return;
		}

		set({
			tariffs: {
				...userTariffs.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code] = curr;
					return acc;
				}, {}),
				...defaultData.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code] = curr;
					return acc;
				}, {}),
			},
		});
	},
	getArticles: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}
		const { data: defaultArticles, error } = await supabase
			.from(SupabaseTableEnum.ARTICLES_DEFAULT)
			.select("*");

		if (error) {
			Logger.error(error);
			return;
		}

		const { data: userArticles, error: userArticlesError } = await supabase
			.from(SupabaseTableEnum.ARTICLES)
			.select("*")
			.eq("organization_id", organizationId);

		if (userArticlesError) {
			Logger.error(userArticlesError);
			return;
		}

		set({
			articles: {
				...userArticles.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code] = curr;
					return acc;
				}, {}),
				...defaultArticles.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code] = curr;
					return acc;
				}, {}),
			},
		});
	},

	getGuarantorLookup: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.GUARANTORS)
				.select("*")
				.eq("organization_id", organizationId)
				.order("code", { ascending: true })
		);

		if (error) {
			Logger.error(error);
			return;
		}

		const guarantorLookup = data?.reduce(
			(
				acc: Record<string, GuarantorEntityType>,
				curr: GuarantorEntityType
			) => {
				acc[curr.id] = curr;
				return acc;
			},
			{}
		);
		set({
			guarantorLookup,
		});
	},

	handleSignOut: async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) throw error;

			set(initialState);

			window.location.replace("/");
		} catch (error) {
			Logger.error("Error signing out:", error);
			showNotification({
				message: "Fehler beim Abmelden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
		}
	},
	setMFADialog: (open: boolean) => {
		set({
			mfaDialogOpen: open,
		});
	},
	verifyMFA: async () => {
		const { data, error } =
			await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
		if (error) {
			Logger.error(error);
			return;
		}

		if (data.nextLevel === "aal2" && data.nextLevel !== data.currentLevel) {
			set({
				mfaEnrolled: true,
				mfaAuthenticated: false,
			});
		} else if (
			data.nextLevel === "aal1" &&
			data.nextLevel === data.currentLevel
		) {
			set({
				mfaEnrolled: false,
				mfaAuthenticated: true,
			});
		} else if (
			data.nextLevel === "aal2" &&
			data.nextLevel === data.currentLevel
		) {
			set({
				mfaEnrolled: true,
				mfaAuthenticated: true,
			});
		}
		set({
			mfaVerified: true,
		});
	},
	setupMFA: async (verifyCode: string, factorId: string) => {
		const challenge = await supabase.auth.mfa.challenge({ factorId });
		if (challenge.error) {
			throw challenge.error;
		}

		const challengeId = challenge.data.id;

		const verify = await supabase.auth.mfa.verify({
			factorId,
			challengeId,
			code: verifyCode,
		});
		if (verify.error) {
			throw verify.error;
		}
		set({
			mfaEnrolled: true,
		});
		showNotification({
			message: "MFA erfolgreich eingerichtet",
			type: "success",
		});
	},
});

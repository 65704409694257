import { z } from "zod";
import {
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { CompleteJobDocumentInformation } from "../actions/useExportActions";
import { Logger } from "@/lib/logger/Logger";
import { XmlEndpointsEnum } from "./useExportXml";
import axios from "axios";
import { environment } from "../../lib/utils/environment";
import {
	validateCantonAbbreviation,
	validatePhone,
} from "../useForm/form-field-validation-functions";
import { getCantonFromPostalCode } from "../../lib/utils/utils-functions";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import {
	ProductionCountriesEnum,
	productionCountriesLookup,
} from "../../lib/constants/productionCountries";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";

function formatDateForSumexApi(date: string): string {
	const ATRDate = new Date(date);
	return ATRDate.toISOString().replace(".000Z", "");
}

function formatTimestampzForSumexApi(timestampz: string): string {
	return timestampz?.split(".")[0];
}

export const DocumentFieldsForAPI = z.object({
	/**
	 * We do not use ATRTPW (which sets the taxpunktwert) on the API side. This is fine because
	 * every job item has its own TPW, which we can safely assume because the tp_value column is not nullable.
	 * Having tp_values be not nullable is ok, because there are always upstream entities to set the tp_value even if the
	 * job item itself does not have one set (client tp_value, organization tp_value)
	 */
	ip: z.object({
		LABCanton: z.string().refine((val) => validateCantonAbbreviation(val), {
			message: "Die Postleitzahl der Organisation ist ungültig",
		}),
		LABCompany: z
			.string()
			.min(1, { message: "Name der Organisation benötigt." }),
		LABName: z
			.string()
			.min(1, { message: "Name der Organisation benötigt." }),
		LABStreet: z.string().default(""),
		LABCity: z
			.string()
			.min(1, { message: "Stadt der Organisation benötigt." }),
		LABPhone: z.string().default(""),
		LABEmail: z.string().default(""),
		LABVATNumber: z.string().default(""),
		LABGLN: z.string().default(""),
		LABPLZ: z.string(),
		ATRCode: z.string(),
		ATRNote: z.string(),
		ATRVAT: z.number(),
		ATRProduction: z.string(),
		ATRDate: z.string(),
		ATRDiscount1: z.number(),
		ATRDiscount2: z.number(),
		PATCode: z.string().min(1, { message: "Patientencode benötigt." }),
		PATSalutation: z.string().default(""),
		PATFirstName: z
			.string({
				invalid_type_error: "Vorname des Patienten benötigt.",
			})
			.min(1, { message: "Vorname des Patienten benötigt." }),
		PATLastName: z
			.string({
				invalid_type_error: "Nachname des Patienten benötigt.",
			})
			.min(1, { message: "Nachname des Patienten benötigt." }),
		PATBirthDate: z.string(),
		PATProvider: z
			.string()
			.nullable()
			.transform((val) => val ?? ""),
		KUNCode: z.string().min(1, { message: "Auftraggebercode benötigt." }),
		KUNSalutation: z
			.string()
			.min(1, { message: "Anrede des Auftraggebers wird benötigt." }),
		KUNFirstName: z
			.string()
			.min(1, { message: "Vorname des Auftraggebers wird benötigt." }),
		KUNLastName: z
			.string()
			.min(1, { message: "Nachname des Auftraggebers wird benötigt." }),
		KUNStreet: z
			.string()
			.min(1, { message: "Straße des Kunden wird benötigt." }),
		KUNCity: z
			.string()
			.min(1, { message: "Stadt des Kunden wird benötigt." }),
		KUNPhone: z
			.string()
			.nullable()
			.refine((val) => val === null || validatePhone(val), {
				message: "Die Telefonnummer ist ungültig",
			})
			.default(""),
		KUNEmail: z.string().default(""),
		KUNPLZ: z.string(),
	}),
	documentTypes: z.array(
		z.object({
			code: z.string(),
			description: z.string(),
			quantity: z.number(),
			Date: z.string(),
			price: z.number(),
			TPW: z.number(),
			Art: z.number(),
		})
	),
});

interface FormatDocumentPropsForApiProps {
	documentProps: CompleteJobDocumentInformation & {
		patient: PatientEntityType;
		organization: OrganizationEntityType;
	};
	documentType:
		| JobDocumentTypeEnum.Quotation
		| JobDocumentTypeEnum.DeliveryNote;
}

export const formatDocumentPropsForApi = (
	props: FormatDocumentPropsForApiProps
): {
	success: boolean;
	data: null | z.infer<typeof DocumentFieldsForAPI>;
	error: string | null;
} => {
	const {
		documentProps: {
			organization,
			patient,
			client,
			job,
			jobDocument,
			jobItems,
		},
		documentType,
	} = props;

	if (
		jobItems.find((item) => item.type == JobItemTypeEnum.ARTICLE_FIXED_RATE)
	) {
		return {
			success: false,
			data: null,
			error: "Artikel vom Typ Arbeitspauschale wurden übersprungen. Versicherer akzeptieren keine undefinierten Aufwändungen.",
		};
	}

	const data = {
		ip: {
			LABCanton: organization.postal_code
				? getCantonFromPostalCode(Number(organization.postal_code))
				: "",
			LABCompany: organization.name,
			LABName: organization.name,
			LABStreet: organization.street,
			LABCity: organization.city,
			LABPhone: organization.phone_and_fax,
			LABEmail: organization.email,

			// LABVATNumber is only used for invoice requests (deliery notes)
			LABVATNumber: organization.mwst_number,
			LABGLN: organization.gln,
			LABPLZ: organization.postal_code,

			/**
			 * ATRCode
			 *
			 * For generalInvoiceRequest450.SetInvoice
			 * bstrRequestInvoiceID	The request ID is the main software's identification of the invoice ("Rechnungsnummer").
			 *
			 * For generalCreditRequest450.SetCreditObject
			 * The request credit ID is the main software's identification of the general credit ("Kostengutsprachenummer").
			 */
			ATRCode: job.code,

			/**
			 * ATRNote
			 *
			 * Only relevant for generalInvoiceRequest450.AddDiagnosis:
			 * bstrText	The textual description of the diagnosis as given in the corresponding diagnosis catalog defined by DiagnosisType .
			 */
			ATRNote:
				documentType === JobDocumentTypeEnum.DeliveryNote
					? job.title
					: "",
			ATRVAT: job.tax_rate,
			ATRProduction:
				job.prod_country_choice === "other"
					? job.prod_country_other
					: productionCountriesLookup[
							job.prod_country_choice as ProductionCountriesEnum
						],

			/**
			 * ATRDate
			 *
			 * For generalInvoiceRequest450.SetInvoice
			 * The request date is the main software's date of the invoice request ("Rechnungsdatum")
			 *
			 * For generalCreditRequest450.SetCreditObject
			 * The request credit date is the main software's date of the general credit ("Kostengutsprachedatum")
			 */
			ATRDate: formatDateForSumexApi(jobDocument.date),
			ATRDiscount1: jobDocument.discount_work,
			ATRDiscount2: jobDocument.discount_material,
			PATCode: patient.code,
			PATSalutation: patient.title,
			PATFirstName: patient.first_name,
			PATLastName: patient.last_name,
			PATBirthDate: patient.birth_date
				? formatDateForSumexApi(patient.birth_date)
				: "",
			PATProvider: patient.insurance,
			KUNCode: client.code,
			KUNSalutation: client.title,
			KUNFirstName: client.first_name,
			KUNLastName: client.last_name,
			KUNStreet: client.street,
			KUNCity: client.city,
			KUNPhone:
				client.phone_business ??
				client.phone_mobile ??
				client.phone_personal ??
				"",
			KUNEmail: client.email,
			KUNPLZ: client.postal_code,
		},
		documentTypes: jobItems.map((item) => ({
			/**
			 * When the job item is of type tariff, the standard tariff code can be used.
			 * For articles, the code is used only internally and for the insurance the code is derived from the cluster.
			 */
			code:
				item.type === JobItemTypeEnum.TARIFF
					? item.code
					: item.cluster?.concat(".0"),
			description: item.description,
			quantity: item.quantity,
			Date: formatTimestampzForSumexApi(item.modified_at),
			price: item.price,
			TPW: item.tp_value,
			Art: item.type, // 0 = tariff, 1-5 different article types
		})),
	};

	const validatedData = DocumentFieldsForAPI.safeParse(data);

	if (!validatedData.success) {
		Logger.error("Error validating data for XML", {
			error: validatedData.error,
		});
		return {
			success: false,
			data: null,
			error: "Fehler beim Erstellen des Dokuments (XML Export)",
		};
	}

	return {
		success: true,
		data: validatedData.data,
		error: null,
	};
};

export const postDataToApi = async ({
	endpoint,
	data,
}: {
	endpoint: XmlEndpointsEnum;
	data: z.infer<typeof DocumentFieldsForAPI>;
}) => {
	return await axios({
		method: "POST",
		url: `${environment.VITE_XML_MICROSERVICE_URL}/${endpoint}`,
		data,
	});
};

import {
	Dialog,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { ClientSelect } from "../../../forms/form-input-components/selects/client.select";
import { useState } from "react";
import {
	PractitionerEmailsAutocomplete,
	PractitionerOptionType,
} from "./practitioner-emails-autocomplete";
import { useCentralStore } from "../../../../../dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

interface ShareDialogProps {
	shared: boolean;
	entityName: string | null;
	onShare: () => void;
	onUnshare: () => void;
	onNotify: (
		practitioners: PractitionerOptionType[],
		message: string
	) => void;
	open: boolean;
	onClose: () => void;
}

/**
 * Note: Can only be used within ClientContext
 * @param shared - whether the entity is shared or not, parent should use [patient/job/file].connect_relationship_id to determine this
 * @param entityName - name of the entity to be shared, e.g. "Patient", "Auftrag", "Datei"
 * @param onShare - called when entity is shared
 * @param onUnshare - called when entity is unshared
 * @param onNotify - called when notification is sent
 * @param open - whether the dialog is open or not
 * @param onClose - called when dialog is closed
 */
export const ShareDialog: React.FC<ShareDialogProps> = (props) => {
	const { open, onClose, shared } = props;
	return (
		<Dialog open={open} onClose={onClose}>
			<div
				style={{
					position: "relative",
					width: "30rem",
					height: "fit-content",
					minHeight: "20rem",
				}}
			>
				{shared ? (
					<ShareDialogScreenShared {...props} />
				) : (
					<ShareDialogScreenNotShared {...props} />
				)}
			</div>
		</Dialog>
	);
};

// if shared
// handles onUnshare, onNotify
export const ShareDialogScreenShared: React.FC<ShareDialogProps> = (props) => {
	const { client } = useCentralStore((state) => ({
		client: state.client,
	}));
	const [notificationText, setNotificationText] = useState("");
	const { onUnshare, onNotify } = props;
	const [selectedPractitioners, setSelectedPractitioners] = useState<
		PractitionerOptionType[]
	>([]);

	const handleNotify = () => {
		if (selectedPractitioners) {
			onNotify(selectedPractitioners, notificationText);
		}
	};

	return (
		<>
			<div
				style={{
					height: "3rem",
					backgroundColor: "green",
					position: "relative",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					minHeight: "6rem",
					paddingTop: "1rem",
					paddingBottom: "1rem",
				}}
			>
				<div>
					<CheckCircleIcon
						sx={{
							color: "white",
							fontSize: "2rem",
						}}
					/>
				</div>
				<Typography variant="h6">
					Geteilt mit {`${client?.first_name} ${client?.last_name}`}
				</Typography>
				<p
					style={{
						cursor: "pointer",
						fontSize: "0.8rem",
					}}
					onClick={onUnshare}
				>
					Teilen aufheben
				</p>
			</div>
			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					paddingBottom: "3rem",
					paddingTop: "1rem",
				}}
			>
				<div>
					<Typography variant="h6">
						Personen per Email benachrichtigen
					</Typography>
					<PractitionerEmailsAutocomplete
						selectedPractitioners={selectedPractitioners}
						setSelectedPractitioners={setSelectedPractitioners}
					/>
					<>
						<div
							style={{
								paddingTop: "1rem",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<TextField
								label="Nachricht"
								multiline
								rows={4}
								defaultValue=""
								onChange={(event) => {
									setNotificationText(event.target.value);
								}}
							/>
						</div>
						<DialogActions>
							<Button
								onClick={handleNotify}
								sx={{
									position: "absolute",
									right: "1rem",
									bottom: "1rem",
								}}
							>
								Bestätigen
							</Button>
						</DialogActions>
					</>
				</div>
			</DialogContent>
		</>
	);
};

// if not shared
// handles onShare
export const ShareDialogScreenNotShared: React.FC<ShareDialogProps> = (
	props
) => {
	const { entityName, onShare } = props;
	const { client } = useCentralStore((state) => ({
		client: state.client,
	}));
	return (
		<>
			<DialogTitle variant="h5">{entityName} freigeben</DialogTitle>
			<p
				style={{
					fontSize: "0.9rem",
					marginTop: "0.5rem",
					marginBottom: "0.5rem",
					marginLeft: "1.6rem",
					marginRight: "1.6rem",
				}}
			>
				Alle Behandler, die dem Auftraggeber zugeordnet sind, können
				über ihren Account sicher auf den Inhalt zugreifen.{" "}
			</p>
			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					paddingBottom: "3rem",
					paddingTop: "1rem",
				}}
			>
				<ClientSelect
					onChange={() => {}}
					value={client?.id}
					disabled={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onShare}
					sx={{
						position: "absolute",
						right: "1rem",
						bottom: "1rem",
					}}
				>
					Bestätigen
				</Button>
			</DialogActions>
		</>
	);
};

import { CompanyInformationForm } from "../../forms/form-components/company-information.form";
import { ManageBankAccountsTab } from "./tabs/manage-bank-accounts-tab/manage-bank-accounts.tab";
import { LargeDialogFrameWithTabs } from "../../components/dialog-components/dialog-frames/large-dialog-with-tabs/large-dialog-frame-with-tabs.component";
import { TariffsConfigurationForm } from "../../forms/form-components/configuration/tariffs-configuration.form";
import { ManageGuarantorsTab } from "./tabs/manage-guarantors-tab/manage-guarantors-tab.component";
import { ManageTariffsTab } from "./tabs/manage-tariffs-tab/manage-tariffs.tab";
import { ManageArticlesTab } from "./tabs/manage-articles-tab/manage-articles.tab";
import { DialogPageBase } from "../../components/dialog-components/dialog-pages/dialog-page-base/dialog-page-base.component";
import { CompanyAccountingInformation } from "../../forms/form-components/company-accounting-information.component";
import { CompanyMahnungswesenInformation } from "../../forms/form-components/company-mahnungswesen-information.component";
import { ManageTablesTab } from "./tabs/manage-tables-tab/manage-tables.tab";
import { ManagePrintersTab } from "./tabs/manage-printers-tab/manage-printers.tab";

export const OrganizationSettingsDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	const tabs = [
		{
			label: "Firmenangaben",
			component: (
				<DialogPageBase title="Firmenangaben" onClose={onClose}>
					<CompanyInformationForm />
				</DialogPageBase>
			),
			index: 0,
		},
		{
			label: "Rechnungswesen",
			component: (
				<DialogPageBase title="Rechnungswesen" onClose={onClose}>
					<CompanyAccountingInformation />
				</DialogPageBase>
			),
			index: 1,
		},
		{
			label: "Mahnungswesen",
			component: (
				<DialogPageBase title="Mahnungswesen" onClose={onClose}>
					<CompanyMahnungswesenInformation />
				</DialogPageBase>
			),
			index: 2,
		},
		{
			label: "Tarifwerte festlegen",
			component: (
				<DialogPageBase title="Tarife" onClose={onClose}>
					<TariffsConfigurationForm />
				</DialogPageBase>
			),
			index: 3,
		},
		{
			label: "Tarife verwalten",
			component: <ManageTariffsTab onClose={onClose} />,
			index: 4,
		},
		{
			label: "Artikel verwalten",
			component: <ManageArticlesTab onClose={onClose} />,
			index: 5,
		},
		{
			label: "Konten verwalten",
			component: <ManageBankAccountsTab onClose={onClose} />,
			index: 6,
		},
		{
			label: "Garanten verwalten",
			component: <ManageGuarantorsTab onClose={onClose} />,
			index: 7,
		},
		{
			label: "Tabelleneinstellungen",
			component: (
				<DialogPageBase onClose={onClose}>
					<ManageTablesTab onClose={onClose} />
				</DialogPageBase>
			),
			index: 8,
		},
		{
			label: "Meine Drucker",
			component: (
				<DialogPageBase onClose={onClose}>
					<ManagePrintersTab />
				</DialogPageBase>
			),
			index: 9,
		},
	];

	return (
		<LargeDialogFrameWithTabs
			id="organization-settings"
			open={open}
			onClose={onClose}
			tabs={tabs}
		/>
	);
};

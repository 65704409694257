import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { stringAvatar } from "../../../lib/utils/avatar-functions";
import { ClientEntityType } from "../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "@/dentlab/src/store/Central";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@lab/src/components/ui/command";
import { Typography } from "@/components/src/typography/typography.component";
import { RolesEnum } from "@/dentlab/src/types/enums";
import { GanttChart } from "lucide-react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";

export const ClientList: React.FC<{
	onClick: () => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	openForm: () => void;
}> = ({ onClick, open, setOpen, openForm }) => {
	const { clients } = useCentralStore((state) => ({
		clients: Object.values(state.clientsLookup),
	}));
	const navigate = useNavigate();

	const apps = [
		{
			key: "accounting",
			label: "Debitorenbuchhaltung",
			role: RolesEnum.ADMIN,
			path: "/accounting",
			icon: <CalculateIcon />,
		},
		{
			key: "statistics",
			label: "Statistik",
			role: RolesEnum.ADMIN,
			path: "/statistics",
			icon: <BarChartIcon />,
		},
		{
			key: "agenda",
			label: "Agenda",
			role: RolesEnum.MEMBER,
			path: "/agenda",
			icon: <GanttChart />,
		},
		{
			key: "monthly-invoices",
			label: "Sammelrechnungen",
			role: RolesEnum.ADMIN,
			path: "/monthly-invoices",
			icon: <ReceiptIcon />,
		},
		{
			key: "job-item-groups",
			label: "Jumbos/Vorlagen",
			role: RolesEnum.ADMIN,
			path: "/job-item-groups",
			icon: <WorkspacesIcon />,
		},
	];
	const { role } = useCentralStore((state) => ({
		role: state.role,
	}));

	return (
		<CommandDialog onOpenChange={setOpen} open={open}>
			<CommandInput placeholder="Auftraggeber suchen…" />{" "}
			<CommandList className="w-[640px] h-96 max-h-96">
				<CommandGroup heading="Aktionen">
					<CommandItem
						className="h-12 flex pl-5 gap-2 w-full items-center justify-start font-medium"
						onSelect={openForm}
					>
						<AddCircleIcon />
						<Typography variant="title-sm">
							Auftraggeber hinzufügen
						</Typography>
					</CommandItem>
				</CommandGroup>{" "}
				<CommandGroup heading="DentLab-Apps">
					{apps
						.filter((app) => app.role >= (role ?? 2))
						.map((app) => {
							return (
								<CommandItem
									className="flex gap-2 items-center"
									onSelect={() => {
										navigate(app.path);
										setOpen(false);
									}}
								>
									{app.icon}
									{app.label}
								</CommandItem>
							);
						})}
				</CommandGroup>
				<CommandEmpty>Keine Auftraggeber</CommandEmpty>{" "}
				<CommandGroup heading="Auftraggeber öffnen" className="w-full">
					{clients.map((client: ClientEntityType) => (
						<CommandItem
							onSelect={() => {
								onClick();
								navigate(`/client/${client.id}`);
							}}
							key={client.id}
							className="h-12"
						>
							<ClientListRow client={client} />
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};

const ClientListRow: React.FC<{
	client: ClientEntityType & { image_src?: string };
}> = ({ client }) => {
	const { sx, children } = stringAvatar(
		`${client.first_name} ${client.last_name}`
	);

	return (
		<div
			className="cursor-pointer flex flex-row w-full"
			data-testid="client-list-row"
		>
			<Avatar
				style={{
					borderRadius: "10px", // To match AppBarButton
				}}
				sx={{ width: "30px", height: "30px", fontSize: "16px", ...sx }}
				id={client.id}
			>
				{/* check if the client has a profile picture by seeing if the avatar_path exists and it has a url  */}
				{client.avatar_path &&
				client?.avatar_path?.split("/")[0] != "public" ? (
					<img
						src={client.image_src}
						alt="Client Avatar"
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				) : (
					children
				)}
			</Avatar>
			<div className="flex flex-row items-center pl-2 w-full gap-1">
				<Typography variant="title-sm">
					{client.title} {client.first_name} {client.last_name}
					{/* TODO: https://dentdesk.atlassian.net/browse/DW-895 */}
					<span
						style={{
							color: "grey",
							fontSize: "0.8rem",
						}}
					>
						{client.connect_relationship_id &&
							"(mit dentCONNECT verbunden)"}
					</span>
				</Typography>
				<Typography variant="caption">{client.code}</Typography>
			</div>
		</div>
	);
};

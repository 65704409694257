import {
	DatePicker,
	DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import "./custom-date-picker.css";
import { Translate } from "../translate/translate.component";

export const CustomDatePicker: React.FC<{
	value: Date | null | undefined;
	onChange: (value: DatePickerChangeEvent) => void;
}> = ({ value, onChange }) => {
	return (
		<Translate>
			<DatePicker
				value={value}
				onChange={onChange}
				size="small"
				fillMode="outline"
				// European format
				format="dd.MM.yyyy"
			/>
		</Translate>
	);
};

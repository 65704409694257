import { ExtendedTariffEntityType } from "@/dentlab/src/context/JobItemsContext";
import {
	ArticleEntityType,
	JobItemEntityType,
	TariffEntityType,
} from "@/lib/supabase/supabaseTypes";
import { Typography } from "@mui/material";
import { FC } from "react";

export const ItemListing: FC<{
	rows: ExtendedTariffEntityType[] | TariffEntityType[] | ArticleEntityType[];
	jobItems: JobItemEntityType[];
	handleClick:
		| ((
				e: React.MouseEvent<HTMLDivElement>,
				row: TariffEntityType | ArticleEntityType
		  ) => void)
		| ((
				e: React.MouseEvent<HTMLDivElement>,
				row: ExtendedTariffEntityType
		  ) => void);
}> = ({ rows, jobItems, handleClick }) => {
	return (
		<>
			{rows.map(
				(
					row:
						| TariffEntityType
						| ArticleEntityType
						| ExtendedTariffEntityType
				) => (
					<div
						key={row.id}
						data-testid="job-items-selection-list-item"
						className="list-item"
						style={{
							background: jobItems.find(
								(j) => j.code === row.code
							)
								? "var(--neutral-light)"
								: "inherit",
						}}
						// @ts-expect-error row has many possible types
						onClick={(e) => handleClick(e, row)}
					>
						<Typography variant="subtitle2">
							{(row as TariffEntityType).code_e || row.code}
						</Typography>
						<Typography variant="body2" noWrap>
							{row.description_de}
						</Typography>
						{
							// .custom property is added by mergePositions() but does not exist in the database
							(row as any).custom ? (
								<Typography
									variant="caption"
									sx={{
										border: "1px solid",
										borderRadius: "5px",
									}}
								>
									Eigener
								</Typography>
							) : null
						}
					</div>
				)
			)}
		</>
	);
};

import { Typography } from "@/components/src/typography/typography.component";
import { usePrivacyContext } from "@/dentlab/src/context/PrivacyContext";
import { createSecurePatientName } from "@/dentlab/src/lib/utils/utils-functions";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { PatientEntityType } from "@/lib/supabase/supabaseTypes";
import {
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandDialog,
} from "@lab/src/components/ui/command";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SetStateAction } from "react";

export const PatientDialog: React.FC<{
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	openNewPatientDialog: () => void;
	onPatientSelect: (patient: PatientEntityType) => void;
}> = ({ open, setOpen, openNewPatientDialog, onPatientSelect }) => {
	const { patients } = usePatientStore((state) => ({
		patients: Object.values(state.patientsLookup),
	}));

	const { isPrivacyOn } = usePrivacyContext();

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput placeholder="Patient suchen…" />{" "}
			<CommandList className="h-96 max-h-96">
				<CommandGroup heading="Aktionen">
					<CommandItem
						onSelect={openNewPatientDialog}
						className="flex h-12 font-medium pl-5 gap-6 w-full items-center justify-start"
					>
						<AddCircleIcon />
						<Typography variant="title-sm">
							Neuer Patient
						</Typography>
					</CommandItem>
				</CommandGroup>{" "}
				<CommandEmpty>Keine Patienten</CommandEmpty>{" "}
				<CommandGroup heading="Patient öffnen" className="w-[500px]">
					{patients.map((patient) => {
						let name = "";
						if (isPrivacyOn) {
							name = createSecurePatientName(
								patient.first_name,
								patient.last_name,
								isPrivacyOn
							);
						} else {
							name = patient.first_name
								? `${patient.first_name} ${patient.last_name}`
								: "Ubenannter Patient";
						}
						return (
							<CommandItem
								key={patient.id}
								className="flex cursor-pointer w-full h-full flex-row gap-2 items-center"
								onSelect={() => {
									onPatientSelect(
										patient as PatientEntityType
									);
								}}
							>
								<div className="text-lg">{name}</div>
								<div className="text-gray-500">
									{patient.code}
								</div>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};

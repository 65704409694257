import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	IconButton,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PdfTemplateSelect } from "./pdf-template-select.component";
import { clientExample } from "../../constants/clientExample";
import { jobExample } from "../../constants/jobExample";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Document, Page } from "react-pdf";
import { jobDocumentExample } from "../../constants/jobDocumentExample";
import { usePdfTemplateConfigurationContext } from "../../context/PdfTemplateConfigurationContext";
import { CustomSelect } from "../../components/custom-select.component";
import { patientExample } from "../../constants/patientExample";
import { useSearchParams } from "react-router-dom";
import { PdfTemplateSingleConfigurations } from "./pdf-template-single-configurations.component";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import {
	PaymentSlipDataInput,
	formatPaymentSlipData,
} from "../../pdf-templates/pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { BankAccountSelect } from "../../forms/form-components/bank-account/input-components/bank-account.select";
import {
	BankAccountEntityType,
	ClientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { useBankAccountsContext } from "../../context/single-entity-contexts/BankAccountsContext";
import { bankAccountExample } from "../../constants/bankAccountExample";
import { ClientSelect } from "../../forms/form-input-components/selects/client.select";
import DownloadIcon from "@mui/icons-material/Download";
import CachedIcon from "@mui/icons-material/Cached";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	ExtendedJobDocument,
	ParsedJobItemFromView,
	calculateJobTotalsLegacy,
	calculateSammelrechnungRowsAndTotals,
} from "../../lib/utils/calculate";
import { CustomTextFieldWithSaveButton } from "../../forms/form-input-components/custom-input-components/custom-text-field-with-save-button.component";
import { PdfTemplateOptions } from "./pdf-selected-template.config";
import { jobItemExamples } from "../../constants/jobItemExample";
import { useCentralStore } from "../../store/Central";
import { PdfTemplateLogo } from "./pdf-template-logo.component";
import { Logger } from "@/lib/logger/Logger";
import { useStorage } from "../../hooks/useStorage";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";

export const PdfTemplateConfiguration: React.FC = () => {
	// Persist the selected template via ?template=...
	const [searchParams, setSearchParams] = useSearchParams();
	const { fileUrl } = useStorage();
	const template = searchParams.get("template");

	const {
		bankAccounts: { bankAccountsLookup, bankAccounts },
	} = useBankAccountsContext();
	const [selectedBankAccount, setSelectedBankAccount] =
		useState<BankAccountEntityType | null>(null);
	const bankAccount = selectedBankAccount ?? bankAccountExample;

	const { clientsLookup, clients, organization } = useCentralStore(
		(state) => ({
			clientsLookup: state.clientsLookup,
			clients: Object.values(state.clientsLookup),
			organization: state.organization,
		})
	);
	const [selectedClient, setSelectedClient] =
		useState<ClientEntityType | null>(null);
	const client = selectedClient ?? clientExample;

	useEffect(() => {
		if (bankAccounts.length > 0) {
			setSelectedBankAccount(bankAccounts[0]);
		}
	}, [bankAccounts]);

	useEffect(() => {
		if (clients.length > 0) {
			setSelectedClient(clients[0]);
		}
	}, []);

	const { pdfTemplateConfiguration, updatePdfTemplateConfiguration } =
		usePdfTemplateConfigurationContext();

	const [selectedPdfTemplate, setSelectedPdfTemplate] =
		useState<PdfTemplateType>(
			(template as PdfTemplateType) || PdfTemplateType.KOSTENVORANSCHLAG
		);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [numPages, setNumPages] = useState<number>(0);
	const [pdfBase64String, setPdfBase64String] = useState<string>("");
	const [isDocumentLoading, setIsDocumentLoading] = useState(true);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [logoUrl, setLogoUrl] = useState<string | null>(null);
	const [clientAvatarUrl, setClientAvatarUrl] = useState<string | null>(null);

	useEffect(() => {
		refreshPreviewPage();
	}, [selectedPdfTemplate, pdfTemplateConfiguration, bankAccount]);

	useEffect(() => {
		setIsPageLoading(true);
		setPageNumber(1);
	}, [selectedPdfTemplate]);

	useEffect(() => {
		const fetchImageUrl = async () => {
			setIsPageLoading(true);
			const generalConfig = pdfTemplateConfiguration?.general;
			if (!generalConfig?.logoPath) {
				setLogoUrl(null);
				return;
			}
			const image_src = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.createSignedUrl(
					`${organization?.id}/${generalConfig.logoPath}`,
					3600,
					{
						transform: {
							quality: 35,
						},
					}
				);

			if (!image_src.data) return;

			setLogoUrl(image_src.data.signedUrl);
			setIsPageLoading(false);
		};

		fetchImageUrl();
	}, [pdfTemplateConfiguration?.general?.logoPath]);

	useEffect(() => {
		const fetchClientAvatarUrl = async () => {
			let clientAvatarPath = client?.avatar_path;
			if (!clientAvatarPath) {
				setClientAvatarUrl(null);
				refreshPreviewPage();
				return;
			}

			//TODO(Eyob): Why is the client.avatar_path already signed?
			// https://dentdesk.atlassian.net/browse/DW-884
			if (clientAvatarPath.startsWith("http")) {
				clientAvatarPath = clientAvatarPath
					.split("supabase.co/storage/v1/render/image/sign/v1/")[1]
					.split("?")[0];
			}

			const imageSrc = await fileUrl(clientAvatarPath);

			Logger.info("clientAvatarPath", clientAvatarPath, imageSrc);

			if (!imageSrc.data) {
				setClientAvatarUrl(null);
				refreshPreviewPage();
				return;
			}

			setClientAvatarUrl(imageSrc.data.signedUrl);
		};

		fetchClientAvatarUrl();
	}, [client]);

	useEffect(() => {
		refreshPreviewPage();
	}, [clientAvatarUrl]);

	const handleSelectPdfTemplate = (value: PdfTemplateType) => {
		setIsDocumentLoading(true);
		setSelectedPdfTemplate(value);
		setSearchParams({ ...searchParams, template: value });
	};

	const refreshPreviewPage = () => {
		setIsDocumentLoading(true);
		const pdfElement = document.querySelector(
			".pdf-preview"
		) as HTMLElement;
		drawDOM(pdfElement, {
			paperSize: ["210mm", "297mm"],
			keepTogether: "p",
			scale: 0.75,
		})
			.then((group) => {
				return exportPDF(group, {});
			})
			.then((dataUri) => {
				const base64String = dataUri.split(";base64,")[1];
				setPdfBase64String(base64String);
			});
	};

	const paymentSlipData: PaymentSlipDataInput = {
		bankAccount: bankAccount,
		recipient: client,
		amount: "1234.56",
		currency: "CHF",
		invoiceNumber: "000001",
	};

	const errorsAlerts: {
		severity: "warning" | "error";
		message: string;
	}[] = [];

	if (!client) {
		errorsAlerts.push({
			severity: "warning",
			message: "Kein Beispiel-Auftraggeber ausgewählt",
		});
	}
	if (!bankAccount) {
		errorsAlerts.push({
			severity: "warning",
			message: "Kein Beispiel-Bankkonto ausgewählt",
		});
	}
	if (!organization?.name) {
		errorsAlerts.push({
			severity: "warning",
			message: "Firmenname des Labors fehlt in den Einstellungen",
		});
	}
	if (!organization?.street) {
		errorsAlerts.push({
			severity: "warning",
			message: "Strasse fehlt in der Laboradresse in den Einstellungen",
		});
	}
	if (!organization?.city) {
		errorsAlerts.push({
			severity: "warning",
			message: "Ort fehlt in der Laboradresse in den Einstellungen",
		});
	}
	if (!organization?.mwst_number) {
		errorsAlerts.push({
			severity: "warning",
			message: "MwSt.-Nummer fehlt in den Einstellungen",
		});
	}
	if (!organization?.mwst_percentage) {
		errorsAlerts.push({
			severity: "warning",
			message: "MwSt.-Satz fehlt in den Einstellungen",
		});
	}

	const { error: paymentSlipError } = formatPaymentSlipData(paymentSlipData);
	if (paymentSlipError) {
		errorsAlerts.push({ severity: "error", message: paymentSlipError });
	}

	if (!organization) {
		return <div>Lädt...</div>;
	}
	const jobTotals = calculateJobTotalsLegacy({
		job: jobExample,
		jobDocument: jobDocumentExample,
		jobItems: jobItemExamples,
		organization,
	});
	const extendedJobDocumentsExample: ExtendedJobDocument[] = Array.from(
		{ length: 3 },
		(_, i) => i
	).map(() => {
		return {
			job_client_id: clientExample.id,
			job_code: jobExample.code,
			job_document_date: jobDocumentExample.date,
			job_document_discount_material:
				jobDocumentExample.discount_material,
			job_document_discount_work: jobDocumentExample.discount_work,
			job_document_id: jobDocumentExample.id,
			job_items: jobItemExamples as ParsedJobItemFromView[],
			job_title: jobExample.title,
			organization_id: organization.id,
		};
	});
	const sammelrechnungCalculationResult =
		calculateSammelrechnungRowsAndTotals(
			extendedJobDocumentsExample,
			organization,
			clientExample
		);

	const goBackPage = () => {
		if (pageNumber > 1) {
			setIsPageLoading(true);
			setPageNumber((prev) => prev - 1);
		}
	};

	const goForwardPage = () => {
		if (pageNumber < numPages) setIsPageLoading(true);
		setPageNumber((prev) => Math.min(prev + 1, numPages));
	};

	const handlePageHasFinishedLoading = () => {
		setTimeout(() => {
			setIsPageLoading(false);
		}, 200);
	};

	const handleDocumentHasFinishedLoading = () => {
		setTimeout(() => {
			setIsDocumentLoading(false);
		}, 200);
	};

	const handleLogoUpdate = async ({ logoPath }: { logoPath?: string }) => {
		if (logoPath !== undefined && logoPath !== null)
			updatePdfTemplateConfiguration("general", {
				logoPath,
			});

		Logger.info("Updating....", logoPath);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
				width: "100%",
				justifyContent: "center",
				gap: "2%",
			}}
		>
			<div
				style={{
					padding: "40px 0px 40px 40px",
					width: "30%",
					minWidth: "300px",
					maxWidth: "600px",
					display: "flex",
					flexDirection: "column",
					gap: "20px",
				}}
			>
				{/* ERROR ALERTS */}
				<div>
					{errorsAlerts.map((alert, index) => (
						<Alert severity={alert.severity} key={index}>
							{alert.message}
						</Alert>
					))}
				</div>
				{/* PDF TEMPLATE SELECT */}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "20px",
						alignItems: "center",
					}}
				>
					<h1
						style={{
							fontSize: "18px",
							fontWeight: 400,
						}}
					>
						Dokument wählen
					</h1>
					<PdfTemplateSelect
						value={selectedPdfTemplate}
						onChange={handleSelectPdfTemplate}
					/>
				</div>
				{/* ACCORDION */}
				<div>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Beispieldaten wählen</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<BankAccountSelect
								onChange={(value) =>
									setSelectedBankAccount(
										bankAccountsLookup[value]
									)
								}
								value={bankAccount?.id.toString()}
							/>
							<ClientSelect
								value={client?.id.toString()}
								onChange={(value) =>
									setSelectedClient(clientsLookup[value])
								}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Allgemeine Einstellungen</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<CustomSelect
								inputLabel="Seite der Adresse"
								value={
									pdfTemplateConfiguration?.general
										?.clientAddressLeft
										? "left"
										: "right"
								}
								onChange={async (value) => {
									updatePdfTemplateConfiguration("general", {
										clientAddressLeft: value === "left",
									});
								}}
								options={[
									{ value: "left", label: "Links" },
									{ value: "right", label: "Rechts" },
								]}
							/>
							<CustomTextFieldWithSaveButton
								label="E-Mail Adresse für Kopfzeile"
								value={pdfTemplateConfiguration?.general?.email}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										email: value,
									});
								}}
								validationError={null}
								fullWidth
							/>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												pdfTemplateConfiguration
													?.general.showVzlsMembership
											}
											onChange={(e) => {
												updatePdfTemplateConfiguration(
													"general",
													{
														showVzlsMembership:
															e.target.checked,
													}
												);
											}}
										/>
									}
									label="VZLS Mitgliedschaft anzeigen"
								/>
							</FormGroup>
							<CustomTextFieldWithSaveButton
								label="Rand oben in mm"
								value={
									pdfTemplateConfiguration?.general?.marginTop
								}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										marginTop: parseInt(value),
									});
								}}
								validationError={null}
								fullWidth
							/>
							<CustomTextFieldWithSaveButton
								label="Rand links in mm"
								value={
									pdfTemplateConfiguration?.general
										?.marginLeft
								}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										marginLeft: parseInt(value),
									});
								}}
								validationError={null}
								fullWidth
							/>
							<CustomTextFieldWithSaveButton
								label="Rand rechts in mm"
								value={
									pdfTemplateConfiguration?.general
										?.marginRight
								}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										marginRight: parseInt(value),
									});
								}}
								validationError={null}
								fullWidth
							/>
							<CustomTextFieldWithSaveButton
								label="Rand unten in mm"
								value={
									pdfTemplateConfiguration?.general
										?.marginBottom
								}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										marginBottom: parseInt(value),
									});
								}}
								validationError={null}
								fullWidth
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Firmenlogo</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<PdfTemplateLogo
								handleLogoUpdate={handleLogoUpdate}
								pdfTemplateConfiguration={
									pdfTemplateConfiguration
								}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>
								Einstellungen aktuelles Dokument
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<FormControlLabel
								className="mb-1"
								control={
									<Checkbox
										disabled={
											!pdfTemplateConfiguration?.general
												.logoPath
										}
										checked={
											pdfTemplateConfiguration?.[
												selectedPdfTemplate
											].showLogo
										}
										onChange={(e) => {
											updatePdfTemplateConfiguration(
												selectedPdfTemplate,
												{
													showLogo: e.target.checked,
												}
											);
										}}
									/>
								}
								label="Logo anzeigen"
							/>
							<PdfTemplateSingleConfigurations
								pdfTemplateType={selectedPdfTemplate}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
			<div
				style={{
					height: "100%",
					padding: "40px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					style={{
						boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.3)",
						position: "relative",
					}}
				>
					<div
						style={{
							position: "absolute",
							top: 0,
							right: -100,
							zIndex: 100,
						}}
					>
						<IconButton
							onClick={() => {
								const linkSource = `data:application/pdf;base64,${pdfBase64String}`;
								const downloadLink =
									document.createElement("a");
								const fileName = "Kostenvoranschlag.pdf";
								downloadLink.href = linkSource;
								downloadLink.download = fileName;
								downloadLink.click();
							}}
						>
							<DownloadIcon />
						</IconButton>
						<IconButton onClick={refreshPreviewPage}>
							<CachedIcon />
						</IconButton>
					</div>
					<Document
						onLoadSuccess={(n) => {
							setNumPages(n._pdfInfo?.numPages);
							handleDocumentHasFinishedLoading();
						}}
						file={`data:application/pdf;base64,${pdfBase64String}`}
						loading={<PdfPreviewLoadingPlaceholder />}
						error={"PDF konnte nicht geladen werden"}
					>
						<Page
							onLoadSuccess={handlePageHasFinishedLoading}
							pageNumber={pageNumber}
							width={500}
							loading={<PdfPreviewLoadingPlaceholder />}
							error={"Seite konnte nicht geladen werden"}
						/>
					</Document>
					<PdfPreviewLoadingOverlay
						isLoading={isDocumentLoading || isPageLoading}
					/>
				</div>
				<div
					style={{
						marginTop: 24,
						display: "flex",
						justifyContent: "center",
						width: "full",
						alignItems: "center",
					}}
				>
					<IconButton disabled={pageNumber <= 1} onClick={goBackPage}>
						<IconChevronLeft />
					</IconButton>
					{pageNumber}
					<IconButton
						disabled={pageNumber >= numPages}
						onClick={goForwardPage}
					>
						<IconChevronRight />
					</IconButton>
				</div>
			</div>
			<div
				style={{
					position: "relative",
				}}
			>
				<div
					style={{
						position: "absolute",
						left: "-10000px",
						top: "-10000px",
					}}
				>
					<div className="pdf-preview">
						<PdfTemplateOptions
							client={client}
							jobExample={jobExample}
							jobItems={jobItemExamples}
							jobDocumentExample={jobDocumentExample}
							organization={organization}
							pdfTemplateConfiguration={pdfTemplateConfiguration}
							patientExample={patientExample}
							jobTotals={jobTotals}
							paymentSlipData={paymentSlipData}
							sammelrechnungCalculationResult={
								sammelrechnungCalculationResult
							}
							selectedPdfTemplate={selectedPdfTemplate}
							logoUrl={logoUrl ?? undefined}
							clientAvatarUrl={clientAvatarUrl ?? undefined}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const PdfPreviewLoadingPlaceholder: React.FC = () => {
	return (
		<div
			style={{
				top: 0,
				zIndex: 101,
				backgroundColor: "white",
				width: 500,
				height: (500 / 210) * 297,
			}}
		></div>
	);
};

/** We use this in combination with PdfPreviewLoadingPlaceholder to override the loading feedback react-pdf provides */
const PdfPreviewLoadingOverlay: React.FC<{
	isLoading: boolean;
}> = ({ isLoading }) => {
	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "white",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				zIndex: isLoading ? 101 : -1,
			}}
		>
			<CircularProgress />
		</div>
	);
};

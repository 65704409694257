import { FormGroupProps } from "../../form-types";
import { CustomTextFieldWithPercentage } from "../custom-input-components/custom-text-field-with-percentage.component";
import { TpTierSelect } from "../selects/tp-tier.select";

// make onCheckboxChange and onChange required
interface TpTierAndVariationGroup extends FormGroupProps {
  onChange: (event: any) => void;
  onValueChange: (event: any) => void;
}

export const TpTierAndVariationGroup: React.FC<TpTierAndVariationGroup> = ({
  formData,
  formErrors,
  onChange,
  onValueChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "1rem",
      }}
    >
      <TpTierSelect
        label="TP-Stufe"
        value={formData.tp_tier}
        onChange={(value: any) => onValueChange({ name: "tp_tier", value })}
        width="50%"
      />
      <CustomTextFieldWithPercentage
        label="TP-Variation (+/-)"
        name="tp_variation"
        value={formData.tp_variation || ""}
        onChange={onChange}
        size="small"
        validationError={formErrors.tp_variation}
        sx={{
          width: "50%",
        }}
      />
    </div>
  );
};

import { Database } from "./supabaseTypesImport";

export function isShared(
	entity: JobWithShare | PatientWithShare | FileWithShare
): boolean {
	if (!entity || !entity.shared_ids) {
		return false;
	}
	// Filter null elements
	return entity.shared_ids.filter((j) => j).length > 0;
}

export type SharedTableType = "jobs" | "patients" | "files";

export enum ActivityTypeEnum {
	SHARED_JOB_REQUEST = "shared_job_request",
	SHARED_JOB = "shared_job",
	SHARED_PATIENT = "shared_patient",
	SHARED_DOCUMENT = "shared_document",
	SHARED_FILE = "shared_file",
	MESSAGE_GENERAL = "message_general",
}

export enum SupabaseShareEnitityViewEnum {
	FILES_WITH_SHARES = "files_with_shares",
	JOBS_WITH_SHARES = "jobs_with_shares",
	PATIENTS_WITH_SHARES = "patients_with_shares",
}

export enum SupabaseTableEnum {
	ACCOUNTING_RECIPIENTS = "accounting_recipients",
	ACCOUNTING_VIEW = "accounting_view",
	ACTIVATION_CODES = "activation_codes",
	ALLOWED_SIGNUPS_LAB = "allowed_signups_lab",
	ARTICLES = "articles",
	ARTICLES_DEFAULT = "articles_default",
	BANK_ACCOUNTS = "bank_accounts",
	CLIENTS = "clients",
	CONNECT_RELATIONSHIPS = "connect_relationships",
	DESKTOPS = "desktops",
	DESKTOP_JOB_REQUESTS = "desktop_job_requests",
	DESKTOP_JOB_RESPONSES = "desktop_job_responses",
	DESKTOP_SYSTEMS = "desktop_systems",
	EMAILS = "emails",
	FILES = "files",

	GUARANTORS = "guarantors",
	INVOICES = "invoices",
	JOB_DOCUMENTS = "job_documents",
	JOB_DOCUMENTS_INVOICES_RELATIONSHIPS = "job_documents_invoices_relationships",
	JOB_ITEMS = "job_items",
	JOB_ITEMS_WITH_PATIENTS = "job_items_with_patients",
	JOB_ITEM_TEMPLATES = "job_item_templates",
	JOB_ITEM_TEMPLATE_GROUPS = "job_item_template_groups",
	JOB_REQUESTS = "job_requests",
	JOBS = "jobs",
	SHARED_JOBS = "shared_jobs",
	ORGANIZATIONS = "organizations",
	PATIENTS = "patients",
	SHARED_PATIENTS_WITH_LAST_JOB = "shared_patients_with_last_job",
	PRACTITIONERS = "practitioners",
	PROFILES = "profiles",
	PROFILES_TO_INVITES_VIEW = "profiles_to_invites",
	REPLIES = "replies",
	SHARED = "shared",
	TARIFFS = "tariffs",
	TARIFFS_DEFAULT = "tariffs_default",
}

export type FileWithShare =
	Database["public"]["Views"][SupabaseShareEnitityViewEnum.FILES_WITH_SHARES]["Row"];

export type JobWithShare =
	Database["public"]["Views"][SupabaseShareEnitityViewEnum.JOBS_WITH_SHARES]["Row"];

export type PatientWithShare =
	Database["public"]["Views"][SupabaseShareEnitityViewEnum.PATIENTS_WITH_SHARES]["Row"];

export type ProfilesToInvitesViewType =
	Database["public"]["Views"][SupabaseTableEnum.PROFILES_TO_INVITES_VIEW]["Row"];

export type SharedPatientsWithLastJobViewType =
	Database["public"]["Views"][SupabaseTableEnum.SHARED_PATIENTS_WITH_LAST_JOB]["Row"];

export enum SupabaseRpcEnum {
	CREATE_INVOICE_SINGLE = "create_invoice_single",
	CREATE_INVOICE_MONTHLY = "create_invoice_monthly",
	PROCESS_CAMT54_TRANSACTIONS = "process_camt54_transactions",
}

export type JobRequestEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_REQUESTS]["Row"];

export type SharedJobsViewType =
	Database["public"]["Views"][SupabaseTableEnum.SHARED_JOBS]["Row"];

export type AccountingViewType =
	Database["public"]["Views"]["accounting_view"]["Row"];

/**
 * Entity in supabase "replies" table
 */
export type ReplyEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.REPLIES]["Row"];

/**
 * Entity in supabase "activities" table
 */
export type ActivityEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.SHARED]["Row"];

/**
 * Entity in supabase "activities" table for insert
 */
export type ActivityEntityTypeInsert =
	Database["public"]["Tables"][SupabaseTableEnum.SHARED]["Insert"];

/**
 * Entity in supabase "articles" table
 */
export type JobEntityType =
	| Database["public"]["Tables"][SupabaseTableEnum.JOBS]["Row"]
	| JobWithShare;

export type InsertJobEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOBS]["Insert"];

/**
 * Entity in supabase "articles" table
 */
export type ArticleEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.ARTICLES]["Row"];

/**
 * Entity in supabase "articles" table for insert
 */
export type ArticleEntityTypeInsert =
	Database["public"]["Tables"][SupabaseTableEnum.ARTICLES]["Insert"];

/**
 * Entity in supabase "articles_default" table
 */
export type ArticleDefaultEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.ARTICLES_DEFAULT]["Row"];

/**
 * Entity in supabase "bank_accounts" table
 */
export type BankAccountEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.BANK_ACCOUNTS]["Row"];

/**
 * Entity in supabase "clients" table
 */
export type ClientEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.CLIENTS]["Row"];

/**
 * Entity in supabase "guarantors" table
 */
export type GuarantorEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.GUARANTORS]["Row"];

/**
 * Entity in supabase "job_documents" table
 */
export type JobDocumentEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_DOCUMENTS]["Row"];

/**
 * Entity in supabase "job_items" table
 */
export type JobItemEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_ITEMS]["Row"];

/**
 * Entity in supabase "job_items" table for insert
 */
export type JobItemEntityTypeInsert =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_ITEMS]["Insert"];

/**
 * Entity in supabase "organizations" table
 */
export type OrganizationEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.ORGANIZATIONS]["Row"];

/**
 * Entity in supabase "patients" table
 */
export type PatientEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.PATIENTS]["Row"];

/**
 * Entity in supabase "patients" table for update
 */
export type PatientEntityTypeUpdate =
	Database["public"]["Tables"][SupabaseTableEnum.PATIENTS]["Update"];

/**
 * Entity in supabase "practitioners" table
 */
export type PractitionerEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.PRACTITIONERS]["Row"];

/**
 * Entity in supabase "profiles" table
 */
export type ProfileEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.PROFILES]["Row"];

/**
 * Entity in supabase "tariffs" table
 */
export type TariffEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.TARIFFS]["Row"];

/**
 * Entity in supabase "tariffs" table for insert
 */
export type TariffEntityTypeInsert =
	Database["public"]["Tables"][SupabaseTableEnum.TARIFFS]["Insert"];

/**
 * Entity in supabase "tariffs_default" table
 */
export type TariffDefaultEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.TARIFFS_DEFAULT]["Row"];

/**
 * Entity in supabase "files" table
 */
export type FileEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.FILES]["Row"];

/**
 * Entity in supabase "job_item_template_groups" table
 */
export type JobItemTemplateGroupEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_ITEM_TEMPLATE_GROUPS]["Row"];

/**
 * Entity in supabase "job_item_templates" table
 */
export type JobItemTemplateEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.JOB_ITEM_TEMPLATES]["Row"];

/**
 * Entity in supabase "job_item_template_groups" table with all its job_item_templates
 */
export type JobItemTemplateGroupWithJobItemTemplatesType =
	JobItemTemplateGroupEntityType & {
		job_item_templates: JobItemTemplateEntityType[];
	};

/**
 * Entity in supabase "invoices" table
 */
export type InvoiceEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.INVOICES]["Row"];

/**
 * Entity in supabase "desktops" table
 */
export type DesktopEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.DESKTOPS]["Row"];

/**
 * Entity in supabase "desktop_systems" table
 */
export type DesktopSystemEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.DESKTOP_SYSTEMS]["Row"];

/**
 * Entity in supabase "desktop_systems" table for insert
 */
export type DesktopSystemEntityTypeInsert =
	Database["public"]["Tables"][SupabaseTableEnum.DESKTOP_SYSTEMS]["Insert"];

/**
 * Entity in supabase "desktop_job_requests" table
 */
export type DesktopJobRequestEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.DESKTOP_JOB_REQUESTS]["Row"];

/**
 * Entity in supabase "desktop_job_responses" table
 */
export type DesktopJobResponseEntityType =
	Database["public"]["Tables"][SupabaseTableEnum.DESKTOP_JOB_RESPONSES]["Row"];

/**
 * Entity of Desktop and its systems
 */
export type DesktopEntityTypeWithSystems = DesktopEntityType & {
	desktop_systems: DesktopSystemEntityType[];
};

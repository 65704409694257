import {
	Alert,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "../../../../hooks/useForm/useForm";
import { useToast } from "../../../../context/ToastContext";
import { FormProps } from "../../../form-types";
import { Database, supabase } from "../../../../../../lib/supabase";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import {
	ClientEntityType,
	SupabaseTableEnum,
} from "../../../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../../../lib/logger/Logger";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { useEffect, useState } from "react";
import { useDatabaseFetch } from "../../../../hooks/useDatabase";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import axios from "axios";

// TODO: move out all dentCONNECT logic to improve this files readability

interface PractitionerFormPropsInsert extends FormProps {
	clientId: string;
	practitionerId?: undefined;
}

interface PractitionerFormPropsUpdate extends FormProps {
	practitionerId: number;
	clientId?: undefined;
}

export type PractitionerFormProps =
	| PractitionerFormPropsInsert
	| PractitionerFormPropsUpdate;

export const PractitionerForm: React.FC<PractitionerFormProps> = ({
	onSave = (pract: any) => {},
	submitRef = null,
	clientId = undefined,
	practitionerId = undefined,
}) => {
	const isUpdate = clientId ? false : true;

	const { organizationId, client, upsertClient } = useCentralStore(
		(state) => ({
			organizationId: state.organization?.id,
			client: state.client,
			upsertClient: state.upsertClient,
		})
	);

	// Set values for useForm based on whether form is used for update or insert
	const useFormCustomFields = isUpdate
		? {}
		: {
				organization_id: organizationId,
				client_id: clientId,
			};
	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? practitionerId : undefined;

	// useForm hook
	const {
		handleInputChange,
		handleCreate,
		formData,
		formErrors,
		handleValueChange,
		handleUpdate,
	} = useForm<Database["public"]["Tables"]["practitioners"]["Row"]>(
		useFormCustomFields,
		SupabaseTableEnum.PRACTITIONERS,
		useFormFetchData,
		useFormFetchId,
		"Behandler"
	);

	const handleUpdateSubmit = async (event: any) => {
		return await handleUpdate(event, useFormFetchId);
	};

	const handleInsertSubmit = async (event: any) => {
		event.preventDefault();

		if (isDisableConnect) {
			return await handleCreate(event);
		}

		const practEmail = formData?.email;
		if (!practEmail) {
			showNotification({
				message: "Für dentCONNECT-Nutzer bitte eine E-Mail angeben",
				type: "error",
			});
			return { success: false, data: null };
		}

		// TODO: this logic should live on the backend

		// check if a practitioner with that email already exists (for example, if another lab has added them before)
		const { data: existingProfiles, error: existingProfilesError } =
			await supabase
				.from(SupabaseTableEnum.PROFILES)
				.select()
				.eq("email", practEmail);

		if (existingProfilesError) {
			Logger.error(
				"PractitionerForm",
				"handleInsertSubmit",
				"Error retrieving existing profiles",
				existingProfilesError
			);
			showNotification({
				message: "Fehler beim Hinzufügen des Behandlers",
				type: "error",
			});
			return { success: false, data: null };
		}

		let authId = null;
		let organizationMedId = null;
		let connectRelationshipId = null;
		if (existingProfiles?.length > 0) {
			// take data from profiles table
			authId = existingProfiles[0].id;
			organizationMedId = existingProfiles[0].organization_id;
		} else {
			// profile for pract email does not exist yet and is authenticated on the backend
			if (!practEmail) return null;

			// get authId and organizationId from microservice
			const {
				data: { data },
			} = await axios({
				url: `${import.meta.env.VITE_PDF_MICROSERVICE_URL}/auth`,
				method: "POST",
				data: {
					email: practEmail,
					organization_lab_id: organizationId,
				},
			});

			if (!data) {
				Logger.error(
					"PractitionerForm",
					"handleInsertSubmit",
					"Error, no data returned"
				);
				showNotification({
					message: "Fehler beim Hinzufügen des Behandlers",
					type: "error",
				});
				return { success: false, data: null };
			}

			authId = data.profile_id;
			organizationMedId = data.organizationId;
			connectRelationshipId = data.connectRelationshipId;
		}

		if (!authId) {
			Logger.error(
				"PractitionerForm",
				"handleInsertSubmit",
				"Error, no authId returned"
			);
			showNotification({
				message: "Fehler beim Hinzufügen des Behandlers",
				type: "error",
			});
			return { success: false, data: null };
		}

		formData.profile_id = authId;

		if (!organizationMedId || !organizationId) {
			Logger.error(
				"PractitionerForm",
				"handleInsertSubmit",
				"Error, no organizationMedId or organizationId"
			);
			showNotification({
				message: "Keine Organisation gefunden",
				type: "error",
			});
			return { success: false, data: null };
		}

		if (!organizationMedId) {
			Logger.error(
				"PractitionerForm",
				"handleInsertSubmit",
				"Error, no organizationMedId returned"
			);
			showNotification({
				message: "Fehler beim Hinzufügen des Behandlers",
				type: "error",
			});
			return { success: false, data: null };
		}

		// update client with new organization_med_id
		upsertClient({
			...client,
			connect_relationship_id: connectRelationshipId,
		} as ClientEntityType);

		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!organizationId || !formData.email)
			return Logger.error(
				"PractitionerForm",
				"onSubmit",
				"No organizationId"
			);
		const { data: practitionerExists } = await supabase
			.from(SupabaseTableEnum.PRACTITIONERS)
			.select("*")
			.eq("email", formData.email)
			.eq("organization_id", organizationId);

		if (practitionerExists?.length) {
			showNotification({
				message:
					"Ein Behandler mit dieser E-Mail-Adresse existiert bereits",
				type: "error",
			});
			return;
		}
		Logger.log(
			"PractitionerForm",
			"onSubmit",
			"isUpdate",
			isUpdate,
			"useFormFetchId",
			useFormFetchId
		);

		Logger.log("Data to insert:", formData);
		// TODO: weird TS error when not settings return value to any
		const { success, data, error }: any = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		Logger.log("Data returned from insert/update pract:", data, error);
		if (data) {
			onSave(data[0]);
		}
	};

	const [isDisableConnect, setIsDisableConnect] = useState(false);

	// check if there are existing practitioners for this organization that have an auth_id, thus are connected to dentCONNECT
	// if there are none, the first practitioner that is added will be an admin and a warning will be shown
	const { data: existingPracts } = useDatabaseFetch(
		SupabaseTableEnum.PRACTITIONERS,
		{
			column: "organization_id",
			value: organizationId,
		}
	);

	const willBeAdmin = isUpdate
		? false
		: // TODO: instead of filtering here, useFetchMultipleEntriesByForeignKey should have the option
			// to add multiple filters
			!existingPracts?.some(
				(pract: any) => pract.client_id === clientId && pract.profile_id
			);

	useEffect(() => {
		if (!willBeAdmin) {
			setIsDisableConnect(true);
		}
	}, [willBeAdmin]);
	if (!client) return;

	return (
		<form onSubmit={onSubmit}>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			{isUpdate && formData.profile_id && (
				<Alert
					severity="info"
					sx={{
						marginBottom: "20px",
					}}
				>
					Dieser Behandler ist mit dentCONNECT verbunden
				</Alert>
			)}
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				{willBeAdmin && !isDisableConnect && (
					<Alert
						severity="warning"
						sx={{
							marginBottom: "20px",
						}}
					>
						Der erste Behandler ist automatisch Administrator der
						dentCONNECT-Organisation, die mit diesem Auftraggeber
						verbunden ist. Dies kann nicht geändert werden. Sie
						können dentCONNECT für diesen Behandler deaktivieren.
					</Alert>
				)}
				<TitleAndNameGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onValueChange={(args: any) =>
						handleValueChange(args.name, args.value)
					}
				/>
				<div
					style={{
						paddingTop: "1rem",
						display: "flex",
						flexDirection: "row",
						gap: "20px",
					}}
				>
					<CustomTextField
						label="E-Mail"
						value={formData.email}
						onChange={(event) =>
							handleValueChange("email", event.target.value)
						}
						disabled={isUpdate}
						validationError={formErrors.email}
					/>
					{!isUpdate && (
						<Tooltip // intermin solution while labs cannot add more than one practitioner to a connect org
							title={
								!willBeAdmin
									? "Zurzeit kann nur ein Behandler mit dentCONNECT verbunden werden"
									: ""
							}
						>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={isDisableConnect}
											onChange={() =>
												setIsDisableConnect(
													!isDisableConnect
												)
											}
											color="primary"
										/>
									}
									label="dentCONNECT für diesen Behandler deaktivieren"
									disabled={!willBeAdmin} // intermin solution while labs cannot add more than one practitioner to a connect org
								/>
							</FormGroup>
						</Tooltip>
					)}
				</div>
			</Box>

			{!submitRef && (
				<Button type="submit" variant="contained">
					Speichern
				</Button>
			)}
		</form>
	);
};

import { create } from "zustand";
import { JobItemGroupsSlice, createJobItemGroupsStore } from "./groups.store";
import { JobListSlice, createJobListStore } from "./job-list.store";
import {
	createJobDocumentStore,
	JobDocumentSlice,
} from "./job-documents.store";
import { createJobStore, JobsSlice } from "./job.store";
import { createJobItemStore, JobItemSlice } from "./job-items.store";
import { JobPatientSlice, createJobPatientStore } from "./patient.store";

type JobSlice = JobItemGroupsSlice &
	JobListSlice &
	JobDocumentSlice &
	JobsSlice &
	JobItemSlice &
	JobPatientSlice;

export const useJobStore = create<JobSlice>((...args) => ({
	...createJobItemGroupsStore(...args),
	...createJobListStore(...args),
	...createJobDocumentStore(...args),
	...createJobStore(...args),
	...createJobItemStore(...args),
	...createJobPatientStore(...args),
}));

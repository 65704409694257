import { Button, IconButton, Portal, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { RightSidebar } from "../../../../job-page-components/right-sidebar.component";
import { useRefContext } from "../../../../../../context/RefContext";
import {
	JobItemTemplateEntityType,
	JobItemTemplateGroupWithJobItemTemplatesType,
} from "../../../../../../../../lib/supabase/supabaseTypes";
import "./styles.css";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useJobStore } from "@/dentlab/src/store/Jobs";

export const JobItemGroupsSelection: React.FC<{
	rows: JobItemTemplateGroupWithJobItemTemplatesType[];
	onSelect: (jobItems: JobItemTemplateEntityType[]) => void;
	jobDocumentId: number;
}> = ({ rows, onSelect, jobDocumentId }) => {
	const { container } = useRefContext();
	const navigate = useNavigate();

	const { openedSidebar, openSidebar, closeSidebar } = useJobStore(
		(state) => ({
			openedSidebar: state.openedSidebar,
			openSidebar: state.openSideBar,
			closeSidebar: state.closeSidebar,
		})
	);

	const open =
		openedSidebar?.type === "group" &&
		openedSidebar?.documentId === jobDocumentId;

	const label = "Gruppen";

	const handleClose = () => {
		closeSidebar();
	};

	const handleOpen = () => {
		openSidebar(jobDocumentId, "group");
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpen}
				startIcon={<SearchIcon />}
				size="small"
			>
				{label}
			</Button>
			{open && (
				<Portal container={container.current}>
					<RightSidebar
						title={label}
						onClose={handleClose}
						tools={
							<IconButton
								onClick={() => navigate("/job-item-groups")}
							>
								<EditIcon />
							</IconButton>
						}
					>
						<div
							style={{
								paddingTop: "10px",
								paddingBottom: "10px",
							}}
						>
							{rows.length === 0 && (
								<div
									style={{
										padding: "10px",
										textAlign: "center",
									}}
								>
									Sie haben noch keine Gruppen hinzugefügt.
								</div>
							)}
							{rows.map(
								(
									row: JobItemTemplateGroupWithJobItemTemplatesType
								) => (
									<div
										key={row.id}
										className="list-item"
										onClick={() =>
											onSelect(row.job_item_templates)
										}
									>
										<Typography variant="subtitle2">
											{row.name}
										</Typography>
										<Typography variant="body2" noWrap>
											{row.job_item_templates.length}{" "}
											Positionen
										</Typography>
									</div>
								)
							)}
						</div>
					</RightSidebar>
				</Portal>
			)}
		</>
	);
};

import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import {
	JobDocumentEntityType,
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { useCentralStore } from "../Central";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import {
	GsStatusEnum,
	KvStatusEnum,
	LsStatusEnum,
	PermissionStatus,
} from "../../hooks/actions/actions-hooks-types";
import { JobStatusEnum } from "@/lib/types/job";
import { JobDocumentWithFiles } from "../../pages/job-page/job-document/job-document.component";
import { useJobStore } from ".";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";

export interface JobDocumentSlice extends State {
	openedSidebar: null | {
		documentId: number;
		type: "tariff" | "article" | "group";
	};
	fetchJobDocuments: () => Promise<void>;
	createJobDocument: (type: JobDocumentTypeEnum) => Promise<void>;
	isCreateJobDocumentAllowed: (
		jobDocumentType: JobDocumentTypeEnum
	) => PermissionStatus;
	deleteJobDocument: (id: number) => Promise<void>;
	fetchJobDocument: (id: number) => Promise<void>;
	duplicateJobDocument: (
		jobDocument: JobDocumentEntityType,
		existingJobItems: JobItemEntityType[],
		newDocumentType?: JobDocumentTypeEnum
	) => Promise<void>;
	updateJobDocumentTitle: (
		id: number,
		title: string,
		status: JobStatusEnum
	) => Promise<void>;
	updateJobDocumentStatus: (
		documentId: number,
		status: JobStatusEnum | KvStatusEnum | LsStatusEnum | GsStatusEnum,
		amount?: number | null,
		accountingDocumentId?: number | null
	) => Promise<boolean>;
	openSideBar: (
		documentId: number,
		type: "tariff" | "article" | "group"
	) => void;
	closeSidebar: () => void;
}

export const createJobDocumentStore: StateCreator<JobDocumentSlice> = (
	set,
	get
) => ({
	...initialState,
	openedSidebar: null,
	fetchJobDocuments: async () => {
		const jobId = get().job?.id;

		if (!jobId) {
			Logger.error("Job id is not set");
			return;
		}

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.select(`${SupabaseTableEnum.JOB_ITEMS}(*)`)
				.eq("job_id", jobId)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		set({
			jobItemsForDocuments: data.reduce(
				(
					acc: Record<number, JobItemEntityType[]>,
					doc: { job_items: JobItemEntityType[]; id: number }
				) => {
					acc[doc.id] = doc.job_items;
					return acc;
				},
				{}
			),
		});
	},
	createJobDocument: async (type: JobDocumentTypeEnum) => {
		const jobDocuments = get().jobDocuments;
		const permission = get().isCreateJobDocumentAllowed(type);

		if (!permission.isAllowed) {
			showNotification({
				message: permission.explanation,
				type: "error",
			});

			Logger.error(permission.explanation);
			return;
		}

		let name = "Neues Dokument";
		if (type === JobDocumentTypeEnum.DeliveryNote) {
			name = "Lieferschein";
		} else if (type === JobDocumentTypeEnum.Quotation) {
			name = "Kostenvoranschlag";
		} else if (type === JobDocumentTypeEnum.CreditNote) {
			name = "Gutschrift";
		} else if (type === JobDocumentTypeEnum.MATERIALS) {
			name = "Materialien";
		}

		const count =
			jobDocuments?.filter(
				(doc: JobDocumentWithFiles) => doc.type === type
			).length || 0;
		const countForName = count > 0 ? ` ${count + 1}` : "";

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.insert({
					title: `${name} ${countForName}`,
					// These variables are only being used here, no need to assign
					// explicit names
					job_id: get().job?.id,
					type,
					discount_material:
						useCentralStore.getState().client?.discount_material,
					discount_work:
						useCentralStore.getState().client?.discount_work,
				} as JobDocumentEntityType)
				.select()
		);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			set({
				jobDocuments: [...(jobDocuments ?? []), data[0]],
			});
		}
	},

	duplicateJobDocument: async (
		jobDocument: JobDocumentEntityType,
		existingJobItems: JobItemEntityType[],
		newDocumentType?: JobDocumentTypeEnum
	) => {
		if (!jobDocument || !jobDocument.type) {
			showNotification({
				message: "Dokument konnte nicht kopiert werden.",
				type: "error",
			});
			return;
		}

		const jobDocumentType =
			newDocumentType || (jobDocument.type as JobDocumentTypeEnum);

		const permission = get().isCreateJobDocumentAllowed(jobDocumentType);

		if (permission.isAllowed === false) {
			showNotification({
				message: permission.explanation,
				type: "error",
			});
			return;
		}

		const { data } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.insert({
					title: "Kopie von " + jobDocument.title,
					job_id: jobDocument.job_id,
					type: jobDocumentType,
				})
				.select()
		);

		if (data && existingJobItems.length > 0) {
			const { error } = await handleDatabaseOperation(
				supabase.from(SupabaseTableEnum.JOB_ITEMS).insert(
					existingJobItems.map((item) => {
						// old id already exists, so it needs to be undefined and created by supabase

						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const {
							id,
							job_document_id,
							modified_at,
							created_at,
							...newItem
						} = item;
						return {
							...newItem,
							id: uuidv4(),
							job_document_id: data[0].id,
						};
					})
				)
			);
			if (error) {
				showNotification({
					message: "Fehler beim Kopieren der Positionen.",
					type: "error",
				});
				Logger.error(error);
				return;
			}
		}

		const jobId = get().job?.id;
		if (jobId) {
			useJobStore.getState().fetchJob(jobId);
		}
	},

	/**
	 * isCreateJobDocumentAllowed - Function to check if a job document can be created
	 * @param {JobDocumentTypeEnum} jobDocumentType
	 * @returns {PermissionStatus}
	 *
	 * ALLOWED:
	 * - Quotation: Always
	 * - DeliveryNote: If no delivery note (that's not archived) exists
	 * - CreditNote: Always
	 */
	isCreateJobDocumentAllowed: (
		jobDocumentType: JobDocumentTypeEnum
	): PermissionStatus => {
		const jobDocuments = get().jobDocuments;

		if (
			jobDocumentType === JobDocumentTypeEnum.Quotation ||
			jobDocumentType === JobDocumentTypeEnum.CreditNote ||
			jobDocumentType === JobDocumentTypeEnum.MATERIALS
		) {
			return {
				isAllowed: true,
				explanation: "",
			};
		} else if (jobDocumentType === JobDocumentTypeEnum.DeliveryNote) {
			const deliveryNoteExists = jobDocuments.some(
				(jobDocument) =>
					jobDocument.type === JobDocumentTypeEnum.DeliveryNote &&
					jobDocument.status !== JobStatusEnum.ARCHIVED
			);

			if (!deliveryNoteExists) {
				return {
					isAllowed: true,
					explanation: "",
				};
			}

			return {
				isAllowed: false,
				explanation:
					"Es existiert bereits ein Lieferschein. Archivieren Sie diesen, um einen neuen zu erstellen.",
			};
		}

		return {
			isAllowed: false,
			explanation: "Die Art des Auftrags wurde nicht erkannt.",
		};
	},

	deleteJobDocument: async (id: number) => {
		const jobDocuments = get().jobDocuments;
		set({
			jobDocuments: jobDocuments.filter(
				(jobDocument) => jobDocument.id !== id
			),
		});

		await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.delete()
				.eq("id", id)
				.select()
		);
	},

	updateJobDocumentTitle: async (
		id: number,
		title: string,
		status: JobStatusEnum
	) => {
		if (status > JobStatusEnum.IN_PROGRESS) {
			showNotification({
				message:
					"Der Titel des Dokuments kann nicht geändert werden, da der Auftrag bereits abgeschlossen ist.",
				type: "error",
			});
			return;
		}

		const { success, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.update({ title })
				.eq("id", id)
				.select()
		);

		if (success) {
			showNotification({
				message: "Titel des Dokuments aktualisiert",
				type: "success",
			});
			set(
				produce((state) => {
					const jobDocument = state.jobDocuments.find(
						(doc: JobDocumentWithFiles) => doc.id === id
					);
					if (jobDocument) {
						jobDocument.title = title;
					}
				})
			);
			Logger.info("Job document title updated", get().jobDocuments);
		} else {
			showNotification({
				message: "Fehler beim Aktualisieren des Titels des Dokuments",
				type: "error",
			});
			Logger.warn("Error updating job document title", { error });
		}
	},

	updateJobDocumentStatus: async (
		documentId: number,
		status: JobStatusEnum | KvStatusEnum | LsStatusEnum | GsStatusEnum,
		amount: number | null = null,
		accountingDocumentId: number | null = null
	) => {
		if (
			status === JobStatusEnum.BOOKED_SINGLE ||
			status === JobStatusEnum.BOOKED_MONTHLY
		) {
			if (!accountingDocumentId) {
				showNotification({
					message:
						"Es fehlt ein Buchhaltungseintrag, um den Status des Dokuments zu aktualisieren.",
					type: "error",
				});
			}
		}

		if (status == JobStatusEnum.COMPLETED) {
			if (!amount) {
				showNotification({
					message:
						"Um den Auftrag abzuschliessen, muss ein Gesamtbetrag vorhanden sein.",
					type: "error",
				});
			}
		}

		const { success, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.update({
					status,
					amount,
					// TODO: accounting_document_id: accountingDocumentId,
				})
				.eq("id", documentId)
				.select()
		);

		if (success) {
			set(
				produce((state) => {
					const jobDocument = state.jobDocuments.find(
						(doc: JobDocumentWithFiles) => doc.id === documentId
					);
					if (jobDocument) {
						jobDocument.status = status;
						if (amount) jobDocument.amount = amount;
						if (accountingDocumentId)
							jobDocument.accounting_document_id =
								accountingDocumentId;
					}
				})
			);
			Logger.info("Job document status updated", get().jobDocuments);
			return true;
		} else {
			showNotification({
				message: "Fehler beim Aktualisieren des Status des Dokuments",
				type: "error",
			});
			Logger.error("Error updating job document status", { error });
			return false;
		}
	},
	fetchJobDocument: async (id: number) => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.select(`*, ${SupabaseTableEnum.FILES} (*)`)
				.eq("id", id)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		set((state) => ({
			...state,
			jobDocuments: state.jobDocuments.map((jd) => {
				if (jd.id == id) {
					return data?.[0];
				} else {
					return jd;
				}
			}),
		}));
	},
	closeSidebar: () => set({ openedSidebar: null }),
	openSideBar: (documentId: number, type: "tariff" | "article" | "group") => {
		if (get().openedSidebar !== null) {
			set({
				openedSidebar: null,
			});
			setTimeout(() => {
				set({
					openedSidebar: {
						documentId,
						type,
					},
				});
			}, 100);
		} else {
			set({
				openedSidebar: {
					documentId,
					type,
				},
			});
		}
	},
});

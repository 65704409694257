import { SearchBar } from "./header-bar-components/search-bar/search-bar.component";
import "../../styles.css";
import { HeaderBarSettingsGroup } from "./header-bar-components/header-bar-settings-group/header-bar-settings-group.component";
import logo from "../../../assets/dentLAB_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useCentralStore } from "@/dentlab/src/store/Central";

export function getDaysLeftInTrial(createdAt: string | null | undefined) {
	if (!createdAt) {
		return 0;
	}
	// From account creation there's a 14 day trial period, round up to the nearest integer
	const daysPassed = Math.floor(
		(new Date().getTime() - new Date(createdAt).getTime()) /
			(1000 * 60 * 60 * 24)
	);
	const daysLeft = Math.max(0, 14 - daysPassed);
	return daysLeft;
}

export const HeaderBar: React.FC = () => {
	const organizationCreatedAt = useCentralStore(
		(state) => state.organization?.created_at
	);
	const navigate = useNavigate();
	return (
		<div className="header-bar">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginLeft: "1rem",
					paddingTop: "3px",
				}}
			>
				<Link to="/">
					<img
						src={logo}
						style={{
							height: "14px",
						}}
					/>
				</Link>
			</div>
			<SearchBar />
			<div className="flex flex-row">
				<div className="flex flex-row rounded-full bg-white bg-opacity-10 items-center justify-center text-sm my-0.5">
					<p className="ml-4 mr-2 text-white opacity-80">
						Ihr Test läuft in{" "}
						<b>{getDaysLeftInTrial(organizationCreatedAt)}</b> Tagen
						aus.
					</p>
					<div
						className="flex flex-row rounded-full bg-white bg-opacity-20 items-center justify-center text-sm h-full hover:cursor-pointer"
						onClick={() => navigate("/abonnement")}
					>
						<p className="px-4 text-white opacity-80">
							Jetzt upgraden
						</p>
					</div>
				</div>
				<HeaderBarSettingsGroup />
			</div>
		</div>
	);
};

import { useJobDocumentActions } from "@/dentlab/src/hooks/actions/useJobDocumentActions";
import { JobDocumentWithFiles } from "../job-document.component";
import { JobStatusEnum } from "@/lib/types/job";
import { JobDocumentTypeEnum } from "../job-document.types";
import { CustomButtonWithAnchoredMenu } from "@/dentlab/src/components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "./job-document-customMenuItem.component";
import { PermissionStatus } from "@/dentlab/src/hooks/actions/actions-hooks-types";
import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "@/dentlab/src/pdf-templates/document-template.types";
import { useJobStore } from "@/dentlab/src/store/Jobs";

interface DocumentOption {
	text: string;
	action: () => void | Promise<void>;
	permission: PermissionStatus;
}

export const JobDocumentOptionsMenu: React.FC<{
	jobDocument: JobDocumentWithFiles;
	jobItems: JobItemEntityType[];
	onExport: (templateType: PdfTemplateType | XmlTemplateType) => void;
}> = ({ jobDocument, jobItems, onExport }) => {
	const {
		isArchiveJobDocumentAllowed,
		isUnarchiveJobDocumentAllowed,
		archiveJobDocument,
		unarchiveJobDocument,
		isExportAuftragsblattAllowed,
		isExportRechnungAllowed,
		isExportBarzahlungAllowed,
		isExportKartenzahlungAllowed,
		isExportXmlAllowed,
	} = useJobDocumentActions();

	const { duplicateJobDocument, isCreateJobDocumentAllowed } = useJobStore(
		(state) => ({
			duplicateJobDocument: state.duplicateJobDocument,
			isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
		})
	);

	const documentOptions: DocumentOption[] = [];

	if (jobDocument.status === JobStatusEnum.ARCHIVED) {
		documentOptions.push({
			text: "Archivierung aufheben",
			action: () => unarchiveJobDocument(jobDocument),
			permission: isUnarchiveJobDocumentAllowed(jobDocument),
		});
	} else {
		documentOptions.push({
			text: "Dokument archivieren",
			action: () => archiveJobDocument(jobDocument),
			permission: isArchiveJobDocumentAllowed(jobDocument),
		});
	}

	documentOptions.push({
		text: "Dokument duplizieren",
		action: async () =>
			await duplicateJobDocument(jobDocument, jobItems ?? []),
		permission: isCreateJobDocumentAllowed(
			jobDocument.type as JobDocumentTypeEnum
		),
	});

	if (jobDocument.type !== JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Zu Lieferschein duplizieren",
			action: async () =>
				await duplicateJobDocument(
					jobDocument,
					jobItems ?? [],
					JobDocumentTypeEnum.DeliveryNote
				),
			permission: isCreateJobDocumentAllowed(
				JobDocumentTypeEnum.DeliveryNote
			),
		});
	}

	if (
		jobDocument.type === JobDocumentTypeEnum.Quotation ||
		jobDocument.type === JobDocumentTypeEnum.DeliveryNote
	) {
		documentOptions.push({
			text: "Auftragsblatt exportieren",
			action: () => onExport(PdfTemplateType.AUFTRAGSBLATT),
			permission: isExportAuftragsblattAllowed(jobDocument),
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Rechnung erstellen",
			action: () => onExport(PdfTemplateType.RECHNUNG),
			permission: isExportRechnungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Barzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.BARZAHLUNGSQUITTUNG),
			permission: isExportBarzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Kartenzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.KARTENZAHLUNGSQUITTUNG),
			permission: isExportKartenzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "SV LS ELNF erstellen",
			action: () => onExport(XmlTemplateType.LIEFERSCHEIN_XML),
			// permission: isExportXmlAllowed(jobItems),
			// TODO: Allow xml export
			permission: {
				isAllowed: false,
				explanation:
					"XML Export wird auf Testaccounts nicht unterstützt.",
			},
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.Quotation) {
		documentOptions.push({
			text: "SV KV ELNF erstellen",
			action: () => onExport(XmlTemplateType.KOSTENVORANSCHLAG_XML),
			// permission: isExportXmlAllowed(jobItems),
			// TODO: Allow xml export
			permission: {
				isAllowed: false,
				explanation:
					"XML Export wird auf Testaccounts nicht unterstützt.",
			},
		});
	}
	return (
		<CustomButtonWithAnchoredMenu>
			{documentOptions.map((d) => {
				const { isAllowed, explanation } = d.permission;
				return (
					<div key={d.text}>
						<CustomMenuItem
							title={isAllowed ? "" : explanation}
							menuFunction={d.action}
							disabled={isAllowed === false}
							text={d.text}
							placement="left"
						/>
					</div>
				);
			})}
		</CustomButtonWithAnchoredMenu>
	);
};

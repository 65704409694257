export const patientFormTexts = {
  options: {
    inv_covered_by: [
      {
        value: "private",
        label: "Privat",
      },
      {
        value: "public_insurance",
        label: "Krankenkasse",
      },
      {
        value: "private_insurance",
        label: "Versicherung",
      },
      {
        value: "social_welfare_office",
        label: "Sozialamt",
      },
      {
        value: "other",
        label: "Andere",
      },
    ],
    gender: [
      {
        value: "male",
        label: "Männlich",
      },
      {
        value: "female",
        label: "Weiblich",
      },
      {
        value: "diverse",
        label: "Divers",
      },
      {
        value: "no_answer",
        label: "Keine Angabe",
      },
    ],
  },
  labels: {
    inv_covered_by: "Kostenübernahme",
  },
};

import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

const options = [
  { value: "S", label: "Strukturierte Adresse" },
  { value: "K", label: "Kombinierte Adressfelder (2 Zeilen)" },
];

export const PaymentSlipAddressTypeSelect: React.FC<SelectProps> = (props) => {
  return <CustomSelect options={options} inputLabel="Adress-Typ" {...props} />;
};

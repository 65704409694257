import { LargeDialogFrameWithTabs } from "@/dentlab/src/components/dialog-components/dialog-frames/large-dialog-with-tabs/large-dialog-frame-with-tabs.component";
import { DialogPageWithToolbar } from "@/dentlab/src/components/dialog-components/dialog-pages/dialog-page-with-toolbar/dialog-page-with-toolbar.component";
import { SharePatientButton } from "@/dentlab/src/components/sharing/share-button/share-patient-button.component";
import { PatientForm } from "@/dentlab/src/forms/form-components/patient/insert-update/patient.form";
import { PatientWithShare } from "@/lib/supabase/supabaseTypes";
import { PatientDialogFilesSection } from "./components/patients-file-section.component";

export const PatientFormDialog: React.FC<{
	open: boolean;
	onClose: () => void;
	patient?: PatientWithShare;
	setPatient?: (patient: PatientWithShare) => void;
	onSave?: (patient: PatientWithShare) => void;
}> = ({ open, onClose, patient, setPatient, onSave }) => {
	let tabs = [
		{
			label: "Patient",
			component: patient ? (
				<PatientForm
					patientId={patient?.id as string}
					onSave={onSave}
				/>
			) : (
				<PatientForm onSave={onSave} />
			),
			index: 0,
		},
		{
			label: "Dateien",
			component: (
				<PatientDialogFilesSection patientId={patient?.id as string} />
			),
			index: 1,
		},
	];
	if (!patient) {
		tabs = tabs.slice(0, 1);
	}
	return (
		<LargeDialogFrameWithTabs
			id="patient-form-dialog"
			height={"70%"}
			maxWidth={"lg"}
			open={open}
			onClose={onClose}
			tabs={tabs.map((tab) => ({
				label: tab.label,
				component: (
					<DialogPageWithToolbar
						onClose={onClose}
						toolbar={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									height: "100%",
									justifyContent: "space-between",
								}}
							>
								<div></div>
								<div
									style={{
										marginRight: "1rem",
									}}
								>
									{patient && setPatient && (
										<SharePatientButton
											patient={patient}
											setPatient={setPatient}
										/>
									)}
								</div>
							</div>
						}
					>
						{tab.component}
					</DialogPageWithToolbar>
				),
				index: tab.index,
			}))}
		/>
	);
};

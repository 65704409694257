import { useParams } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormDialog } from "../../../hooks/useFormDialog";
import {
	PractitionerForm,
	PractitionerFormProps,
} from "../../../forms/form-components/practitioner/insert-update/practitioner.form";
import { PractitionerEntityType } from "../../../../../lib/supabase/supabaseTypes";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";

// TODO: DW-317 let user add and update practitioner inline (without dialog)
export const PractitionerList: React.FC = () => {
	const { clientId }: any = useParams();
	const {
		practitioners: { practitionersByClientIdLookup },
		updatePractitioners,
	}: any = usePractitionersContext();
	const practitioners = practitionersByClientIdLookup[clientId];

	const { openDialog, dialog } = useFormDialog<PractitionerFormProps>({
		onSubmit: (practitioner: PractitionerEntityType) => {
			// case if there are no practitioners yet
			if (!practitioners) {
				updatePractitioners([practitioner]);
				return;
			}

			const newPractitioners = practitioners.map((pract: any) => {
				if (pract.id === practitioner.id) {
					return practitioner;
				}
				return pract;
			});
			if (
				!practitioners.find(
					(pract: any) => pract.id === practitioner.id
				)
			) {
				newPractitioners.push(practitioner);
			}
			updatePractitioners(newPractitioners);
		},
		FormComponent: PractitionerForm,
		dialogTitle: "Behandler hinzufügen",
	});

	// If the edit button is clicked, the practitioner id is stored in state and the dialog is opened
	const handleOpenExistingPractitionerForm = (practitionerId: number) => {
		openDialog({ practitionerId: practitionerId });
	};

	// If the add button is clicked, the dialog is opened with clientId as props instead of practitionerId
	const handleOpenNewPractitionerForm = () => {
		openDialog({ clientId: clientId });
	};

	return (
		<Box>
			{dialog}
			{practitioners?.map((practitioner: PractitionerEntityType) => (
				<Box key={practitioner.id}>
					{practitioner.first_name} {practitioner.last_name}{" "}
					{practitioner.email}
					{practitioner.profile_id && " (dentCONNECT-Nutzer)"}
					<IconButton
						aria-label="delete"
						size="medium"
						onClick={() => {
							handleOpenExistingPractitionerForm(practitioner.id);
						}}
					>
						<EditIcon />
					</IconButton>
				</Box>
			))}
			<Button onClick={handleOpenNewPractitionerForm}>
				Behandler hinzufügen
			</Button>
		</Box>
	);
};
